.container {
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 13px;
  margin-bottom: 10px;
}
.description {
  color: #9c9696;
  font-size: 11px;
  width: 210px;
  text-align: center;
  margin-bottom: 10px;
}
.checkmark {
  width: 60px;
  height: 60px;
}
