.container {
  padding: 15px 15px 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
  justify-content: space-between;
}
.notAllowed {
  cursor: default;
  opacity: 0.4;
}
div:last-child > .container {
  border-bottom: none !important;
  margin-bottom: -20px;
}
