.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleContainer {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.titleContainer .title {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.propertiesContentContainer {
  border-top: 1px solid #eeeeee;
}
.blockPropertyContainer {
  border-bottom: 1px solid #eeeeee;
}
.singleProperty {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.singleProperty .propertyTitle {
  margin-right: 15px;
}
.singleProperty:last-child {
  border-bottom: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.switch :global .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.propertyFields {
  padding-bottom: 10px;
  margin: 0 20px;
  font-size: 12px;
  color: #8b8b8d;
  line-height: 30px;
}
.propertyFields .propertyInputField {
  height: 30px;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  border: 0;
  outline: none;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  margin-bottom: 5px;
}
