.container {
  margin: 10px 4px 10px 0;
  width: calc(100% - 5px);
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 25px 40px;
  box-sizing: border-box;
  font-size: 13px;
}
.topDescription {
  margin-bottom: 10px;
}
.loaderContainerWrapper {
  margin-top: 40px;
}

.loadingItem {
  height: 35px;
  background-color: #e3e3e3;
  margin-bottom: 5px;
}
.surveysContainer {
  margin-top: 25px;
}
