.main-results-container {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 65px;
}

.main-results-container .loader-container {
  background-color: #e3e3e3;
}
.main-results-container .filters-container {
  height: 475px;
  min-width: 360px;
  box-shadow: none;
  border: none;
  margin-top: 0;
  border-radius: 7px;
}
.main-results-container .question-container {
  height: 400px;
  margin-top: 25px;
  border-radius: 7px;
}
.main-results-container .statistics-loading {
  margin-top: 2px;
  height: 50px;
  border-radius: 7px;
}
.main-results-container .header-container {
  height: 50px;
  width: 100%;
  margin-top: 0;
  border-radius: 7px;
}
.results-content-loading {
  display: inline-flex;
  align-items: flex-start;
}
.toolbar-container {
  padding-top: 30px;
  padding-bottom: 7px;
  position: absolute;
  width: 1390px;
  z-index: 100;
  display: flex;
  background-color: #fff;
}
.toolbar-container.toolbar-container-fixed {
  position: fixed;
}
.toolbar-container.toolbar-container-relative {
  position: relative;
}
.toolbar-container.toolbar-container-no-padding-bottom {
  padding-bottom: 0;
}
.toolbar-container .navigation {
  padding: 5px;
  display: flex;
  height: 40px;
  line-height: 40px;
  flex-grow: 1;
  width: initial;
  margin-right: 8px;
}
.toolbar-container .navigation .navigation-item {
  flex-grow: 1;
  text-align: left;
  font-size: 12px;
  color: #000000;
  user-select: none;
}
.toolbar-container .navigation .navigation-item.navigation-item-active {
  font-family: Open Sans Bold;
  color: #5200f1;
}
.toolbar-container .navigation .navigation-item:nth-child(2) {
  border-left: 1px solid #d3d3d3;
}
.toolbar-container .toolbar-button:nth-child(2) {
  margin-left: auto;
}
.toolbar-container .toolbar-button {
  border-radius: 7px;
  padding: 5px 20px;
  font-family: Open Sans SemiBold;
  margin-left: 8px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}
.toolbar-container .toolbar-button.logic-jump-button {
  color: #5200f1;
  border: 1px solid #d3d3d3;
  display: inline-flex;
  align-items: center;
}
.toolbar-container .toolbar-button.logic-jump-button.logic-jump-button-active {
  color: #5200f1;
}
.toolbar-container
  .toolbar-button.logic-jump-button.logic-jump-button-disabled {
  opacity: 0.5;
}
.toolbar-container .toolbar-button.logic-jump-button .logic-jump-icon {
  width: 14px;
  height: 12px;
  margin-right: 15px;
}
.toolbar-container .toolbar-button.edit-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
}
.toolbar-container .toolbar-button.edit-button.edit-button-active {
  color: #5200f1;
  border-radius: 7px 7px 0 0;
  border-bottom-color: #e6e6e6;
}
.toolbar-container .toolbar-button.edit-button .edit-icon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  filter: grayscale(100%) opacity(0.4);
}
.toolbar-container .toolbar-button.edit-button.edit-button-active .edit-icon {
  filter: none;
}
.toolbar-container .toolbar-button.edit-button .edit-drop-down {
  width: 100%;
  position: absolute;
  top: 51px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 7px 7px;
  text-align: left;
  background-color: #fff;
}
.toolbar-container .toolbar-button.edit-button .edit-drop-down-option {
  cursor: pointer;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
}
.toolbar-container .toolbar-button.background-color-button {
  background-color: #5200f1;
  color: #fff;
  border: 1px solid #5200f1;
}
.toolbar-container .toolbar-button.edit-workspace-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-active {
  color: #5200f1;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-disabled {
  opacity: 0.5;
  cursor: text;
  filter: grayscale(100%) opacity(0.4);
}
.toolbar-container
  .toolbar-button.edit-workspace-button
  .edit-workspace-button-icon {
  height: 12px;
  margin-right: 10px;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-active
  .edit-workspace-button-icon {
  filter: none;
}
.toolbar-container .toolbar-button.export-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
  display: inline-flex;
  align-items: center;
}
.toolbar-container .toolbar-button.export-button.export-button-disabled {
  cursor: text;
  opacity: 0.5;
  filter: grayscale(100%) opacity(0.4);
}
.toolbar-container .toolbar-button.export-button .export-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.toolbar-container .share-results-button .toolbar-temporary-tooltip {
  position: absolute;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  color: #221f1f;
  bottom: -22px;
  right: 1px;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 14px;
  font-family: 'Open Sans Regular';
  border-radius: 2px;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.toolbar-container .share-results-button .toolbar-temporary-tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
  left: 38px;
  top: 33px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid #c3c3c3;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.toolbar-container
  .share-results-button
  .toolbar-temporary-tooltip-arrow.toolbar-temporary-tooltip-arrow-white {
  top: 34px;
  border-bottom-color: #fff;
  opacity: 1;
}
.toolbar-container
  .share-results-button.share-results-button-tooltip-visible
  .toolbar-temporary-tooltip,
.toolbar-container
  .share-results-button.share-results-button-tooltip-visible
  .toolbar-temporary-tooltip-arrow {
  visibility: visible;
  opacity: 1;
  transition: none;
}
.blocks-results-header {
  position: relative;
  background-color: #fff;
  top: 0;
  padding-top: 12px;
  z-index: 99;
  width: calc(100vw - 375px - (100vw - 1390px) / 2);
  overflow-x: hidden;
}
.blocks-results-header-shadow {
  box-shadow: -2px 6px 5px -5px #cacaca;
}
.blocks-results-header.blocks-results-header-sticky {
  position: sticky;
}
.box-shadow {
  height: 15px;
  width: calc(100vw - 362px - (100vw - 1390px) / 2);
  box-shadow: 0 6px 5px -5px #cacaca;
}
.content-results-header-loading {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  height: 55px;
  line-height: 55px;
  padding-top: 30px;
  z-index: 99;
}
.content-results-header {
  display: flex;
  background-color: #ffffff;
  z-index: 100;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  margin-bottom: 10px;
  height: 50px;
  justify-content: center;
  width: 1013px;
  margin-left: 13px;
}
.left-results-header-content {
}
.left-results-header-content .header1 {
  font-family: 'Roboto Bold';
  font-size: 23px;
  color: #221f1f;
  height: 30px;
  line-height: 30px;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.left-results-header-content .header2 {
  font-size: 14px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.right-results-header-content {
  display: inline-flex;
}
.right-results-header-content .right-header-content-container {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.right-results-header-content
  .right-header-content-container
  .right-header-value {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  font-size: 28px;
}
.right-results-header-content
  .right-header-content-container
  .right-header-value
  .right-header-value-percentage {
  font-size: 14px;
  margin-left: 4px;
  font-family: Open Sans Bold;
}
.right-results-header-content
  .right-header-content-container
  .right-header-title {
  font-size: 12px;
  margin-left: 8px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  max-width: 50px;
  color: #221f1f;
}
.results-content {
  display: inline-flex;
  align-items: flex-start;
  top: 82px;
}
.results-content-top {
  top: 100px;
}
.results-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 7px;
  margin-bottom: 15px;
  break-inside: avoid;
}
.results-container .container-title {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: none;
}
.results-container .answers-container .camera-question-image-item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.results-container .answers-container .camera-question-image-item img {
  max-width: 100px;
  max-height: 130px;
  cursor: pointer;
}
.results-container .answers-container .camera-question-image-item img:hover {
  opacity: 0.7;
}
.results-container .answers-container .open-question-answer-container {
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-text {
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-time-taken {
}
.results-container .answers-container .open-question-answer-container {
  border-bottom: 1px solid #dfe0e4;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8d;
  font-size: 13px;
}
.results-container
  .answers-container
  .open-question-answer-container:last-child {
  border-bottom: 0;
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-text {
  width: 80%;
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-time-taken {
  width: 20%;
}

.results-container .answers-container .results-answers-distribution-container {
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 95px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution {
  font-size: 14px;
  color: #221f1f;
  margin-bottom: 30px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
  z-index: 9;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-other-answer {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-other-answer-button {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #5200f5;
  margin: 12px 0;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list
  .other-answer {
  display: block;
  padding: 10px 0;
  color: #8b8b8d;
  font-size: 13px;
  border-bottom: 1px solid #dfe0e4;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list
  .other-answer:last-child {
  border-bottom: 0;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list {
  margin-left: 2px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer
  .allow-other-label {
  margin-left: 10px;
  border: 1px solid #5200f5;
  color: #5200f5;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 2px;
  opacity: 0.7;
  position: absolute;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer.cover-image-padding {
  padding-left: 35px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .answer-cover-image-container {
  top: -3px;
  z-index: 10;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .answer-cover-image-container
  .left-container {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .left-container {
  width: 22px;
  height: 22px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .left-container
  .cover-image-preview {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-details {
  display: flex;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-details
  .unfiltered {
  font-size: 10px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container {
  width: 75%;
  height: 11px;
  background-color: #ebecf0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 3px;
  cursor: pointer;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container.unfiltered {
  height: 15px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar {
  height: 11px;
  background-color: #5200f1;
  cursor: pointer;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-bar-container
  .results-answers-bar {
  background-color: #fc035d;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar.unfiltered {
  height: 5px;
  background-color: #8b8b8d;
  cursor: default;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar:hover {
  opacity: 0.8;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-percentage {
  font-family: 'Roboto Bold';
  width: 10%;
  padding-left: 20px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-percentage {
  color: #fc035d;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-count {
  font-family: 'Roboto Bold';
  width: 15%;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-answer-count {
  color: #fc035d;
}

.results-container .filter-results-container {
  padding-right: 19px;
  margin-left: 19px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.results-container .filter-results-container .filter-results-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.filter-results-options-container {
  display: flex;
  justify-content: space-between;
}
.filter-results-options-button {
  font-size: 30px;
  font-weight: bold;
  color: #5300f2;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 0px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.demographics-dropdown {
  position: absolute;
  z-index: 100;
  top: -190px;
  right: 30px;
  box-sizing: border-box;
  font-size: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.demographics-dropdown-action {
  height: 30px;
  text-transform: none;
  color: #8b8b8d;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.demographics-dropdown-action-icon {
  width: 20px;
  text-align: center;
}
.demographics-dropdown-action-text:hover {
  opacity: 0.7;
}
.side-results-container-title-item {
  display: inline-flex;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8b8b8d;
  cursor: pointer;
}
.side-results-container-title-item .count {
  background-color: #5200f1;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 12px;
  height: 11px;
  line-height: 10px;
}
.side-results-container-title-item.active {
  color: #000000;
}
.side-results-container-content {
}
.action-results-column-container {
  width: 360px;
  position: relative;
  z-index: 99;
  top: 12px;
  margin-top: 12px;
  border: 1px solid #d3d3d3;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.action-results-column-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.action-results-column-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.action-results-column-container.action-results-column-container-sticky {
  position: sticky;
}
.blocks-results-column-container {
  width: 1012px;
  margin-bottom: 100px;
  margin-left: 13px;
  margin-top: 15px;
}
.blocks-results-loading {
  margin-top: 10px;
}
.results-container .block-structure {
  display: inline-flex;
  margin-top: 30px;
  width: calc(100% - 80px);
}
.results-container .block-structure .block-number {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
  flex-shrink: 0;
}
.results-container .block-structure .block-icon {
  width: 35px;
  color: #8500f0;
  top: 4px;
  flex-shrink: 0;
}
.results-container .block-structure .block-icon .icon {
  font-size: 16px;
}
.results-container .block-content {
  width: 100%;
}
.results-container .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
}
.results-container .media-upload {
  position: absolute;
  top: 30px;
  right: 20px;
}
.results-container .media-upload .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.results-container .media-upload .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}
.block-content .block-text {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  width: calc(100% - 80px);
}
.block-content .block-text .block-text-matrix-axis {
  margin-bottom: 20px;
}
.block-content
  .block-text
  .block-text-matrix-axis
  .block-text-matrix-axis-bold {
  font-family: 'Open Sans SemiBold';
  text-transform: capitalize;
}
.block-content .block-text .block-respondents {
  margin-top: 5px;
  font-size: 12px;
  color: #8b8b8d;
}
.block-content .block-text .block {
  font-family: 'Open Sans Regular';
  font-size: 18px;
  border: 0;
  outline: none;
  width: 600px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 10px;
}
.results-container .result-view-type-container {
  height: 45px;
  line-height: 45px;
  display: flex;
  padding-left: 95px;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
}
.results-container .results-container-left {
  display: flex;
}
.results-container .result-view-type-container .view-type-item {
  font-family: 'Open Sans Bold';
  margin-right: 45px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 13px;
}
.results-container .result-view-type-container .view-type-item.active {
  color: #000000;
}
.results-container .result-view-type-container .expand-open-question-button {
  display: flex;
  cursor: pointer;
  margin-right: 30px;
  user-select: none;
  height: 35px;
  line-height: 35px;
  height: 35px;
  padding: 0 30px;
  border-radius: 20px;
  font-size: 14px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);
  color: #505050;
  background-color: #edeef1;
}
.results-container
  .result-view-type-container
  .expand-open-question-button.expand-open-question-button-active {
  color: #ffffff;
  background-color: #5200f1;
}
.results-container .answers-container .open-question-answers-container {
  max-height: 315px;
}
.results-container
  .answers-container
  .open-question-answers-container.open-question-answers-expanded {
  max-height: none;
}

.gauge-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gauge-legend-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;
  width: 100%;
}
.gauge-legend-container .gauge-legend-item-container {
  margin-right: 15px;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-bullet {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-label {
  font-family: 'Open Sans SemiBold';
  font-size: 11px;
  text-transform: uppercase;
  color: #8b8b8d;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-value {
  display: block;
  font-family: 'Roboto Bold';
  font-size: 14px;
  color: #221f1f;
  text-align: center;
  padding-left: 10px;
}

.results-bar-container {
}
.results-bar-container .results-bar-bottom-axis {
}
.results-bar-container .results-bar-bottom-axis text {
  font-size: 11px;
  color: #8b8b8d;
}
.results-bar-container .results-bar-bottom-axis line,
.results-bar-container .results-bar-bottom-axis path {
  stroke: #8b8b8d;
}
.results-bar-container .results-bar-left-axis {
}
.results-bar-container .results-bar-left-axis text {
  font-size: 11px;
  color: #8b8b8d;
}
.results-bar-container .results-bar-left-axis line,
.results-bar-container .results-bar-left-axis path {
  stroke: #8b8b8d;
}
.results-bar-container .results-bar-left-axis .tick line {
  display: none;
}
.results-bar-single-bar:hover {
  opacity: 0.5;
}

.results-page-export-overlay-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-popup-container {
  background-color: #fff;
  padding: 40px 0 20px 0;
  border-radius: 10px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
}
.results-page-export-container {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.results-page-export-container .results-page-export-introduction {
  padding: 0 35px;
  font-size: 14px;
}
.results-page-export-container
  .results-page-export-introduction
  .results-page-export-introduction-title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 20px;
}
.results-page-export-container
  .results-page-export-introduction
  .results-page-export-introduction-subtitle {
  font-style: italic;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}
.results-page-export-container .results-page-export-options {
  display: flex;
  padding: 30px 35px;
}
.results-page-export-container .results-page-export-option {
  box-shadow: 0px 3px 6px #00000029;
  border: 0.25px solid #95989a;
  border-radius: 6px;
  width: 120px;
  height: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.results-page-export-container .results-page-export-option-active {
  border: 2px solid #5300f2;
  box-shadow: none;
}
.results-page-export-container
  .results-page-export-option
  .results-page-export-option-icon {
  filter: grayscale(100%) opacity(0.4);
  width: 58px;
  height: 80px;
}
.results-page-export-container
  .results-page-export-option.results-page-export-option-active
  .results-page-export-option-icon {
  filter: none;
}
.results-page-export-container .results-page-export-options-information {
  max-width: 150px;
  margin-left: 40px;
}
.results-page-export-container
  .results-page-export-options-information
  .results-page-export-options-information-title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 10px;
}
.results-page-export-container
  .results-page-export-options-information
  .results-page-export-options-information-subtitle {
  font-style: italic;
}
.results-page-export-container .results-page-export-additional-details {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0 35px;
  box-sizing: border-box;
  width: 610px;
  max-width: 100%;
  min-height: 77px;
}
.results-page-export-container
  .results-page-export-additional-details.results-page-export-additional-details-visible {
  border-top: 1px solid #e6e6e6;
}
.results-page-export-container
  .results-page-export-additional-details
  .results-page-export-warning {
  display: flex;
  align-items: center;
  color: #8b8b8d;
  font-family: Open Sans SemiBold;
}
.results-page-export-container
  .results-page-export-additional-details
  .results-page-export-warning
  .results-page-export-warning-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox {
  margin-right: 8px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox.selected-checkbox {
  background-color: #5300f2;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox
  img {
  width: 10px;
}
.results-page-export-container .results-page-export-actions {
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}
.results-page-export-container
  .results-page-export-actions
  .results-page-export-confirm-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.results-page-export-container
  .results-page-export-actions
  .results-page-export-close-icon {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
  top: 3px;
  position: relative;
}

.result-view-data.area-chart-container {
  padding-left: 75px;
  display: flex;
}
.result-view-data.area-chart {
  width: 80%;
}
.result-view-data.stats-data {
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  font-size: 12px;
}
.result-view-data.stats-data .results-view-stats-big-container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.result-view-data.stats-data
  .results-view-stats-big-container
  .results-view-stats-big-number {
  font-family: 'Roboto Bold';
  font-size: 25px;
}
.result-view-data.stats-data
  .results-view-stats-big-container
  .results-view-stats-big-label {
}
.result-view-data.stats-data .results-view-stats-small-container {
  width: 100%;
}
.result-view-data.stats-data .results-view-stats-small-subcontainer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.result-view-data.stats-data
  .results-view-stats-small-container
  .results-view-stats-small-number {
  font-family: 'Roboto Bold';
  font-size: 17px;
}
.result-view-data.stats-data
  .results-view-stats-small-container
  .results-view-stats-small-label {
}

.result-view-data.area-chart .line {
  fill: none;
  stroke: #8500f0;
  stroke-width: 3px;
}

.result-view-data.area-chart .area {
  fill: #ebeaef;
}
.result-view-data.area-chart .axis {
}
.result-view-data.area-chart .axis line,
.result-view-data.area-chart .axis path {
  stroke: #9a9a9a;
}
.result-view-data.area-chart text,
.result-view-data.area-chart .axis-title {
  fill: #9a9a9a;
  font-size: 13px;
}
.result-view-data.area-chart .overlay {
  fill: none;
  pointer-events: all;
}
.result-view-data.area-chart .focus circle {
  fill: #ebeaef;
  stroke: #8500f0;
  stroke-width: 3px;
}
.result-view-data.area-chart .hover-line {
  stroke: #6f257f;
  stroke-width: 2px;
  stroke-dasharray: 3, 3;
}

.result-chart-cursor-pointer {
  cursor: pointer;
}
.result-chart-hover-opacity {
  opacity: 1;
}
.result-chart-on-hover-opacity:hover {
  opacity: 0.7;
}
.result-chart-half-opacity {
  opacity: 0.5;
}
.result-chart-grey-bar {
  opacity: 0;
}
.result-chart-grey-bar-active {
  opacity: 0.7;
}
.workspace-result-bar-0 {
  margin-left: 10px;
  margin-right: 10px;
}

.results-container .answers-container .sort-answers-table-container {
  margin-top: 40px;
}
.results-container .answers-container .table-row {
  display: flex;
  border-bottom: 2px solid #d9d8da;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .left-title {
  width: 30%;
  background-color: #f1eff1;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bold;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .top-title {
  background-color: blue;
  flex-grow: 1;
  flex-basis: 0;
  background-color: #f1eff1;
  padding: 10px;
  font-weight: bold;
  margin-right: 2px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .top-title.left-title {
  flex-grow: initial !important;
  flex-basis: initial !important;
  background-color: #f1eff1;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .table-value {
  background-color: #ffffff;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  margin-right: 2px;
  overflow: hidden !important;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .table-value
  .percentage {
  font-size: 12px;
}

.logic-map-container-results-page :global .edgePath path {
  fill: none;
}
.logic-map-container-results-page :global .flow-chart-question-type {
  cursor: pointer;
}
.logic-map-container-results-page :global .flow-chart-zoom-control-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  display: flex;
}
.logic-map-container-results-page :global .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}

@media only screen and (max-width: 1395px) {
  .main-results-container {
    width: calc(100% - 30px);
  }
  .toolbar-container {
    width: calc(100vw - 40px);
  }
  .blocks-results-header {
    width: 100%;
  }
  .content-results-header-loading,
  .results-content-loading {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
  .content-results-header {
    width: calc(100vw - 417px);
  }
  .right-results-header-content {
    width: 100%;
    justify-content: center;
  }
  .right-results-header-content .right-header-content-container:last-child {
    margin-right: 30px;
  }
  .blocks-results-column-container {
    width: calc(100vw - 417px);
  }
  .blocks-results-column-container.blocks-results-loading {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .blocks-results-wrapper {
    padding-top: 15px;
  }
  .blocks-results-header {
    overflow-x: visible;
  }
  .content-results-header-loading,
  .content-results-header {
    position: relative;
    display: block;
    height: auto;
    padding-left: 0px;
  }
  .content-results-header-loading {
    padding-bottom: 0;
  }
  .results-content-loading {
    flex-flow: column-reverse;
  }
  .content-results-header {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
  .action-results-column-container,
  .action-results-column-container.action-results-column-container-sticky {
    width: auto;
    position: relative;
  }
  .my-campaigns-action {
    display: none;
  }
  .results-content {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    top: 0px;
  }
  .toolbar-container,
  .toolbar-container-fixed {
    flex-wrap: wrap;
    position: relative;
  }
  .toolbar-container .navigation {
    margin-right: 5px;
  }
  .toolbar-container .navigation-item {
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .toolbar-container .toolbar-button {
    line-height: normal;
    font-size: 13px;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .toolbar-container .toolbar-button:nth-child(2) {
    margin-left: 0;
  }
  .toolbar-container .toolbar-button .edit-drop-down .edit-drop-down-option {
    height: 25px;
    line-height: 25px;
    padding-left: 10px;
  }
  .blocks-results-column-container {
    width: 100%;
    margin-left: 0px;
  }
  .results-container .answers-container {
    max-height: 1000px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution {
    display: block;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-bar-container {
    width: 70%;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-percentage {
    width: auto;
    padding-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-answer-count {
    width: auto;
  }
  .action-results-column-container .demographic-results-container,
  .action-results-column-container {
    max-height: initial;
  }
  .left-results-header-content {
    padding-left: 10px;
  }
  .right-results-header-content .right-header-content-container {
    justify-content: flex-start;
    padding-left: 15px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-answer-details {
    display: block;
  }
  .footer-container {
    display: none;
  }
}

.campaign-results-drag-rect {
  fill: #000000;
  opacity: 0.3;
  cursor: pointer;
}

.results-container-response-times-container {
  margin-top: 13px;
  padding-top: 20px;
}
.results-container-response-times-container .area {
  fill: rgb(82, 0, 241);
  clip-path: url(#clip);
}

.results-container-response-times-container .zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.results-container-response-times-container line,
.results-container-response-times-container path {
  stroke: #9a9a9a;
}
.results-container-response-times-container text {
  fill: #9a9a9a;
}
.results-container-response-times-container .selection {
  opacity: 0.7;
}

.print-popup-alert-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.print-popup-alert {
  width: 425px;
  background-color: #ffffff;
  border: 1px solid #efefef;
  padding: 20px;
  border-radius: 5px;
}
.print-popup-alert .print-text {
  display: block;
  color: #221f1f;
  margin-bottom: 20px;
}
.print-popup-alert .print-button {
  display: block;
  width: min-content;
  margin: 0 auto;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
  background-color: #5200f1;
  text-align: center;
  font-size: 14px;
  padding: 0 30px;
  cursor: pointer;
  border-radius: 20px;
}

.content-result-not-found {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #8b8b8d;
  margin-top: 56px;
}

.data-title {
  color: #000000;
  font-family: 'Open Sans SemiBold';
  font-size: 14px !important;
}

/* PRINT STYLESHEET */
@media print {
  .print-popup-alert-background {
    display: none !important;
  }
  .main-results-container {
    max-width: 95%;
    width: auto;
  }
  .main-results-container .results-content {
    width: 100%;
    display: block;
  }
  .toolbar-container {
    width: calc(100vw - 40px);
  }
  .blocks-results-wrapper {
    padding-top: 15px;
  }

  .action-results-column-container {
    width: 100%;
    background-color: #ffffff;
    position: relative;
  }
  .action-results-column-container .container-title {
    flex-wrap: wrap;
  }
  .blocks-results-header {
    width: 100%;
    position: relative;
    overflow-x: visible;
  }
  .blocks-results-header.blocks-results-header-sticky {
    position: relative;
  }
  .blocks-results-header .content-results-header {
    width: 100%;
    margin-left: 0px;
  }

  .action-results-column-container
    .container-title
    .side-results-container-title-item.active {
    display: inline-block;
  }
  .action-results-column-container
    .container-title
    .side-results-container-title-item {
    display: none;
  }

  .action-results-column-container .demographic-results-container {
    max-height: none;
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-results-container {
    width: 95%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-results-container
    .filter-results-charts-bar {
    overflow: visible;
    transform: scale(1);
    transform-origin: center;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-type-reset {
    display: none;
  }

  .blocks-results-column-container {
    width: 100%;
    margin-left: auto;
    position: relative;
  }
  .blocks-results-column-container .results-container {
    break-inside: avoid;
    border: none;
    display: block;
  }

  .blocks-results-column-container
    .results-container
    .result-view-type-container {
    padding-left: 45px;
  }

  .blocks-results-column-container
    .results-container
    .answers-container
    .results-answers-distribution-container {
    max-height: none !important;
    padding-left: 45px;
  }
  .blocks-results-column-container
    .results-container
    .answers-container
    .filter-results-charts-bar-wrapper {
    padding-left: 20px;
  }
  .blocks-results-column-container
    .results-container
    .answers-container
    .filter-results-charts-bar {
    overflow: hidden;
    transform: scale(0.85);
    transform-origin: left;
  }

  .blocks-results-column-container
    .results-container
    .block-structure
    .block-content
    .block-text {
    width: 100%;
    margin-right: 0;
  }

  .blocks-results-column-container
    .answers-container
    .results-answers-distribution-container
    .sort-answers-table-container {
    break-inside: avoid;
  }
  .blocks-results-column-container
    .answers-container
    .results-answers-distribution-container
    .sort-answers-table-container
    .table-row
    .left-title {
    width: 20%;
  }

  .footer-container {
    display: none;
  }

  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .answer-cover-image-container
    .left-container {
    display: none;
  }
  .results-container .cover-image-preview,
  .results-container .media-upload {
    display: none;
  }
}
