body {
  overflow-y: scroll;
}
.BUFFLLoaderContainer {
  text-align: center;
}
.BUFFLLoader {
  width: 64px;
  height: 64px;
  opacity: 0.7;
  margin-top: 100px;
}
.flowContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100vh;
}
.flowContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
}
.flowContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}
.flow {
  box-sizing: border-box;
  width: 1035px;
  top: 66px;
  margin: 0 auto;
  padding-bottom: 50px;
  font-size: 14px;
  border-left: 1px solid #e3e3e4;
  border-right: 1px solid #e3e3e4;
  min-height: calc(100vh - 66px) !important;
  position: relative;
}
.flow :global .flow-container {
  margin: 32px;
  margin-top: 0px;
}
.flow :global .container-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.flow :global .block-container {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  padding-top: 35px;
  margin-left: 20px;
}
.flow :global .block-container.focused {
  border-left: 4px solid #8500f0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}
.flow :global .block-container .block-structure {
  display: flex;
}

.flow :global .properties-column-container {
  position: relative;
  margin-left: 0;
}

.flow :global .welcome {
  width: 636px;
  display: inline-block;
}
.flow :global .properties-column-container {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}
.flow :global .block-content .block-text .description,
.flow :global .block-content .block-text .block {
  width: 100%;
}

.flow :global .side-container-properties {
  width: auto;
}

.flow :global .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
}

.flow :global .media-upload a:link,
.flow :global .media-upload a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.flow :global .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.flow :global .action.remove {
  color: red;
}
.flow :global ::placeholder {
  color: '#aaa';
}

@media only screen and (max-width: 1035px) {
  .flow {
    width: 100%;
  }
}
