.container {
  display: flex;
}
.range {
  border: 0;
  background-color: #cecece;
  color: #ffffff;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 5px;
}
.range.active {
  background-color: #5200f1;
}
