.container {
  position: absolute;
  bottom: -11px;
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.container.disabled {
  cursor: default;
  background-color: #cecece;
}
.container .tooltip {
  display: none;
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 100;
  font-size: 12px;
  width: 320px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.container:hover .tooltip {
  display: block;
}
