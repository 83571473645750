.container {
  display: flex;
}
.blockAdditionalOption {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.blockAdditionalOption.matrixBlockAdditionalOption {
  margin-right: 15px;
}
.close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2px;
}
.close img {
  width: 20px;
  height: 20px;
}
.close.disabled {
  opacity: 0.3;
}
.logicJumpAdmin {
  cursor: pointer;
  position: absolute;
  right: -60px;
}
.logicJump {
  cursor: pointer;
  position: absolute;
  right: -30px;
}
.quota {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -3px;
  font-size: 18px;
}
.logicJump svg,
.logicJumpAdmin svg,
.quota svg {
  width: 17px;
  height: 11px;
}
.allowOther {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.answerLabel {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 62px;
  resize: none;
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 2px;
  min-height: 19px;
}
.lock {
  position: absolute;
  right: 30px;
}
.lockIcon {
  align-self: flex-start;
  padding-top: 4px;
  padding-left: 4px;
}
.lockTooltip {
  position: absolute;
  display: none;
  background-color: #a97ff8;
  right: -220px;
  top: -8px;
  font-size: 12px;
  color: #fff;
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  z-index: 25;
  box-shadow: -5px 5px 10px 5px rgba(0, 0, 0, 0.05);
}
.lockTooltipArrow {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 10px solid #a97ff8;
  position: absolute;
  left: -7px;
  top: 5px;
}
.lockIcon:hover + .lockTooltip {
  display: block;
}
.deboundeIcon {
  margin-left: 60px;
  padding-bottom: 11px;
}
.answerOtherNoneLabel {
  color: #5200f1;
  border: 1px solid #5200f1;
  position: absolute;
  left: 60px;
  text-transform: uppercase;
  width: 52px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  padding: 3px 0;
  border-radius: 5px;
}
