.title {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  margin-bottom: 15px;
}
.container {
  margin-bottom: 15px;
  display: flex;
}
.optionContainer {
  margin-right: 15px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
}
.optionContainer input {
  margin-right: 5px;
  margin-top: 1px;
  accent-color: #5200f1;
  outline: none;
}
.moreInfoContainer .questionMark {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.moreInfoContainer .tooltip {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.moreInfoContainer:hover .tooltip {
  display: block;
}
