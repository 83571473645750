.register {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.greenLink {
  color: #29f5b2;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.mainContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.form {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
}
.title {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.input {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.grey {
  margin-top: 10px;
  font-size: 14px;
  color: #d3d3d3;
  cursor: pointer;
}
.label {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans Light';
}
.button {
  display: block;
  text-align: center;
  background-color: #29f5b2;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
}
.button:hover {
  opacity: 0.7;
}
.error {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.bottomBlobWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.bottomBlob {
  position: absolute;
  bottom: -1000px;
  width: 1718px;
  height: 1373px;
  left: -705px;
}
.herdyWorking {
  position: absolute;
  width: 651px;
  height: 570px;
  width: 554px;
  height: 400px;
  bottom: -60px;
  left: 200px;
}

@media only screen and (max-width: 1024px) {
  .mainContainer {
    flex-direction: column;
    box-sizing: border-box;
  }
  .form {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .herdyWorking {
    width: 359px;
    height: 259px;
    left: 25px;
  }
}
