.container {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
}
.container .loadingContainer {
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rangesContainer {
  margin-right: 30px;
}
.headerContainer .title {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
}
.headerContainer .valueContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.headerContainer .valueContainer .value {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 26px;
  margin-right: 10px;
}
.headerContainer .description {
  width: 80px;
  font-size: 13px;
  line-height: 14px;
}
.chartContainer {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  color: #7d7c7c;
  height: 162px;
}
.tooltipContainer {
  position: fixed;
  z-index: 9999999999999999;
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 15px;
}
