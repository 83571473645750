.user-campaign-questions-slider-container {
  margin-bottom: 60px;
}
.user-campaign-questions-slider-container .input-range__label--value {
  top: -33px !important;
}
.user-campaign-questions-slider-container .input-range__slider {
  background: var(--slider-circle-color, #24b2e8);
  border: 1px solid var(--slider-circle-color, #24b2e8);
}
.user-campaign-questions-slider-container .input-range__track--active {
  background: inherit;
}
.user-campaign-questions-slider-container .input-range__label {
  font-family: inherit;
}
.user-campaign-questions-slider-container
  .input-range__label.input-range__label--value {
  color: var(--slider-circle-color, #24b2e8);
}
.input-range__disabled {
  opacity: 0.3;
}
@media only screen and (max-width: 900px) {
  .user-campaign-questions-slider-container {
    margin-bottom: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
