.video-react {
  background-color: rgba(255, 255, 255, 0) !important;
}
.video-react,
.video-react-video {
  border-radius: 6px;
}
.video-react-big-play-button {
  background-color: rgba(255, 255, 255, 0) !important;
}
