.dropdownContainer {
  width: 50%;
  max-width: 300px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 15px;
  margin-right: 15px;
}
.dropdownContainer.dropdownVisisble:before {
  content: '';
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
.dropdownContainerLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.dropdownContainerLabel img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.dropdownContainerArrow {
  width: 20px;
  height: 20px;
}

.absoluteContainer {
  position: absolute;
  z-index: 25;
}
.container {
  position: static;
  background-color: #ffffff;
  width: 400px;
  max-height: 170px;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-vertical;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.03);
  padding-top: 5px;
  padding-bottom: 5px;
}
.question {
  position: static;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: #8b8b8d;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.question img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.horizontalLine {
  height: 10px;
  border-top: 1px solid #e2e2e2;
  margin: 10px;
}
.questionContainer {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.globalIndex {
  padding-left: 5px;
  padding-right: 2px;
}
.questionContainer.questionContainerWithConstraints {
  opacity: 0.3;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.groupIcon {
  padding-left: 4px;
  padding-right: 5px;
}
.nestingLevel1Padding {
  width: 20px;
  display: inline-block;
}
.nestingLevel2Padding {
  width: 40px;
  display: inline-block;
}
.footer {
  color: #8b8b8d;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  font-style: italic;
  top: -7px;
  text-align: center;
  display: block;
  width: 100%;
}
.distributorIconContainer img {
  width: 12px;
  height: 19px;
  top: 5px;
  position: relative;
}
.distributorIconPContainer img {
  width: 12px;
  height: 19px;
  top: 5px;
  position: relative;
}
