.container {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
}
.content {
  position: relative;
  top: 102px;
}
.sideContainer {
  width: 360px;
  position: fixed;
}
.createAPopulationButton {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 13px;
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  margin-bottom: 30px;
}
.createAPopulationButton.disabled {
  color: #95989a;
  opacity: 0.5;
}
.createAPopulationButton img {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 13px;
}
.createAPopulationButton.disabled img {
  filter: grayscale(100%) opacity(0.4);
}
.populationContainer {
  min-height: 300px;
  max-height: calc(100vh - 390px);
  overflow: auto;
}

.defaultSample {
  border: 1px solid #efefef;
  border-radius: 10px;
  page-break-inside: avoid;
  break-inside: avoid;
  box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  margin-bottom: 35px;
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.defaultSample .titleTextContainer {
  display: flex;
  align-items: center;
}
.defaultSample .colorBullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}
.defaultSample .populationLabel {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  color: #707070;
}
.defaultSample .filteredResultsCount {
  text-transform: lowercase;
}

.populationsInfoContainer {
  background-color: #ede5fe;
  padding: 15px;
  box-sizing: border-box;
}
.infoTitleWrapper {
  display: flex;
  margin-bottom: 5px;
}
.infoIcon {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.infoTitle {
  font-family: 'Roboto Medium';
}
.infoText {
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
}

@media only screen and (max-width: 1395px) {
  .container {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    top: 160px;
  }
  .createAPopulationButton {
    position: relative;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}
