.container {
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.searchContainer {
  display: flex;
  background-color: #f6f2fe;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
}
.removeQuestion {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.mainContent {
  padding: 0 20px;
  color: #777777;
  font-size: 13px;
}
.mainContent.isNotEligiblePage {
  color: #000000;
  font-size: 13px;
}
.title {
  font-size: 12px;
  color: #000000;
  margin-bottom: 10px;
  font-family: 'Open Sans SemiBold';
  padding-left: 15px;
  margin-top: 20px;
}
.questionContainer.limitHeight {
  max-height: 220px;
  overflow-y: auto;
}

.loaderFillContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 30px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
.getInTouchContainer {
  left: -32px;
  font-size: 12px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  width: calc(100% + 70px);
  margin-top: 30px;
}
.getInTouchContainer span {
  color: #5300f2;
  cursor: pointer;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
