.parentContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
}
.mainContainer {
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 42px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 20px;
}
.userDetailTitle {
  font-size: 12px;
  padding-left: 10px;
  margin-bottom: 5px;
}
.acceptInputContainer {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #4926e9;
}
.confirmInputContainer {
  margin: 50px 0;
}
.createProfileInputContainer {
  margin-top: 50px;
  margin-bottom: 20px;
}

.textDescription {
  font-family: 'Roboto Light';
}

.userDetailsPage .input {
  font-family: 'Roboto Light';
  display: block;
  width: 100%;
  color: #221f1f;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #707070;
  margin-bottom: 20px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 52px;
  line-height: 52px;
  border-radius: 30px;
  outline: none;
}
.userDetailsPage .input.errorField {
  border-color: #ec008b;
}
.userDetailsPage .labelText {
  display: block;
  font-family: 'Roboto Light';
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.label {
}
.userDetailsPage .formContainer .createUserProfile {
  width: 100%;
  height: 50px;
  font-size: 13px;
  line-height: 50px;
  background-color: #efefef;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}
.dontWantProfile {
  font-family: 'Roboto Light';
  color: #ec008b;
  margin-bottom: 40px;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
}

.languagesSelectorContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.languagesSelectorContainer .terms-language-selector {
  margin-right: 20px;
}

.readFullDocumentsContainer div {
  color: blue;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}
.readFullDocumentsContainer div:hover {
  text-decoration: underline;
}

.termsContentImage {
  display: block;
  width: 400px;
  margin: 16px auto 32px;
}

.textAlignCenter {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.restartSurveyButtonContainer {
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: right;
}

.sectionSubTitle {
  font-family: 'Roboto Bold';
  font-size: 16px;
  margin-bottom: 20px;
}

.accountCreateContainer {
  width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 90%;
}

.errorContainer {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorContainer .errorIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media only screen and (max-width: 900px) {
  .accountCreateContainer {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .termsContentImage {
    width: 80%;
  }

  .userDetailsPage .formContainer input,
  .userDetailsPage .formContainer select {
    width: calc(100% - 50px);
  }

  .title,
  .textDescription {
    width: 100%;
  }
  .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .errorContainer {
    font-size: 14px;
  }
}
