.container {
}
.header {
  padding: 20px;
}
.header .title {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  margin-bottom: 5px;
}
.header .subDescription {
  font-style: italic;
}
.fieldTitle {
  margin-top: 15px;
  margin-bottom: 5px;
}
.inputField {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
  height: 40px;
}
.inputField.inputFieldWithLabel {
  display: inline-block;
}
.inputFieldLabel {
  margin-left: 5px;
}
.inputCharacterCount {
  color: #b5b5b8;
  position: absolute;
  right: 10px;
  bottom: 12px;
}
.textareaField {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  height: 100px;
}
.amount1NarrowField {
  width: 80px;
}
.dropdownWiderFilterStyle {
  margin-bottom: 25px;
}
.dropdownWiderFilterStyle > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 355px;
}
.dropdownWiderFilterStyle.selectedValue > div:first-child {
  color: #000000;
}
.dropdownWiderFilterStyle input {
  width: 91%;
  margin-bottom: 0;
}
.filterDropdownPopup {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 37px;
  width: 350px;
  border: 1px solid #dfe0e4;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
