.line-chart-container .chart-group line,
.line-chart-container .chart-group path {
  stroke: rgba(111, 42, 127, 0.15);
  fill: none;
  stroke-width: 2px;
}
.line-chart-container .chart-group .data-circle {
  stroke: none;
  fill: #6f2a7f;
}
.line-chart-container .chart-group .data-circle .data-tooltip {
  stroke: none;
  color: #6f2a7f;
}
.line-chart-container .axis-group line,
.line-chart-container .axis-group path {
  stroke: none;
  fill: none;
}
.line-chart-container .axis-group text {
  font-size: 14px;
  font-family: 'Roboto Light';
  stroke: none;
}
.line-chart-container .grid-group line,
.line-chart-container .grid-group path {
  stroke: #a7a7a7;
  fill: none;
}
.line-chart-container .domain {
  display: none;
}
