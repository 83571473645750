.container {
  margin-bottom: 20px;
}
.noResultsPlaceholder {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.mainTable {
  width: 100%;
  border-collapse: collapse;
}
.headerRow {
  font-size: 13px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.headerRow th {
  font-weight: normal;
}
