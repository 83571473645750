.container {
  padding: 15px;
  width: 400px;
  font-size: 13px;
}
.title {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.description {
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #e6e6e6;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
