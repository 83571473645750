.container {
  width: 340px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;

  color: #8e8e8e;
  border: 1px #8e8e8e dashed;
  background-color: #f5f3f3;
  margin-bottom: 10px;
}
.active {
  background-color: #e6e0f6;
  color: #4a25e8;
  border: 1px #4a25e8 solid;
}
.stepNameAssigned {
  background-color: #ffffff;
  color: #414141;
  border: 1px #a2a2a2 solid;
}
.lastEventStep {
  margin-bottom: 0;
}
.binIcon {
  display: none;
  position: absolute;
  height: 15px;
  top: 22px;
  right: 23px;
  cursor: pointer;
}
.container:hover .binIcon {
  display: block;
}
.addNewStepIcon {
  position: absolute;
  display: inline-block;
  bottom: -30px;
  left: 50%;
  margin-left: -15px;
}
