.loader-fixed-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
