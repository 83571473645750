.container {
  padding-bottom: 30px;
}
.deleteIcon {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}
.campaignLinkContainer {
  width: 75%;
}
.linkInput {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 28px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  outline: none;
}
.filtersButtonContainer {
  display: flex;
}
.filtersButtonContainer .filtersButton {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.filtersButtonContainer .filtersButton img {
  width: 9px;
  margin-right: 5px;
}
.filtersButtonContainer .filtersButton.filtersButtonActive {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.filtersButtonContainer .minimumQuotaContainer {
  display: inline-flex;
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  margin-left: 13px;
  cursor: pointer;
  user-select: none;
}
.filtersButtonContainer .minimumQuotaContainer .minQuotaLabel {
  display: inline-block;
}
.filtersButtonContainer .minimumQuotaContainer .checkbox.checkboxSelected {
  color: #5200f1;
}
.filtersButtonContainer .minimumQuotaContainer .checkbox {
  color: #b5b5b8;
  height: 21px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.filtersContainer {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
  min-height: 36px;
}
.firstRowContainer {
  display: flex;
}
.dropdownContainers {
  display: flex;
  margin-top: 20px;
}
.searchDropdownTitle,
.campaignLinkTitle {
  font-size: 12px;
  margin-bottom: 10px;
}
