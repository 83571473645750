.container {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container .loadingContainer {
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.title {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.valueContainer {
  display: flex;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}
.valueContainer .value {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 36px;
  margin-right: 10px;
}
.valueContainer .description {
  width: 140px;
  font-size: 16px;
  line-height: 18px;
}
