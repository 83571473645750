.container {
  width: 795px;
  margin-right: 5px;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 30px;
}
.chartContainer {
  height: 400px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}
.footerContainer {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

.container :global .edgePath path {
  fill: none;
}
.container :global .flow-chart-question-type {
  cursor: pointer;
}
.container :global .flow-chart-zoom-control-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
  display: flex;
}
.container :global .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
