.translationInput {
  border: none;
  outline: none;
  white-space: pre-line;
  resize: none;
  overflow: hidden;
  color: #000;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
}
.translationInput:focus,
.translationInput:active {
  outline: none;
}
.translationInput:disabled {
  cursor: text;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}
.translationInput.enabled:hover {
  border-color: #dfe0e4;
}
.textAreaTranslation {
  white-space: pre-line;
}
