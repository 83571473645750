.targetAudienceTabContainer {
  margin-bottom: 10px;
}
.targetAudienceTabContainer .container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.targetAudienceTabContainer .container .containerTitle {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.targetAudienceTabContainer .container .blockContainer {
  padding: 15px 20px 15px 20px;
  font-size: 14px;
}
.redirectionTypeRowContainer {
  display: flex;
}
.redirectionTypeRowContainer .label {
  width: 30%;
}
.redirectionTypeRowContainer .inputContainer {
  width: 70%;
}
.redirectionTypeRowContainer .inputContainer input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-top: 8px;
  margin-bottom: 6px;
  overflow: hidden;
  resize: none;
}

input::placeholder {
  color: #bebebe;
}
.inputContainer {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  height: 30px;
  margin-bottom: 10px;
}
.inputContainer .link {
  padding-left: 10px;
  height: 30px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 30px;
  font-size: 12px;
  color: #8b8b8d;
  display: flex;
  flex-direction: row;
}
.inputContainer .link .websiteIcon {
  height: 12px;
  margin-right: 10px;
  margin-top: 8px;
}
.inputContainer .urlInput {
  border: 0;
  flex: 1;
  padding-right: 20px;
  outline: none;
  font-size: inherit;
}
.inputContainer .copyLinkButton {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
  text-align: center;
}
.inputContainer .copyLinkButton.copied {
  background-color: #95989a;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  cursor: pointer;
}
