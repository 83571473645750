.confirmation-dialog-container {
  width: 400px;
  margin: 5px;
}
.confirmation-dialog-container .confirmation-dialog-message {
  margin-bottom: 50px;
  text-transform: none;
  font-size: 15px;
  color: #000000;
}
.confirmation-dialog-container .confirmation-dialog-actions-container {
  display: flex;
  justify-content: space-between;
}
