.container {
  /*
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  */
}
.mainContainer {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1024px) {
  .mainContainer {
    width: 100%;
  }
}
