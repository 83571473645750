.container {
  margin-bottom: 10px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
}
.row {
  margin-bottom: 15px;
}
.row.flexRow {
  display: flex;
  justify-content: space-between;
}
.flexColumn {
  width: 49.5%;
}
.flexGrow {
  flex-grow: 1;
}
.label {
  font-size: 14px;
  color: #8b8b8d;
  margin-bottom: 5px;
}
.fieldContainer {
  position: relative;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  color: #000;
}
.field {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}
.field.draftField {
  min-height: 30px;
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: text;
}
.fieldContainer .dropdown {
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
}
.field::placeholder,
.fieldContainer .placeholder {
  user-select: none;
  color: #bebebe;
}
.fieldContainer .popupContainer {
  font-size: 14px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 1;
  box-sizing: border-box;
}
.fieldContainer .popupContainer .popupItemsContainer {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.popupItemsContainer .popupItem {
  line-height: 25px;
  height: 25px;
  cursor: pointer;
  padding-left: 10px;
}
