.container {
  margin-left: 40px;
  margin-right: 40px;
}
.answersList {
  margin-right: 40px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e6e6e6;
}
.answer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.answerColor {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 25px;
}
.answerString {
  display: inline-flex;
  flex-grow: 1;
  overflow: hidden;
}
.answerStringText {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
}
.answerOtherLabel {
  margin-left: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
}
.answerToggle {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
}
.answerSwitch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.answerSlider {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  user-select: none;
}
input:checked + .answerSlider:before {
  transform: translateX(20px) translateY(-50%);
}
.answerSlider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 15px;
  left: -15px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  transform: translateX(30px) translateY(-50%);
}
.input {
  display: none;
}
.otherAnswerResults {
  width: calc(100% - 90px);
  margin-left: 35px;
}
.otherAnswerResults.opened {
  margin-bottom: 12px;
}
.otherAnswerButton {
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  margin-bottom: 12px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.otherAnswerString {
  display: block;
  padding: 10px 0 10px 10px;
  color: #8b8b8d;
  font-size: 12px;
}
.otherAnswersList {
  margin-left: 4px;
  border-left: 1px dotted #8b8b8d;
}
.textOverflowInfo {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 11px;
  width: 400px;
}
