.addInsightButton {
  display: inline-block;
  line-height: 30px;
  padding: 0 10px 0 8px;
  margin-left: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.plusIcon {
  color: #5300f2;
}
.backgroundContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupContainer {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.popupContent {
  width: 600px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}
.footer .details {
  color: #5200f1;
}
.footer .footerErrorBackground {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.footer .error {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.footer .error .warningIcon {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.inputElement {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 14px;
  width: calc(100% - 12px);
}
.textareaElement {
  font-family: 'Open Sans Regular';
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  width: calc(100% - 12px);
}
