.container {
  box-sizing: border-box;
  width: 965px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  position: relative;
}

@media only screen and (max-width: 1035px) {
  .container {
    width: calc(100vw - 146px);
    margin-left: 30px;
  }
}
