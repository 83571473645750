.pageContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
}
.pageHeader {
}
.pageHeader .header1 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.pageContent {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
.whiteContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}

.pageContent .notificationDetailsContainer {
  width: calc(100% - 316px);
  margin-bottom: 30px;
}
.pageContentListPlaceholder {
  width: 300px;
  height: calc(100vh - 300px);
  margin-right: 20px;
  background-color: #ebeaef;
}
.pageContentMainPlaceholder {
  width: calc(100% - 316px);
  height: calc(100vh - 300px);
  background-color: #ebeaef;
}
.sections {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 32px;
  padding: 0;
}
.sections .sectionName {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.sections .sectionName.active {
  color: #000000;
}
