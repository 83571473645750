.container {
  /*
  height: 100vh;
  width: 100vw;
  */
  overflow-x: hidden;
  overflow-y: auto;
}
.mainContainer {
  width: calc(100% - 50px);
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
}
.backgroundContainer {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: calc(((100vw - 1200px) / 2) + 1200px - 550px);
  z-index: 0;
}
.backgroundContainerGreen {
  background-color: #f4fefb;
}
.backgroundContainerBlue {
  background-color: #fcfaff;
}
@media only screen and (max-width: 1024px) {
  .mainContainer {
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 40px);
  }
  .backgroundContainer {
    display: none;
  }
}
