.container {
  margin-bottom: 30px;
}
.warning {
  color: #fc035d;
  background-color: rgba(255, 0, 95, 0.12);
  font-family: 'Open Sans SemiBold';
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.warningIcon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
