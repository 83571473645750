.container {
  margin-top: 15px;
}
.row {
  color: #000;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}
.rowContainer {
  display: flex;
  flex-direction: column;
}
.row .rectangle {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}
.row .dots {
  position: absolute;
  width: 10px;
  left: 0;
  bottom: -1px;
  font-size: 18px;
  color: #8b8b8d;
  letter-spacing: -1px;
}
.bold {
  font-family: 'Open Sans Bold';
}
