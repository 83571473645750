.container {
  padding-top: 18px;
}
.explanation {
  margin: 15px 0;
  font-size: 12px;
}
.subtitle {
  font-size: 12px;
  margin-bottom: 5px;
}
.dropdownWrapper {
  margin-bottom: 10px;
}
.touchpointsTooltipContainer {
  display: inline-block;
  margin-left: 5px;
}
.touchpointsTooltipIcon {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.touchpointsTooltipPopup {
  position: absolute;
  left: -30px;
  bottom: 20px;
  padding: 0px 30px;
  width: 250px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px 0px;
  font-style: italic;
}
.touchpointsTooltipIcon:hover + .touchpointsTooltipPopup {
  display: block;
}
