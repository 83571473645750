.container {
  display: flex;
  padding-bottom: 30px;
}
.deleteIcon {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}
