.container {
  width: 320px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.title {
  margin-bottom: 10px;
}
.fieldRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.fieldRowNumber {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 5px;
}
.field {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}
