.container {
  border: 1px solid #ceced4;
  border-radius: 5px;
  -webkit-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  margin: 0 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-left: 5px solid #5200f1;
}
.iconContainer {
  height: 48px;
  width: 80px;
  text-align: center;
}
.description {
  height: 48px;
  line-height: 48px;
  flex: 1;
  color: #6d6d6d;
}
.buttonContainer {
  width: 140px;
  height: 48px;
}
.buttonContainer .button {
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #fc025d;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  width: 140px;
}
