.tooltipContainer {
  position: absolute;
  right: -230px;
  top: 0px;
  z-index: 20;
}
.tooltip {
  width: 200px;
  font-size: 12px;
  font-family: 'Open Sans Light';
  background-color: #ebe4fe;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.tooltip:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
}
