.container {
  position: absolute;
  left: 39px;
  top: -21px;
  width: 450px;
  background: #a97ff8;
  color: #fff;
  border-radius: 6px;
  cursor: default;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px;
  z-index: 25;
}
.arrow {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 17px solid #a97ff8;
  position: absolute;
  left: -16px;
  top: 12px;
}
.header {
  border-bottom: 1px solid rgba(255, 255, 255, 30%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  padding: 15px 25px;
  align-items: center;
}
.description {
  padding: 5px 25px 20px 25px;
}
.addAnswerButton {
  padding: 5px 10px;
  border: 1px solid #ececec;
  border-radius: 7px;
  font-size: 12px;
  color: #000;
  background: #fff;
  width: fit-content;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
.addAnswerIcon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.addOpenAnswerIcon {
  width: 15px;
  height: 14px;
  margin-right: 10px;
}
.xIcon {
  cursor: pointer;
}
