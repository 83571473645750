.listContainer {
  margin-top: 30px;
}
.loadingItem {
  height: 40px !important;
}
.tableContainer {
  margin-top: 30px;
  font-family: 'Open Sans Regular';
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.headerRow th {
  text-align: left;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ebeaef;
}
.tableRow td {
  border-bottom: 1px solid #ebeaef;
  height: 30px;
  line-height: 30px;
}
.tableRow td span:hover {
  text-decoration: underline;
  color: #5200f1;
  cursor: pointer;
}
.noResults {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
}
.visibleForSearchCheckbox {
  cursor: pointer;
  width: 12px;
  height: 12px;
}
