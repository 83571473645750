.wrapper {
  background-color: #161b26;
}
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1190px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 60px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.link {
  font-family: 'Roboto Regular';
  color: #98a2b3;
  text-decoration: none;
  margin-right: 60px;
  transition: all 0.1s ease-in-out;
}
.link:hover {
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .link {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }
}
