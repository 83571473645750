.popup {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  z-index: 9999;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  scrollbar-width: thin;
}
.actionDropdownPopup {
  z-index: 99999;
}
.popup::-webkit-scrollbar {
  -webkit-appearance: none;
}
.popup::-webkit-scrollbar:vertical {
  width: 11px;
}
.popup::-webkit-scrollbar:horizontal {
  height: 11px;
}
.popup::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.option,
.option:link,
a.option:visited {
  display: block;
  height: 40px;
  line-height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8b8b8d;
  text-decoration: none;
  cursor: pointer;
}
.option:hover {
  opacity: 0.7;
}
.separator {
  background-color: #ebeaef;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.icon {
  margin-right: 10px;
  color: #5200f1;
  font-size: 12px;
}
.option.action-dropdown-withdraw-request-option {
  line-height: normal;
  height: auto;
  display: flex;
}
.action-dropdown-publish-option.action-dropdown-publish-option-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
