.container {
  padding-top: 5px;
}
.title {
  display: inline-flex;
  align-items: center;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.nextStepsTitle {
  cursor: pointer;
}
.collapsedTitle {
  border-radius: 5px;
}
.titleBackgroundDark {
  background-color: #c9bcff;
}
.titleBackgroundLight {
  background-color: #ebe6fc;
}
.titleIcon {
  display: inline-block;
  position: relative;
  max-height: 16px;
  max-width: 13px;
  margin-right: 7px;
  margin-top: 1px;
}
.arrowIcon {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-left: 7px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.rotatedArrow {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nextStepsContainer {
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.textContainer {
  padding: 15px 20px;
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.textContainer .textField {
  margin-bottom: 10px;
}
.textContainer .textField:last-child {
  margin-bottom: 0;
}
.textContainer .textField .textFieldTitle {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 4px;
}
.nextStepContainer {
  border-bottom: 1px solid #d3d3d3;
}
.nextStepsContainer .textContainer {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
}
.nextStepsContainer .addNextStep {
  border-bottom: none;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.nextStepsContainer .addNextStep:hover {
  opacity: 0.7;
}
.nextStepsContainer .addNextStep .plusIcon {
  color: #5300f2;
  margin-right: 5px;
}

.showActionDropdownButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: right;
  height: 50px;
  position: absolute;
  top: -12px;
  right: 10px;
}
.actionDropdownContainer {
  display: none;
  position: relative;
  top: 2px;
  right: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  z-index: 999;
  color: #9d9d9d;
}
.actionDropdownContainerOption {
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
}
.showActionDropdownButton:hover .actionDropdownContainer {
  display: block;
}
