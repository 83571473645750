.blockRow {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
  display: flex;
}
.sliderValuesContainer {
  padding-right: 0;
  margin-right: 15px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  display: flex;
  align-items: center;
}
.fieldContainer {
  display: flex;
  align-items: center;
}
.fieldContainer :global(input) {
  margin: 0;
  display: inline-block;
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  max-width: 30px;
  margin-right: 20px;
}
.sliderLogicJumpsRow {
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  padding-top: 20px;
  padding-right: 0;
  margin-right: 15px;
  display: block !important;
}
.branchIcon {
  display: inline-block;
  cursor: pointer;
}
