.appBarContainer {
  height: 150px;
  background-color: #fff;
  z-index: 999;
}
.webSurveyLogoContainer {
  width: 1100px;
  max-width: 90%;
  margin: 0 auto;
  line-height: 150px;
}
.webSurveyLogo {
  width: 115px;
  height: 23px;
  font-family: 'Roboto Light Light' !important;
}
.webSurveyCustomLogo {
  max-width: 185px;
  max-height: 85px;
  height: auto;
  font-family: 'Roboto Light Light' !important;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .appBarContainer {
    height: 70px;
  }
  .webSurveyLogoContainer {
    line-height: 70px;
  }
  .webSurveyLogo {
    width: 75px;
    height: 15px;
  }
  .webSurveyCustomLogo {
    margin-top: 10px;
    max-width: 120px;
    max-height: 50px;
  }
}
