.container {
  padding: 50px 100px;
}
.title {
  color: #4a25e8;
  text-align: center;
  font-family: 'Open Sans SemiBold';
  font-size: 16px;
  margin-bottom: 5px;
}
.description {
  color: #a8a8a9;
  text-align: center;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  margin-bottom: 40px;
}
