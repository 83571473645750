.container {
}
.container :global .tick line {
  visibility: hidden;
}
.container :global .tick text {
  font-family: 'Open Sans Regular';
}
.container :global path.domain {
  stroke: #b5b5b8;
}
.allOptionsHidden {
  color: #95989a;
  position: absolute;
  transform: translate(-50%, -55%);
  left: 50%;
  top: 45%;
  font-size: 14px;
}
