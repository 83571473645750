.container {
  width: 100%;
}
.container .bubble1 {
  position: absolute;
  top: 0px;
  left: -700px;
}
.container .bubble2 {
  position: absolute;
  top: 0px;
  right: -500px;
}
