.dropdownContainer {
  width: 50%;
}
.placeholderContainer {
  width: 100%;
  max-width: calc(100% - 5px);
  position: relative;
  background-color: #ffffff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
}
.placeholderContainer img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
