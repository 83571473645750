.container {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
}
.container .loadingContainer {
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rangesContainer {
  margin-right: 30px;
}
.headerContainer .title {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
}
.headerContainer .valueContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.headerContainer .valueContainer .value {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 26px;
  margin-right: 10px;
}
.headerContainer .description {
  width: 80px;
  font-size: 13px;
  line-height: 14px;
}
.chartContainer {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.tooltipContainer {
  position: fixed;
  z-index: 9999999999999999;
  color: #000000;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000000;
}
.legend {
  text-align: center;
}
.legend .legendItem {
  display: inline-block;
  margin: 0 10px;
  font-size: 11px;
}
.legend .legendItem .bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
}
.legend .legendItem .bullet.blue {
  background-color: #5200f1;
}
.legend .legendItem .bullet.lightblue {
  background-color: #9f94ed;
}
.moreInfoContainer {
  display: inline-block;
  margin-left: -10px;
  position: relative;
  top: 1px;
}
.moreInfoContainer .questionMark {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.moreInfoContainer .tooltip {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.moreInfoContainer:hover .tooltip {
  display: block;
}
