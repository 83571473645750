.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 180px);
}
.authorizedContainer {
  height: calc(100vh - 480px);
  margin-left: 360px;
}
.heardy {
  margin-bottom: 30px;
  height: 200px;
}
.title,
.text {
  font-size: 12px;
  margin-bottom: 25px;
  max-width: 300px;
  text-align: center;
}
.title {
  color: #5200f1;
  font-family: 'Open Sans Bold';
}
.text {
  color: #8b8b8d;
}
.newWorkspaceButton {
  display: inline-block;
  color: #5200f1;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: Open Sans SemiBold;
  user-select: none;
  text-align: center;
  border: 1px solid #c3c3c3;
}
.editIcon {
  height: 12px;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .container {
    height: auto;
  }
}
