.questionsContainer {
  border: 1px solid #bf9dff;
  border-radius: 5px;
}

.groupHeader {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}
.groupHeaderNoActions {
  display: flex;
  flex-direction: row;
}

.groupNameContainer {
  background-color: #5200f1;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 54px);
}

.groupIcon {
  padding: 5px;
  display: flex;
}

.groupName {
  color: white;
  font-family: 'Open Sans SemiBold';
  padding-left: 5px;
  white-space: nowrap;
  width: auto;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
}

.changeGroupName {
  color: #5200f1;
}

.blurGroupName {
  background-color: transparent;
  border: none;
}

.groupSettingsButton {
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.settingsIcon {
  margin-top: 2px;
  width: 18px;
  height: 18px;
}
