.appBarWrapper {
  border-bottom: 1px solid #e4e7ec;
  box-shadow: 0 2px 100px #00000005;
  margin-bottom: 50px;
}
.appBar {
  height: 100px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}
.logo {
  width: 89px;
}
.languageActive {
  font-family: 'Open Sans SemiBold';
}
.languageOption {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
}
.languageOption:last-child {
  margin-left: 10px;
}
