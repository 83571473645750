.container {
  padding: 0 35px;
}
.sections {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 0px;
  z-index: 1000;
  padding: 0;
}
.sections .sectionName {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.sections .sectionName.active {
  color: #000000;
}

.subsectionContainer {
  border-top: 1px solid #e4e4e4;
  margin-top: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.subsectionContainer .subsectionTitle {
  padding: 5px 25px;
  background-color: #ffffff;
  top: -15px;
  color: #8b8b8d;
  text-transform: uppercase;
  font-size: 12px;
}

.webContainer,
.mobileContainer {
  text-align: center;
}

.smallTitle {
  color: #5200f1;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Open Sans Bold';
  font-size: 12px;
  margin-bottom: 10px;
}
.description {
  text-align: center;
  color: #8b8b8d;
  width: 550px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.inputContainer {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  height: 30px;
}
.inputContainer .link {
  padding-left: 10px;
  height: 30px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
}
.inputContainer .link .websiteIcon {
  height: 12px;
  margin-right: 10px;
}
.inputContainer .copyLinkButton {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
}
.inputContainer .copyLinkButton.copied {
  background-color: #95989a;
  cursor: default;
}
.bigButton {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
