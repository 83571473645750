.container {
  display: flex;
  font-family: 'Open Sans Regular';
  justify-content: space-between;
}
.left {
  background-repeat: no-repeat;
  cursor: pointer;
}
.left img {
  transform: rotate(180deg);
  margin-right: 10px;
}
.right {
  background-repeat: no-repeat;
  cursor: pointer;
}
.right img {
  margin-left: 10px;
}
.left.disabled,
.right.disabled {
  opacity: 0.2;
}
