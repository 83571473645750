.confirmInputContainer {
  display: flex;
  font-family: 'Open Sans Light';
  text-align: left;
}
.confirmInputContainer .checkmark {
  top: 4px;
  width: 15px;
  height: 15px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #000000;
  cursor: pointer;
  flex-shrink: 0;
  user-select: none;
}
.tick {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 11px;
  height: 8px;
}
.confirmInputContainerActive .checkmark {
  background-color: #5200f1;
  border-color: #5200f1;
}
.confirmInputContainer .label {
  padding-left: 20px;
}
