.filterDropdownSelectorContainer {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 290px;
  margin-right: 10px;
}
.selectedOptionsLabel {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.selectedOptionsLabel.valueSelected {
  color: #000000;
}
.filterDropdownPopup {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.filterDropdownItem {
  display: flex;
  justify-content: space-between;
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.filterDropdownItem.active {
  background-color: #fafafa;
}
.filterDropdownItemCheckboxSelected {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
  cursor: pointer;
}
.filterDropdownItemCheckbox {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
  cursor: pointer;
}
.filterDropdownItemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.noAvailableClientAccounts {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.filterDropdownPopupSearchInputContainer {
  display: flex;
}
.filterDropdownPopupSearchInputContainer input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.filterDropdownPopupSearchInputContainer .inputLoaderContainer > div {
  position: relative;
  top: -10px;
}
.filterDropdownPopupItemsContainer {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.filterDropdownPopupFooterContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.filterDropdownPopupConfirm {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.addItemIcon {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}
.additionalInfoContainer {
  padding: 7px 10px;
  border-top: 1px solid #efefef;
  line-height: 20px;
}
.additionalInfoContainer .title {
  color: #868686;
}
.additionalInfoContainer .checkboxContainer {
}
.additionalInfoContainer .checkboxContainer .checkboxSelectAllContainer {
  position: relative;
  top: 7px;
}
.quotaInputContainer {
  font-size: 11px;
  font-family: 'Open Sans Regular';
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.quotaInput {
  height: 25px;
  line-height: 25px;
  width: 58px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1px solid #bcbbbb;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 11px;
  text-align: right;
}
.quotaIcon {
  position: absolute;
  left: 8px;
  font-size: 13px;
}
.dropdownDisabled {
  background-color: #f9f9f9 !important;
  color: #8b8b8d !important;
}
