.acceptInputContainer {
  width: 520px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
  text-align: left;
  display: flex;
}
.acceptInputContainer .checkmark {
  top: 4px;
  width: 17px;
  height: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;
  user-select: none;
  border-width: 2px;
  border-style: solid;
}
.tick {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.acceptInputContainerActive span {
  width: 10px;
  height: 10px;
}
.acceptInputContainer .termsText {
  text-align: left;
}
.acceptInputContainer .termsText .termsLink {
  text-decoration: underline;
  cursor: pointer;
}
.acceptInputContainer .desktopInput {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .acceptInputContainer {
    flex-direction: column;
    text-align: center;
  }
  .acceptInputContainer .termsText {
    margin-bottom: 20px;
  }
}
