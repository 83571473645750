.topContainer {
  margin: 50px 33px 32px 33px;
}
.container {
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 4px;
}
.containerTitle {
  display: flex;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 30px;
  padding-right: 30px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
}

.containerTitle .advancedSettingsButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  top: -5px;
}
.containerTitle .advancedSettingsDropdown {
  top: 40px;
  right: 0px;
  width: auto;
  position: absolute;
  background-color: #fff;
  border: 1px solid #e7e4e4;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  z-index: 9;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
}
.containerTitle .advancedSettingsDropdownOption {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.containerTitle .advancedSettingsDropdownOption:hover {
  opacity: 0.6;
}

.blockContainer {
  padding: 15px 30px 15px 0;
  font-size: 14px;
  background-color: #fff;
  border-left: 4px solid #fff;
  border-bottom: 1px solid #eee !important;
  margin-left: 20px;
}
.singleBlock {
  display: flex;
  justify-content: space-between;
}
.singleBlock .label {
  width: 350px;
  padding-left: 8px;
}
.singleBlock .label.descriptionCorrected {
  top: 15px;
}
.singleBlock .description {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  outline: none;
  display: block;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-top: 8px;
  margin-bottom: 6px;
  overflow: hidden;
  resize: none;
  width: 125px;
}

.rowContainer {
  display: flex;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #ececec;
}
.rowContainer:first-child {
  margin-top: 20px;
}
.rowContainer:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 20;
}
.scalesContainer {
  width: 100%;
  padding-right: 20px;
  margin-left: 15px;
}
.additionalConfigurationContainer {
  display: flex;
  width: 560px;
  flex-direction: row;
  justify-content: right;
}
.deleteFilterRowIcon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

/* Age scales */
.scalesContainer :global .input-range {
  position: relative;
  top: 5px;
}
.scalesContainer
  :global
  .input-range
  .input-range__slider-container:first-of-type
  .input-range__slider {
  background: #8500f0 !important;
}
.scalesContainer
  :global
  .input-range
  .input-range__slider-container:last-of-type
  .input-range__slider {
  background: #8500f0 !important;
}
.scalesContainer :global .input-range__label--value {
  top: -33px !important;
}
.scalesContainer :global .input-range__label {
  color: #8500f0 !important;
  font-family: 'Open Sans Regular';
}
.scalesContainer :global .input-range__slider {
  background: #8500f0 !important;
  border: 1px solid #8500f0 !important;
  z-index: 2;
}
.scalesContainer :global .input-range__track--active {
  background: #937ff0;
  z-index: 2;
}
.scalesContainer :global .input-range__label {
  font-family: 'Open Sans SemiBold';
}
.scalesContainer :global .input-range__label--min {
  display: none;
}
.scalesContainer :global .input-range__label--max {
  display: none;
}

/* Gender pickers */
.scalesContainer .pickers {
  top: -20px;
}
.scalesContainer .valueOption {
  display: inline-block;
  background-color: #ffffff;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  top: 8px;
  position: relative;
}
.scalesContainer .valueOption.active {
  background-color: #8500f0;
  color: #ffffff;
}
.scalesContainer .valueOption.disabled {
  cursor: default;
}

.scalesContainer .targetValuesTrack {
  position: absolute;
  top: 5px;
  height: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.scalesContainer .targetValues {
  height: 4.8px;
  position: relative;
  background-color: #95989a;
  display: inline-block;
  z-index: 1;
  width: calc(100% - 20px);
}
.scalesContainer .targetLeftValue,
.scalesContainer .targetRightValue {
  position: absolute;
  display: inline-block;
  height: 15px;
  width: 4px;
  border-radius: 4px;
  background-color: #95989a;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 11px;
}
.scalesContainer .targetValues .targetLeftValue span,
.scalesContainer .targetValues .targetRightValue span {
  left: -5px;
  top: 18px;
  position: absolute;
  color: #aaaaaa;
}
.scalesContainer .targetValues .targetLeftValue {
  left: -1.5px;
}
.scalesContainer .targetValues .targetRightValue {
  right: -3.15px;
}

.additionalConfigurationContainer input {
  margin-left: 8px;
  margin-right: 8px;
  width: 50px;
  border: 1px solid #bebebe;
}
.additionalConfigurationContainer select {
  border: 1px solid #bebebe;
  width: 35px;
  background-color: #ffffff;
}

.warningTitle {
  min-height: 45px;
  height: auto;
}

.dynamicFilterWarnings .dynamicFilterWarningMessageBlock {
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dynamicFilterWarnings .dynamicFilterWarningMessageBlock .warningMessage {
  font-weight: 700;
  color: #fc035d;
  text-transform: none;
  margin-left: 20px;
  display: flex;
}
.dynamicFilterWarnings .dynamicFilterWarningMessageBlock .warningIcon {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block;
  top: 2px;
  margin-right: 10px;
}

.noFiltersAvailable {
  text-align: center;
  color: #8b8b8d;
}
