.container {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.content {
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 20px;
}
.content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.title {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.input {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.input::placeholder {
  color: #cbcbcb;
}
.input:focus {
  outline: none;
}
.textareaField {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.textareaField::placeholder {
  font-family: 'Open Sans Regular';
  color: #cbcbcb;
}
.dropdownContainer {
  margin-bottom: 10px;
}
.tooltipContainer {
  display: inline-block;
}
.tooltipIcon {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
  margin-left: 5px;
}
.tooltipPopup {
  position: fixed;
  left: 30px;
  bottom: 30px;
  padding: 8px 12px;
  width: 355px;
  height: 30px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  color: #494949;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
}
