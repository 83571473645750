.container {
}
.dataPointContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ebe5fc;
}
.fieldsContainer {
  margin-top: 5px;
}
.close {
  position: absolute;
  top: 18px;
  right: -32px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
