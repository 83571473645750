.container {
  left: 10px;
  top: 35px;
  width: 340px;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 100px;
  background-color: red;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  z-index: 20;
  overflow: auto;
}
.dropdownItem {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  font-size: 11px;
}
.dropdownItemCheckboxSelected {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.dropdownItemCheckbox {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.dropdownItemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.noAvailableOptions {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.dropdownPopupSearchInputContainer input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
}
.dropdownPopupItemsContainer {
  max-height: 75px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.dropdownPopupItemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.dropdownPopupItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.dropdownPopupFooterContainer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}
.dropdownPopupConfirm {
  font-family: 'Open Sans SemiBold';
  font-size: 10px;
  color: #8500f0;
  cursor: pointer;
}
