.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
.blockStructure {
  display: inline-flex;
  margin-top: 30px;
  width: calc(100% - 80px);
}
.content {
  max-height: 315px;
  overflow-y: auto;
  padding: 20px 30px 20px 95px;
}
.number {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}
.iconContainer {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.iconContainer .icon {
  font-size: 16px;
}
.blockDetails {
  width: 100%;
}
.blockDetails .blockText {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  width: calc(100% - 80px);
}
.otherResultsContainer {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #8b8b8d;
}
.otherResultsHeader {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.otherResultsTitle {
  text-transform: uppercase;
}
.otherResultsList {
  max-height: 200px;
  overflow-y: scroll;
}
.otherResultsList.otherResultsListExpanded {
  max-height: none;
}
.otherResult {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.otherResultColor {
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
}
.otherResultString {
  margin-left: 50px;
}
.toggleButtonWrapper {
  display: flex;
}
.toggleButton {
  cursor: pointer;
  user-select: none;
  line-height: 35px;
  height: 35px;
  padding: 0px 30px;
  border-radius: 20px;
  font-size: 14px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);
  color: #505050;
  background-color: #edeef1;
  box-sizing: border-box;
}
.toggleButton.statisticalRelevance {
  margin-right: 40px;
}
.toggleButtonActive {
  color: #ffffff;
  background-color: #5200f1;
}
.toggleButtonDisabled {
  cursor: auto;
  color: #8b8b8d;
}
.resultsContainer {
  height: 45px;
  line-height: 45px;
  display: flex;
  padding-left: 95px;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
}
.resultsContainerLeft {
  display: flex;
}
.resultsContainerRight {
  display: flex;
}
.resultViewTypeItem {
  font-family: 'Roboto Bold';
  margin-right: 20px;
  color: #000000;
  cursor: pointer;
}
.warning {
  color: #fc035d;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-family: Open Sans SemiBold;
}
.warning img {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.sortAnswersTableContainer {
  margin: 40px 80px 40px 40px;
}
.leftTitle {
  width: 30%;
  background-color: #f1eff1;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bold;
}
.topTitle {
  background-color: blue;
  flex-grow: 1;
  flex-basis: 0;
  background-color: #f1eff1;
  padding: 10px;
  font-weight: bold;
  margin-right: 2px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.leftTitle.topTitle {
  flex-grow: initial !important;
  flex-basis: initial !important;
  background-color: #f1eff1;
}
.tableRow {
  display: flex;
  border-bottom: 2px solid #d9d8da;
}
.tableValue {
  background-color: #ffffff;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  margin-right: 2px;
  overflow: hidden !important;
}
.percentage {
  font-size: 12px;
}
