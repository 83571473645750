.sectionDetailsTitle {
  font-size: 13px;
  color: #6d6d6d;
}
.sectionDetails {
  font-size: 13px;
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.sectionItemTitle {
  color: #8c8c8c;
}
.sectionItemValues {
  margin-bottom: 15px;
}
.sectionItemValues:last-child {
  margin-bottom: 0;
}
