.actionButtons {
  text-align: right;
  font-size: 13px;
}
.actionButton {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 4px 15px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  user-select: none;
}
.actionButton:hover * {
  opacity: 0.7;
}
.actionButton.disabledButton {
  cursor: not-allowed;
}
.actionButton.disabledButton *,
.actionButton.disabledButton:hover * {
  filter: grayscale(1);
  opacity: 0.5;
}
.actionButton .addIcon {
  height: 8px;
  width: 8px;
}
.actionButton .buttonText {
  display: inline-block;
  padding-left: 8px;
}
