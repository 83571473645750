.addAnswerContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.addAnswerContainer.disabled {
  opacity: 0.3;
}
.addAnswerButton {
  padding: 5px 9px;
  border: 1px solid #ececec;
  border-radius: 7px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}
.addAnswerIcon {
  width: 11px;
  height: 10px;
  margin-right: 10px;
}
.addOpenAnswerIcon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.questionMark {
  background-color: #a97ff8;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  position: static;
}
