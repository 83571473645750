.right-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.right-container .welcome-container {
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-container .welcome-container .welcome-container-content {
  width: 450px;
  max-height: 700px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message {
  font-family: 'Roboto Medium';
  font-size: 60px;
  margin-bottom: 30px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message-description {
  margin-bottom: 20px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  a {
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  img {
  width: 200px;
  height: 60px;
}
.right-container .form-container {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-container .form-container .form-container-title {
  font-family: 'Roboto Bold';
  font-size: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.right-container .form-container .buffl-logo {
  position: relative;
  width: 200px;
  left: 50%;
  margin-bottom: 40px;
  margin-left: -100px;
  display: block;
}
.right-container .form-container form {
  position: relative;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.right-container .form-container .form-text {
  margin: 30px 0;
  font-size: 14px;
}
.right-container .form-container input,
.right-container .form-container select {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  display: block;
  width: 500px;
  height: 90px;
  line-height: 90px;
  color: #221f1f;
  font-size: 18px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: content-box;
}
.right-container .form-container input:focus {
  box-shadow: 0 0 5px #5200f1;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  outline: none;
}

.right-container .form-container .error-message {
  background: #f5a0a1;
  color: #b03223;
  border: 1px solid #b03223;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}
.right-container .form-container .success-message {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}

.right-container .form-container .button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  min-width: 80px;
}
.right-container .form-container .button-loading {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
}
.right-container .form-container .button-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.right-container .form-container .button-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.button-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.button-loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes button-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.right-container .form-container .link a:link,
.right-container .form-container .link a:visited {
  text-align: center;
  color: #5200f1;
  text-decoration: none;
}
.right-container .form-container .link a:hover {
  text-decoration: underline;
}
.right-container .form-container .link.forgot-password {
  margin-top: 10px;
  font-family: 'Open Sans SemiBold';
}

.user-app-button-loading {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
}
.user-app-button-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: user-app-button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.user-app-button-loading.user-app-button-loading-grey div {
  border-color: #7b7b7b transparent transparent transparent;
}
.user-app-button-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.user-app-button-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.user-app-button-loading div:nth-child(3) {
  animation-delay: -0.15s;
}

.right-container.registration-page {
  height: auto;
}

.right-container .form-container.registration-page {
  height: auto;
  display: block;
}

.right-container .form-container.registration-page .buffl-logo {
  margin-top: 30px;
}

.right-container .welcome-container.registration-page {
  height: auto;
}

.client-registration-howDidYouLearnAboutBuffl-input {
  height: 100px !important;
}

@keyframes user-app-button-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .right-container .form-container input,
  .right-container .form-container select {
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 768px) {
  .right-container .welcome-container {
    display: none;
  }
  .right-container .form-container {
    width: 100%;
  }
  .right-container .form-container form {
    width: 100%;
  }
  .right-container .form-container input,
  .right-container .form-container select {
    width: 60vw;
  }
}
