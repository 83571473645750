.emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
  z-index: 25;
}
.emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.emoji-picker .emoji:hover {
  opacity: 0.6;
}
