.closePopupButton {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.popupWrapper > div > div {
  margin-left: -20px;
}
