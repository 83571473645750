.container {
  width: 795px;
  height: 500px;
  margin-right: 5px;
}
.textContainer {
  overflow-y: auto;
  height: 480px;
  margin: 30px;
  margin-left: 10px;
  margin-right: 0;
  padding-right: 20px;
}

/* Works on Firefox */
.textContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.textContainer::-webkit-scrollbar {
  width: 7px;
}

.textContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.textContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.text {
  margin-bottom: 15px;
  margin-left: 20px;
}
.text .purple {
  color: #4a25e8;
}
.scalePickersContainer {
  display: flex;
  padding-left: 20px;
  margin-bottom: 25px;
}
.scalePicker {
  margin-right: 20px;
  cursor: pointer;
}
.scalePicker.selected {
  color: #4a25e8;
}
.scalePicker img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.scaleDetailsWrapper {
  background-color: #f7f4fd;
  border-left: 3px solid #4a25e8;
  padding: 25px 17px 1px 17px;
  margin-bottom: 20px;
}
.scaleContainer {
}
.scaleContainer .rangeContainer {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
}
.scaleContainer .mcContainer {
  margin-top: 40px;
  margin-bottom: 40px;
}
.scaleTitle1 {
  font-family: 'Open Sans SemiBold';
}
.scaleTitle2 {
}
.pointContainer {
  padding: 10px 0px 1px 0px;
  background-color: #fafafa;
  margin-bottom: 10px;
}
.disclaimer {
  margin-left: 20px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-right: 40px;
}
.footerContainer {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
