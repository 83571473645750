.container {
  color: #a8a8a8;
  padding: 30px;
  font-size: 13px;
}
.container .row {
  display: flex;
  justify-content: space-between;
  line-height: 35px;
}
.container .row .value {
  font-family: 'Open Sans Light';
  white-space: pre-line;
  text-align: right;
}

.confirmContainer {
  display: flex;
  margin-top: 30px;
  color: #000;
}
.confirmContainer .checkboxContainer {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.confirmContainer.confirmContainerDisabled .checkboxContainer {
  opacity: 0.2;
  cursor: not-allowed;
}
.confirmContainer.confirmContainerDisabled .confirmLabel {
  opacity: 0.2;
}
.confirmContainer .checkboxContainer img {
  position: relative;
  top: 1px;
}
