.myCampaignsAction {
  font-family: 'Open Sans Regular';
  border-left: 1px solid #3b3838;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
  min-width: 130px;
}
.actionLabel {
  margin: 0 16px;
}
