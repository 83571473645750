.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 85px;
  padding-right: 120px;
}
.border {
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 85px;
  padding-right: 120px;

  border-bottom: 1px solid #efefef;
}
