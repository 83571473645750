.container {
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.insightContainer {
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  padding: 25px;
  position: relative;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.insightContainer:last-child {
  border-bottom: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.draggingInsightContainer {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}
.showActionDropdownButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: right;
  height: 50px;
  position: absolute;
  top: -12px;
  right: 10px;
}
.actionDropdownContainer {
  display: none;
  position: relative;
  top: 2px;
  right: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  z-index: 999;
  color: #9d9d9d;
}
.actionDropdownContainerOption {
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
}
.showActionDropdownButton:hover .actionDropdownContainer {
  display: block;
}
.name {
  font-family: 'Open Sans SemiBold';
  cursor: pointer;
}
.description {
  font-family: 'Open Sans Regular';
  cursor: pointer;
  display: inline-block;
}
.baseQuestionContainer {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin-bottom: 15px;
  margin-top: 10px;
}
.baseQuestionContainer img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.baseQuestionContainerNumber {
  padding-right: 2px;
}
.questionContainerPreview {
  display: inline-block;
  padding: 3px 5px 3px 0px;
  background-color: #f9f9f9;
  margin-right: 6px;
}
.basedOnDataText {
  top: 2px;
  font-size: 13px;
  margin-bottom: 10px;
}
.questionWrapContainerPreview {
  margin-bottom: 10px;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.actiosButtonContainer {
  display: inline-block;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}
.actiosButtonContainer .actionButton .rightArrowIcon {
  position: relative;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.actiosButtonContainer .actionButton .leftArrowIcon {
  position: relative;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
