.container {
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.container.containerWithWarning {
  padding-bottom: 20px;
}
.titleContainer {
  display: flex;
}
.icon {
  margin-left: 56px;
  width: 35px;
  color: #8500f0;
  margin-top: 4px;
}
.icon img {
  width: 16px;
  height: 16px;
}
.graphName {
  margin-left: 6px;
  font-size: 18px;
  color: #221f1f;
}
.sampleErrorMessage {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff2f7;
  color: #fc035d;
  font-family: Open Sans SemiBold;
  font-size: 13px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.sampleErrorMessage .warningIcon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  position: relative;
}
.close {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
