.addToParentIconContainer {
  position: absolute;
  bottom: -14px;
  right: -14px;
  width: 27px;
  height: 27px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.addToParentIconGroupedContainer {
  position: absolute;
  bottom: -14px;
  right: -14px;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border-radius: 50%;
  cursor: pointer;
  background-color: #5200f1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.addToParentIconGroupedContainer.disabled {
  background-color: #ffffff;
}
.addToParentIconContainer:hover,
.addToParentIconGroupedContainer:hover {
  z-index: 25;
}
.addToParentIconContainer svg {
  position: absolute;
  width: 13px;
  height: 14px;
  top: 6px;
  left: 7px;
}
.addToParentIconGroupedContainer svg {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 5.5px;
  left: 6px;
}
.groupIcon {
  position: absolute;
  width: 27px;
  height: 27px;
}
.groupIcon.disabled {
  opacity: 0.2;
}
.groupIconTooltipContainer {
  position: absolute;
  top: -4px;
  left: 20px;
}
