.arrow {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.arrowPointingUp {
  transform: rotate(-180deg);
}
