.placeholder {
  border-bottom: 1px solid #efebeb;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}
.placeholder:first-child {
  border-top: 1px solid #efebeb;
}
