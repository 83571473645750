.container {
  position: absolute;
  top: 0px;
  left: -35px;
  width: 35px;
  height: 100%;
  display: flex;
}
.topContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
}
.bottomContainer {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100% - 20px);
}
.leftLine {
  border-left: 1px dashed #8500f0;
}
.topLine {
  border-top: 1px dashed #8500f0;
}
.bottomLine {
  border-bottom: 1px dashed #8500f0;
}
