.filtersBarContainer {
  z-index: 99;
  justify-content: left;
  padding-top: 10px;
  padding-left: 100px;
  border-bottom: 1px solid #efefef;
  min-height: 40px;
}
.filtersBarContainerNoCategories {
  color: #8b8b8d;
  font-size: 12px;
  position: relative;
  display: inline-block;
  padding-top: 6px;
}
.singleActiveFilter {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
  margin-right: 4px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.singleActiveFilter:hover,
.singleActiveFilter:focus,
.singleActiveFilter:active,
.singleActiveFilter::selection {
  background-color: rgba(83, 0, 242, 0.05);
}
.singleActiveFilterLabel {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  box-sizing: border-box;
}
.singleActiveFilterLabelText {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
}
.singleActiveFilterLabelCount {
  color: #707070;
}
.removeSingleActiveFilter {
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 9px;
  height: 30px !important;
  line-height: 30px !important;
  padding-right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.singleActiveFilter:hover .removeSingleActiveFilter {
  visibility: visible;
  opacity: 1;
}
.filtersBarDropdownButton {
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  position: absolute;
  left: 60px;
  top: 16px;
  padding: 2px 3px 0px 3px;
  border-radius: 5px;
  cursor: pointer;
}
.addCategoryRowContainer {
  flex: 2;
  border-bottom: 1px solid #efefef;
  padding-left: 100px;
  padding-right: 10px;
}
.addCategoryRow {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  align-items: flex-start;
}
.addCategoryRow .selectAllContainer {
  display: inline-flex;
  align-items: center;
  height: 21px;
}
.visibleBox {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.visibleBox.active {
  background-color: #5300f2;
}
.visibleBox .visibleTick {
  width: 10px;
}
.addCategoryRow .singleActiveFilter {
  margin: 0px;
  border-radius: 5px;
  margin: 5px;
}
.addIcon {
  width: 10;
  height: 10px;
}
.addCategoryRow .selectAllText {
  padding-left: 10px;
}

.searchCategoryContainer {
  display: flex;
  padding: 20px 10px 0px 100px;
  font-size: 13px;
  align-items: flex-start;
  flex-wrap: wrap;
}
.searchAnswerInputContainer {
  display: inline-block;
  margin-right: 4px;
}
.searchAnswerInputContainer .searchAnswerInput {
  min-height: 30px;
  width: 230px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.searchAnswerInputContainer .closeIcon {
  position: absolute;
  right: 5px;
  line-height: 32px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.categoryInput {
  padding: 5px 0px 5px 0px;
  outline: none;
  border: none;
  color: #8b8b8d;
  font-size: inherit;
  width: inherit;
  background-color: inherit;
  font-family: inherit;
}
.doubleClickHint {
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  position: absolute;
  padding: 0px 5px;
  top: 39px;
  left: 0px;
  z-index: -1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  visibility: hidden;
}
.singleActiveFilter:hover .doubleClickHint {
  z-index: 1;
  visibility: visible;
}

.actionButton {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  user-select: none;
}
.actionButton * {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.actionButton:hover * {
  opacity: 0.7;
}
.actionButton.disabledButton {
  cursor: not-allowed;
}
.actionButton.disabledButton *,
.actionButton.disabledButton:hover * {
  filter: grayscale(1);
  opacity: 0.5;
}
.actionButton .buttonText {
  display: inline-block;
  padding-left: 8px;
}
.actionButton .flagIcon {
  width: 14px;
  height: 14px;
}
.actionButton .trashIcon {
  height: 12px;
}
.categoryOverflowInfo {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 10px;
}
