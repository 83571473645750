.container {
  display: flex;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}
