.loader-fixed-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-fixed-container .loading-animation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
  .loader-fixed-container .loading-animation div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
  }
  .loader-fixed-container .loading-animation div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader-fixed-container .loading-animation div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader-fixed-container .loading-animation div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
