.rowContainer {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  max-width: 300px;
}
.firstColumn {
  padding-left: 20px;
  box-sizing: border-box;
}
.actionsContainer {
  padding-right: 10px;
  text-align: right;
}
.actionsContainer .removeIcon {
  position: relative;
  height: 25px;
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
  vertical-align: middle;
  opacity: 0.7;
}
