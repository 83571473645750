.surveyInformationContainer {
  display: flex;
  padding-left: 32px;
  padding-right: 38px;
  justify-content: space-between;
  margin-bottom: 40px;
}
.surveyInformationContainer .surveyInformation {
  width: 49.3%;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}
.surveyInformationContainer .surveyInformation .description {
  text-align: center;
  color: #95989a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.titleContainer {
  border-bottom: 1px solid #e4e4e4;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titleContainer .title {
  color: #95989a;
}
.titleContainer .errorMessage {
  font-family: 'Open Sans Bold';
  color: #fc035d;
}
.errorsContainer {
  padding: 30px 0;
}
.errorsContainer .errorContainer {
  display: flex;
  padding-bottom: 10px;
  border-right: 1px solid #e4e4e4;
}
.errorsContainer .errorContainer .title {
  width: 35%;
  border-right: 1px solid #d6d6d6;
  text-align: right;
  color: #888888;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 13px;
}
.errorsContainer .errorContainer .errors {
  width: 65%;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 12px;
}
.errorsContainer .errorContainer .errors .message {
  margin-bottom: 5px;
}
.errorsContainer .errorContainer .errors .message span {
  padding: 3px 8px;
  border: 1px solid #fc035d;
  color: #fc035d;
  text-transform: uppercase;
  border-radius: 5px;
  display: inline-block;
}

.disabledPublishContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #dcd3d3;
  padding-top: 40px;
  padding-bottom: 40px;
}
.disabledPublishContainer .description {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 30px;
  opacity: 0.5;
}
.disabledPublishContainer .buttonContainer {
  opacity: 0.2;
}
.disabledPublishContainer .buttonContainer .button {
  cursor: no-drop;
}

.testingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #dcd3d3;
  padding-top: 40px;
  padding-bottom: 40px;
}
.testingContainer .description {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 30px;
  text-align: center;
  opacity: 0.5;
}
.buttonContainer .button {
  width: 170px;
  font-size: 14px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  padding: 5px 30px 5px 30px;
}

.publishButton {
  line-height: 45px;
  color: #ffffff;
  background-color: #5200f1;
  margin-bottom: 20px;
}
.publishButtonDisabled {
  line-height: 45px;
  color: #ffffff;
  background-color: #a488f3;
  margin-bottom: 20px;
  cursor: no-drop !important;
}
.scheduleButton {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
}
.scheduleButtonAdditionalText {
  color: #5200f1;
  font-size: 10px;
}
.licenceDropdownContainer {
  width: 230px;
  margin-bottom: 20px;
}
