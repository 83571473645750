.explanation {
  margin: 15px 0 10px 0;
  font-size: 12px;
}
.colorPickersContainer {
  display: flex;
  flex-wrap: wrap;
}
.colorPickersContainer .colorPicker {
  margin-bottom: 5px;
}
