.titleContainer {
  border-bottom: 1px solid #e6e6e6;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  padding-top: 30px;
  color: #9c9696;
  font-size: 12px;
}
.timelineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
}
.arrowContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowControllerContainer {
  position: absolute;
}
.arrowController {
  width: 18px;
  cursor: pointer;
  position: absolute;
  top: -53px;
}
.scheduleListContainer {
  display: flex;
  flex: 10;
  overflow: hidden;
  scroll-behavior: smooth;
}
.centeredScheduleListContainer {
  justify-content: center;
  align-items: flex-start;
}
.gradient {
  position: absolute;
  top: 0;
  height: 250px;
  width: 160px;
  pointer-events: none;
  z-index: 3;
}
.leftGradient {
  left: 70px;
  background: linear-gradient(90deg, #ffffff, #fff0);
}
.rightGradient {
  right: 70px;
  background: linear-gradient(90deg, #fff0, #ffffff);
}
