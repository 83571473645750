.container {
}
.row {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}
.title {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  padding-top: 15px;
  margin-bottom: 15px;
}
