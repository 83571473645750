ul.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 20px;
}
ul.pagination li {
  list-style: none;
  color: #8b8b8d;
  height: 21px;
  width: 21px;
  line-height: 21px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}
ul.pagination li a {
  display: inline-block;
  cursor: pointer;
  height: 21px;
  width: 21px;
  border-radius: 50%;
}
ul.pagination li.active {
}
ul.pagination li.active a {
  background-color: #5200f1;
  color: #f6f7fb;
}
ul.pagination li.previous,
ul.pagination li.next {
  color: #5200f1;
  font-size: 16px;
  font-weight: 1000;
}

.header-icon {
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
  color: #b5b5b8;
}
.header-icon.active {
  color: #000000;
}
.header-icon.search {
  margin-left: 30px;
}
.campaigns-table-main-container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.campaigns-table-main-container .campaigns-table-header {
  display: flex;
  justify-content: space-between;
  height: 55px;
  line-height: 55px;
  margin-bottom: 10px;
}
.campaigns-table-main-container .campaigns-table-header .left-header-content {
  display: inline-flex;
}
.campaigns-table-main-container .campaigns-table-header .right-header-content {
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.campaigns-table-main-container
  .campaigns-table-header
  .left-header-content
  .header1 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.campaigns-table-main-container
  .campaigns-table-header
  .left-header-content
  .header2 {
  font-size: 21px;
  color: #8b8b8d;
  margin-right: 50px;
}
.campaigns-table-nocampaigns {
  text-align: center;
  margin-top: 30px;
  font-size: 13px;
  color: #8b8b8d;
}

.campaigns-table-results-per-page-container {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #8f8e8e;
}
.campaigns-table-results-per-page-container
  .campaigns-table-results-per-page-container-single-option {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.campaigns-table-results-per-page-container
  .campaigns-table-results-per-page-container-single-option.active-results-option {
  color: #5200f1;
}
.campaigns-table-publish-error-container {
  width: 300px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ffffff;
  font-size: 14px;
}
.campaigns-table-publish-error-container div {
  display: flex;
  justify-content: center;
}
.campaigns-table-publish-error-container img {
  display: inline-block;
  margin-bottom: 20px;
  height: 30px;
  width: 30px;
}
.campaign-list-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1230px) {
  .campaigns-table-main-container {
    width: auto;
  }
  .campaign-list-name {
    width: calc(100vw - 880px);
  }
  .campaign-list-target {
    width: 70px;
  }
  .campaign-list-created-at {
    width: 100px;
  }
}
