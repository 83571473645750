.registrationWrapper {
  width: 100%;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
  font-family: 'Roboto Light';
}

.contentContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1190px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 200px;
  box-sizing: border-box;
}
.left {
  width: 43%;
}
.right {
  width: 57%;
}

@media only screen and (max-width: 992px) {
  .contentContainer {
    display: block;
    text-align: center;
    padding-bottom: 160px;
  }
  .left,
  .right {
    width: 100%;
  }
}
