.container {
  border: 1px solid #efefef;
  border-radius: 10px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  -webkit-box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  margin-bottom: 10px;
}
.titleContainer {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.titleContainer .titleTextContainer {
  display: flex;
  align-items: center;
}
.titleContainer .colorBullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}
.titleContainer .populationLabel {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  color: #707070;
}
.titleContainer .filteredResultsCount {
  text-transform: lowercase;
}
.content {
  border-top: 1px solid #e6e6e6;
}
.content .title {
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.content .title .details .name {
  color: #5200f1;
}
.content .title .arrow {
  width: 8px;
  transform: rotate(270deg);
}
.content .title .arrow.expanded {
  transform: rotate(0deg);
}
.expandedContent .filterRow {
  font-size: 11px;
  display: flex;
  height: 35px;
  align-items: center;
  text-transform: uppercase;
  margin-left: 20px;
}
.expandedContent .filterRow .name {
  margin-left: 10px;
  margin-right: 15px;
}
.expandedContent .filterRow .value {
  color: #5200f1;
}
.expandedContent .footer {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  margin-left: 20px;
}
.smallPopulation {
  color: #fc035d;
  background-color: #fff2f7;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 20px 0 10px 0;
  top: -20px;
  z-index: -1;
}
.smallPopulation img {
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  position: relative;
  margin-top: 5px;
}
.populationActionsDropdown {
  position: absolute;
  z-index: 100;
  top: 30px;
  left: -182px;
  box-sizing: border-box;
  width: 200px;
  font-size: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  margin-bottom: 50px;
}
.dropdownAction {
  height: 40px;
  text-transform: none;
  font-family: Open Sans SemiBold;
  color: #5200f1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdownAction:hover .dropdownActionText {
  opacity: 0.7;
}
.inverseAction:hover .inverseActionText {
  opacity: 1;
}
.inverseAction:hover .inverseActionText .inverseActionTextHover,
.inverseAction:hover .dropdownActionIcon {
  opacity: 0.7;
}
.dropdownActionIconWrapper {
  width: 20px;
  text-align: center;
}
.dropdownActionIcon {
  width: 12px;
  color: #5200f1;
  font-size: 12px;
}
.dropdownActionText {
  margin-left: 15px;
}
.dropdownActionTextRed {
  color: #fc035d;
}
.inverseAction .dropdownActionIcon {
  width: 20px;
  min-width: 20px;
}
.dots,
.dotsActive {
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  user-select: none;
  line-height: 0px;
}
.dots {
  color: #bfbfbf;
}
.dotsActive {
  color: #5200f1;
}
.inverseExplanationIcon {
  width: 12px;
  position: absolute;
  bottom: 7px;
  margin-left: 5px;
}
.inverseExplanationIcon:hover + .inverseExplanation {
  visibility: visible;
  opacity: 1;
  background-color: #ede2fb;
  color: #000000e6;
  top: 40px;
}
.inverseExplanation {
  position: absolute;
  width: 260px;
  right: 0;
  top: 60px;
  background-color: #fff;
  border-radius: 5px;
  visibility: hidden;
  z-index: 99;
  padding: 10px 15px;
  color: #fff;
  font-style: italic;
  transition: all 0.2s ease-out;
  opacity: 0;
  display: flex;
  align-items: flex-start;
}
.inverseExplanationText {
  margin-left: 10px;
}
