.emptyStatePlaceholder {
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptySurveyPlaceholderCover {
  height: 50%;
  max-height: 500px;
  min-height: 290px;
  margin-bottom: 50px;
}
.emptyStateDescription {
  color: #95989a;
  text-align: center;
}
.loadingPlaceholdersContainer div {
  background-color: #f2f2f2;
}
.loadingPlaceholderFirst {
  height: 200px;
  margin-bottom: 30px;
}
.loadingPlaceholderFirst {
  width: 640px;
  height: 90px;
  margin-bottom: 1px;
}
.loadingPlaceholderSecond {
  width: 640px;
  height: 250px;
}
.loadingPlaceholderSide {
  position: absolute;
  width: 305px;
  height: 400px;
  top: 0px;
  right: 10px;
}
