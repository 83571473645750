.userDetailTitle {
  font-family: 'Roboto Bold';
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 15px;
}
.error {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  /*background-color: #fc035d0d;*/
  padding-left: 5px;
  border-radius: 2px;
  display: inline-block;
}
.dropdown {
  background-repeat: no-repeat;
  background-position: right 15px top 13px;
  background-size: 12px;
  appearance: none;
}
