.container {
  position: fixed;
  top: 170px;
  left: 50%;
  margin-left: 545px;
  z-index: 9;
  width: calc(50vw - 560px);
}
.container.expanded {
  width: 240px;
  height: calc(100vh - 66px);
  top: 66px;
  background-color: #ffffff;
  position: fixed;
  right: 15px;
  left: auto;
  margin-left: 0;
  padding-left: 30px;
  padding-top: 105px;
  padding-bottom: 5px;
  -webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}
.draggableQuestionsContainer {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 175px);
}
.draggableQuestionPlaceholder {
  width: 200px;
  height: 50px;
  margin-bottom: 7px;
}
.question {
  width: 200px;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 0 15px;
  font-family: 'Open Sans Regular';
  font-size: 11px;
  text-transform: uppercase;
  color: #8b8b8d;
  cursor: pointer;
}
.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 15px;
}
.expandButton {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -15px;
  left: -44px;
  cursor: pointer;
  z-index: 9;
}
.expandButton .icon {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 5px;
  margin-right: 0;
}
.expandButton .label {
  font-family: 'Open Sans SemiBold';
  color: #909090;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 12px;
  padding: 5px;
  background-color: #ffffff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.container.expanded .expandButton {
  top: 80px;
  left: -15px;
}
.container.expanded .expandButton .icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sectionTitle {
  width: 230px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #909090;
  height: 20px;
  top: 5px;
}
.sectionTitle span {
  display: inline-block;
  padding: 10px;
  background-color: #ffffff;
  font-family: 'Open Sans Regular';
  color: #909090;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  top: -20px;
}
.sectionTitle.sectionTitleMargin {
  margin-top: 25px;
}
.distributorIcon {
  width: 13px;
}

.expandButtonResolutionCheck {
  display: none;
}

@media only screen and (max-width: 1450px) {
  .expandButtonResolutionCheck {
    display: flex;
  }
}

@media only screen and (max-width: 1070px) {
  .container {
    left: auto;
    margin-left: 0;
    right: 0;
  }
  .expandButton {
    left: -30px;
  }
}

@media only screen and (max-height: 770px) {
  .draggableQuestionsContainer {
    height: calc(100vh - 205px);
  }
}
