.combineSurveysContainer {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  font-size: 12px;
  width: 600px;
}
.combineSurveysExplanation {
  font-size: 12px;
  margin-bottom: 10px;
  font-style: italic;
  color: #919191;
}
.surveysListElement {
  list-style-type: decimal;
  font-size: 12px;
  margin-bottom: 4px;
}
.combineButton,
.combineButtonDisabled {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: inline-block;
}
.combineButton {
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
.combineButtonDisabled {
  background-color: #bfbfbf;
  color: #ffffff;
}
.combineButton:hover {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
}
.combineResult {
  font-size: 12px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.combineResultError,
.combineResultSuccess {
  font-size: 12px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.combineResultError {
  color: #fc035d;
}
.combineResultSuccess {
  color: #23cc95;
}
.combineLoading {
  font-size: 12px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: flex;
  vertical-align: top;
}
.campaignList {
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  margin-block-start: 1em;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #efefef;
  border-radius: 5px 0 0 5px;
  border-right: none;
  padding: 5px;
}
.campaignList.disabled {
  opacity: 0.6;
}
.campaignListItem {
  display: flex;
}
.campaignListItemName {
  margin-left: 5px;
}
.searchInput {
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  font-size: 12px;
  outline: none;
}
.combineSurveysTitle {
  font-family: 'Roboto Medium';
  font-size: 18px;
  margin-bottom: 20px;
}
.selectSurveys {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.noCampaigns {
  color: #919191;
  margin: 15px 0;
}
.selectedSurveys {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.loadMore {
  color: #5200f1;
  cursor: pointer;
  margin: 3px 3px 3px 4px;
  user-select: none;
}
