.popupContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 500px;
}
.mainTitle {
  font-family: 'Open Sans Bold', Arial;
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.mainText {
  padding: 0 20px 25px 20px;
}
.text {
  display: inline-block;
  padding-left: 8px;
}
.categoryActions {
  display: flex;
  margin-bottom: 10px;
}
.searchAnswerInputContainer {
  flex-grow: 1;
}
.searchAnswerInputContainer .searchAnswerInput {
  min-height: 30px;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
}
.searchAnswerInputContainer .closeIcon {
  position: absolute;
  right: 0;
  line-height: 32px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.actionButton {
  min-height: 30px;
  margin-left: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
}
.actionButton:hover * {
  opacity: 0.7;
}
.actionButton.disabledButton:hover * {
  opacity: 0.5;
}
.disabledButton {
  cursor: default;
}
.disabledButton * {
  filter: grayscale(1);
  opacity: 0.5;
}
.actionButton .addIcon {
  height: 10px;
  width: 10px;
}
.disabled {
  opacity: 0.5;
}
.categoryList {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
.categoryList::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.categoryList::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.categoryList .categoryListItem {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.categoryList .visibleBox {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
  margin-right: 10px;
}
.categoryList .visibleBox.active {
  background-color: #5300f2;
}
.categoryList .visibleBox.disabled {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.categoryList .visibleBox .visibleTick {
  width: 10px;
}
.categoryList .categoryListItemText {
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.confirmIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.confirmIconDisabled {
  filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.closeIcon {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
