.container {
  width: 650px;
  font-size: 13px;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 1000px;
}
.container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.titleContainer {
  display: flex;
}
.titleContainer .titleItem {
  font-family: Open Sans SemiBold;
  color: #8b8b8d;
  cursor: pointer;
  padding: 15px 20px 15px 15px;
  user-select: none;
  border-bottom: 1px solid #e6e6e6;
}
.titleContainer .titleItem.activeTitle {
  font-family: Open Sans SemiBold;
  color: #404040;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 0;
}
.titleItemPlaceholder {
  flex: 1;
  border-bottom: 1px solid #e6e6e6;
}
.title {
  padding: 10px 15px 15px 0px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.templateTitle {
  padding: 10px 15px 15px 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.description {
  padding: 10px 15px 15px 15px;
  font-style: italic;
}
.templateDescription {
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
}
.questionsContainer {
  padding: 25px 15px 5px 15px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
}
.question {
  display: flex;
  margin-bottom: 15px;
}
.question.questionNotMergeable {
  opacity: 0.4;
}
.question .selectorContainer {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.question .selectorContainer .selectorIcon {
  cursor: pointer;
}
.question .number {
  width: 5%;
  text-align: center;
}
.question .iconContainer {
  width: 5%;
  text-align: center;
}
.question .iconContainer img {
  height: 12px;
}
.question .questionTextContainer {
  width: 85%;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.footer .details {
  color: #5200f1;
}
.footer .footerErrorBackground {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.footer .error {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.footer .error .warningIcon {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.selectAllContainer {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 15px 15px 15px;
  display: flex;
  justify-content: space-between;
}
.selectAllContainer .selectorContainer {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.selectAllContainer .selectorContainer span {
  cursor: pointer;
}
.selectAllContainer .selectedBlocks {
  font-family: Open Sans SemiBold;
  color: #5200f1;
}
.deletePopulation {
  color: #fc035d;
  cursor: pointer;
}
.deletePopulation .deletePopulationIcon {
  height: 12px;
  margin-right: 7px;
}
.graphNameContainer {
  padding: 0 15px;
  margin-bottom: 20px;
}
.graphNameContainer .mergedGraphName {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  width: 50%;
}
.loaderContainer {
  top: 10px;
}
.shift {
  margin-left: 33.5px;
}
.templateContainer {
  min-height: 400px;
}
.templateContainer .templateTabWrapper {
  padding: 0 15px 5px 15px;
}
.templateContainer .templateTabWrapper strong {
  font-family: Open Sans SemiBold;
}
.templateContainer .templateTabWrapper .templateTab {
  background-color: rgb(82 34 242 / 7%);
  border-radius: 6px;
  font-style: italic;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 12px;
}
.templateContainer .templateTabWrapper .hrLine {
  height: 1px;
  width: 100%;
  border-top: 1px solid #eae9e9;
  margin-bottom: 10px;
}
.templateTab:hover {
  opacity: 0.7;
}
.templateContainer .templateTab .bold {
  font-family: Open Sans SemiBold;
}
.bottomLine {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.horizontalLine {
  height: 1px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.customGraphSubtitles {
  padding-top: 5px;
  padding-bottom: 10px;
}
.inputContainer {
  padding: 0px 15px 15px 15px;
  font-style: italic;
}
.inputContainer input {
  width: 45%;
  position: relative;
  background-color: #ffffff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
}
.inputContainer input:first-child {
  margin-right: 14px;
}
