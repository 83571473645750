.container {
  position: fixed;
  width: 590px;
  margin-left: 285px;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: 20;
}
.dropdownItem {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.dropdownItem img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.dropdownItemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.noAvailableOptions {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.dropdownPopupItemsContainer {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 100px;
}
.dropdownItemNumber {
  padding-right: 2px;
}
