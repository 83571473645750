.container {
  overflow-x: auto;
  overflow-y: auto;
}
.mainContainer {
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
