.container {
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}
.container.notActiveQuestion {
  opacity: 0.4;
}
.question {
  display: flex;
}
.question.expanded {
  border-bottom: 1px solid #d8d8d8;
}
.checkboxContainer {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkboxContainer input {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.questionText {
  flex: 1;
  color: #000000;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.expandIconContainer {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expandIconContainer .arrowDownPurple {
  padding: 5px;
  cursor: pointer;
}
.expandIconContainer .arrowDownPurple img {
  width: 10px;
  height: 10px;
}
.answersContainer {
  padding: 13px;
}
.answer {
  font-style: italic;
  font-size: 13px;
  color: #333333;
  min-height: 27px;
  line-height: 27px;
}
.answer input {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
.bottomInfo {
  background-color: #f6f2fe;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.ljArrow {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
  margin-right: 10px;
}
.whyFilteredOutExplanation {
  width: calc(100% - 20px);
  border: 0;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
  border: 1px solid #e7e7e7;
  outline: none;
}
