.container {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  z-index: 20;
  margin-top: 2px;
}
.dropdownItem {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.noAvailableOptions {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.dropdownPopupItemsContainer {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 150px;
}
.circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
}
