.quotaInput {
  width: 40px;
  margin: 0 8px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 23px;
  line-height: 23px;
  padding-left: 10px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 5px;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow-x: hidden;
}
.quotaInput.disabled {
  background-color: #ededed;
  cursor: inherit;
}
