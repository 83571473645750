.container {
  text-align: center;
  width: 100%;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  margin-bottom: 20px;
}
.text {
  font-family: 'Roboto Regular';
  color: #6b7589;
}
.textMobile {
  display: none;
}
.qrCode {
  width: 130px;
  margin: 20px auto 0 auto;
  padding-bottom: 30px;
}
.mobileAppsContainer {
  margin: 20px 0;
  z-index: 2;
  display: none;
}
.mobileAppsContainer a {
  display: inline-block;
  margin-bottom: 4px;
}
.mobileAppsContainer img {
  height: 50px;
  border-radius: 6px;
  position: relative;
}
.mobileAppsContainer img:hover {
  z-index: 99;
}

@media only screen and (max-width: 992px) {
  .title {
    margin-top: 80px;
  }
  .textDesktop {
    display: none;
  }
  .textMobile {
    display: block;
  }
  .qrCode {
    display: none;
  }
  .mobileAppsContainer {
    display: block;
  }
}
