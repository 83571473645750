.notificationsListContainter {
  margin-bottom: 15px;
  width: 300px;
  margin-right: 20px;
}
.notificationsListContainter .title {
  font-family: 'Roboto Medium';
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid #d5d5d5;
  background-color: #fbfbfb;
}
.notificationsListContainter .notificationsContainer {
  max-height: calc(100vh - 320px);
  overflow: scroll;
}
.notificationsListContainter .notificationsContainer .notificationItem {
  padding: 20px;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
  cursor: pointer;
}
.notificationsListContainter
  .notificationsContainer
  .notificationItem.notificationItemSelected {
  background-color: #f6f7fb;
}

.notificationsListContainter
  .notificationsContainer
  .notificationItem.notificationItemPlaceholder {
  height: 40px;
  background-color: #f6f7fb;
}

.notificationsListContainter .notificationsContainer .notificationItem:hover {
  opacity: 0.8;
}
.notificationsListContainter .notificationsContainer .notificationItem .label {
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
}
.notificationsListContainter
  .notificationsContainer
  .notificationItem
  .label
  .name {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.notificationsListContainter
  .notificationsContainer
  .notificationItem
  .label
  .date {
  width: 90px;
  text-align: right;
  font-size: 12px;
  color: #73859f;
  white-space: nowrap;
  margin-left: 10px;
}
.notificationsListContainter
  .notificationsContainer
  .notificationItem
  .filterDetails {
  display: flex;
  justify-content: space-between;
}
.notificationsListContainter
  .notificationsContainer
  .notificationItem
  .filterDetails
  .filterType {
  display: inline-block;
  padding: 2px 4px 2px 4px;
  font-size: 9px;
  color: #ffffff;
  background-color: #5200f1;
  border-radius: 4px;
}
.notificationsListContainter .notificationsContainer .notificationItem .count {
  width: 120px;
  text-align: right;
  font-size: 12px;
  color: #73859f;
}
.whiteContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.pageContentListPlaceholder {
  height: calc(100vh - 300px);
  background-color: #ebeaef;
}
.loadingPlaceholder {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: grey;
  font-size: 12px;
}

.listTypeContainer {
  color: #b5b5b8;
  display: flex;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  padding-left: 20px;
}
.listTypeContainer div {
  cursor: pointer;
  margin-right: 15px;
}
.listTypeContainer div.active {
  color: #000000;
}
