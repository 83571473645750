.container {
  font-family: 'Open Sans Regular';
  font-size: 12px;
}
.header {
  padding: 20px;
  border-bottom: 1px solid #d3d3d3;
}
.header .title {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  margin-bottom: 5px;
}
.header .description {
  margin-bottom: 15px;
}
.header .searchContainer {
}
.header .searchContainer .searchInput {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
  height: 40px;
}

.listContainer {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 345px);
}
.listContainer .option {
  padding: 20px;
  border-bottom: 1px solid #d3d3d3;
  cursor: pointer;
}
.listContainer .option:hover {
  opacity: 0.7;
}
