.container {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.header {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.appBarPath {
  cursor: pointer;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 10px;
}
.subtitle span.description {
  margin-left: 2px;
  color: #807e7e;
  font-size: 13px;
}
.input,
.datePickerContainer input {
  font-family: 'Open Sans Regular';
  width: 257px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.input::placeholder {
  color: #cbcbcb;
}
.input:focus {
  outline: none;
}
.dropdownContainer {
  margin-bottom: 20px;
}
.dropdownLicenceContainer {
  width: 300px;
  margin-right: 10px;
}
.dropdownLicenceContainer:last-child {
  margin-right: 0;
}
.buttonContainer {
  width: 150px;
  margin-top: 30px;
  text-align: center;
}
.linkSuccessfullyGeneratedContainer {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #d4d4d4;
  font-size: 12px;
}
.linkGeneratedTitle {
  margin-bottom: 10px;
}
.generatedLink {
  font-size: 13px;
  text-decoration: underline;
}
.buttonsContainer {
  display: flex;
}
.buttonsContainer .buttonContainer {
  margin-right: 15px;
}
.mainLoadingContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.communitiesDropdown,
.communitiesDropdownSelected {
  width: 298px;
}
.communitiesDropdown > div:first-of-type {
  font-family: 'Open Sans Regular';
  border-radius: 5px;
  border: 1px solid #686767;
  background-color: #ffffff;
  height: 35px;
  line-height: 32px;
  padding-left: 20px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  color: #555555;
  opacity: 0.4;
}
.communitiesDropdownSelected > div:first-of-type {
  font-family: 'Open Sans Regular';
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  height: 35px;
  line-height: 32px;
  padding-left: 20px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  color: #000000;
}
