.container {
  margin-bottom: 20px;
}
.container.transparent {
  opacity: 0.5;
}
.dropdownContainers {
  display: flex;
  margin-top: 20px;
  padding: 0 20px;
}
.searchDropdownTitle,
.campaignLinkTitle {
  font-size: 12px;
  margin-bottom: 10px;
}
.campaignLinkContainer {
  padding: 0 20px;
}
.linkInput {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  outline: none;
}
.filtersButtonContainer {
  display: flex;
}
.filtersButtonContainer .filtersButton {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.filtersButtonContainer .filtersButton img {
  width: 9px;
  margin-right: 5px;
}
.filtersButtonContainer .filtersButton.filtersButtonActive {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.filtersButtonContainer .minimumQuotaContainer {
  display: inline-flex;
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  margin-left: 13px;
  cursor: pointer;
  user-select: none;
}
.filtersButtonContainer .minimumQuotaContainer .minQuotaLabel {
  display: inline-block;
}
.filtersButtonContainer .minimumQuotaContainer .checkbox.checkboxSelected {
  color: #5200f1;
}
.filtersButtonContainer .minimumQuotaContainer .checkbox {
  color: #b5b5b8;
  height: 21px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.filtersContainer {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
  min-height: 36px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 30px;
  margin: 20px 20px 0 20px;
}
.footer.innerFilters {
  padding-top: 15px;
  border-top: 1px solid #e2e2e2;
}
.addFilterButton img {
  width: 15px;
  height: 15px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
.filterControlsContainer {
  display: flex;
}
.whiteButton {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  color: #5200f1;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.purpleButton {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}
.buttonDisabled {
  border: 0;
  background-color: #cecece;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}
.selectTimeframeContainer {
  font-size: 16px;
  font-family: 'Open Sans Light';
  margin-bottom: 20px;
}
.selectTimeframeContainer .dateRange {
  font-size: 18px;
}
.selectTimeframeContainer .editIcon {
  margin-right: 10px;
  color: #5200f1;
  font-size: 14px;
  cursor: pointer;
}
.editIconHidden {
  opacity: 0.2;
}
