.container {
  width: 1012px;
  margin-bottom: 100px;
  margin-left: 375px;
}
.container.disabled {
  position: fixed;
}

@media only screen and (max-width: 1395px) {
  .container {
    width: calc(100% - 390px);
  }
}

@media only screen and (max-width: 768px) {
  .container.disabled {
    position: relative;
    width: calc(100vw - 20px);
    margin-left: 10px;
  }
}
