.container {
  position: relative;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  color: #000;
}
.container .selectedValue {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 12px;
  box-sizing: border-box;
}
.container .selectedValue.disabled {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.container .selectedValue .placeholder {
  user-select: none;
  color: #bebebe;
}
.container .popupContainer {
  font-size: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
  box-sizing: border-box;
}
.container .popupContainer .searchInput {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.container .popupContainer .searchInput input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
  color: #000;
  border-radius: 5px;
}
.container .popupContainer .searchInput input::placeholder {
  color: #bebebe;
}
.container .inputLoaderContainer {
  position: relative;
  top: -10px;
}
.container .popupContainer .searchInput .addItemIcon {
  position: relative;
  top: 0px;
  width: 11px;
  right: 10px;
  cursor: pointer;
}
.container .popupContainer .popupItemsContainer {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.popupItemsContainer .popupItem {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding-left: 10px;
}
.popupItemsContainer .popupItem .itemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.popupItemsContainer .popupItem .checkboxSelected,
.popupItemsContainer .popupItem .checkbox {
  width: 13px;
  height: 21px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.popupItemsContainer .popupItem .checkboxSelected {
  color: #5200f1;
}
.popupItemsContainer .popupItem .checkbox {
  color: #b5b5b8;
}
.popupItemsContainer .noItems {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #8b8b8d;
}
.popupItemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.popupItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.popupItemsContainer .loaderContainer {
  position: relative;
  top: -5px;
  left: 10px;
  display: inline-block;
}
