.input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin-left: 8px;
  margin-right: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}
