.container {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.noResultsPlaceholder {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.mainTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.headerRow {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.firstColumn {
  padding-left: 20px;
  box-sizing: border-box;
}
.errorsContainer {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.error {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}
