.placeholderContainer {
  width: 100%;
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 11px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
}
