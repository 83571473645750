.step-progressline-PAST {
  border-top: 2px solid rgb(181, 157, 247);
}
.step-progressline-NOW {
  border-top: 2px solid rgb(112, 65, 241);
}
.step-progressline-FUTURE {
  border-top: 2px dashed rgb(188, 190, 191);
}
.step-progressline-POTENTIAL {
  border-top: 2px dashed rgb(188, 190, 191);
}

.step-inner-circle-PAST {
  border: 9px solid rgb(205, 190, 250);
  background-color: rgb(157, 126, 244);
}
.step-inner-circle-NOW {
  border: 9px solid rgba(157, 126, 244);
  background-color: rgb(83, 0, 242);
}
.step-inner-circle-FUTURE {
  border: 9px solid rgb(201, 202, 203);
  background-color: rgb(149, 151, 153);
}
.step-inner-circle-POTENTIAL {
  border: 9px solid rgb(201, 202, 203);
  background-color: rgb(149, 151, 153);
}

.step-header-text-PAST {
  color: rgb(157, 126, 244);
  display: flex;
  align-items: center;
}
.step-header-text-NOW {
  color: #4926e9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115%;
}
.step-header-text-FUTURE {
  color: rgb(149, 151, 153);
  display: flex;
  align-items: center;
}
.step-header-text-POTENTIAL {
  color: rgb(149, 151, 153);
  display: flex;
  align-items: center;
}

.step-footer-text-PAST {
  width: max-content;
}
.step-footer-text-NOW {
  width: max-content;
}
.step-footer-text-FUTURE {
  width: max-content;
}
.step-footer-text-POTENTIAL {
  width: 100%;
}
