.container {
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}
.mainContainer {
  min-height: calc(100vh - 150px); /* Appbar is 150px in height */
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
  }
  .mainContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    min-height: calc(100vh - 70px); /* Appbar is 70px in height */
  }
}
