.description {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  border: 0;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
