.dropdownContainers {
  display: flex;
  margin-top: 30px;
}
.searchInputContainer {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  flex-grow: 1;
  position: absolute;
  right: 0px;
  top: -70px;
}
.searchInputContainer .questionContaining {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  height: 25px;
  line-height: 25px;
  min-width: 120px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
  position: relative;
  text-transform: uppercase;
}
.searchInputContainer .questionContainingInput {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 13px;
  width: 190px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.resetFilters {
  margin-top: 10px;
  margin-left: 5px;
}
.resetFilters span {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 13px;
  cursor: pointer;
}
