.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleContainer {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.titleContainer .title {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.propertiesContentContainer {
  border-top: 1px solid #eeeeee;
}
.singleProperty {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.singleProperty.askForEmailAddressInWebSurveyProperty {
  border-bottom: none;
}
.singleProperty .propertyTitle {
  margin-right: 15px;
}
.singleProperty:last-child {
  border-bottom: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.switch :global .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.languageSelector {
  display: inline-block;
  color: #000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 12px;
  margin-right: 5px;
  cursor: pointer;
}
.languageSelectorActive {
  color: #fff;
  background-color: #5200f1;
}
.colorCodesContainer {
  display: flex;
  margin: 0 20px 0 20px;
  padding: 10px 0 10px 0;
  flex: 1;
  border-bottom: 1px solid #eeeeee;
}
.customImageUploadContainer {
  padding: 10px 20px 0 20px;
}
.customImageUploadContainer .imageContainer {
  display: inline-block;
  margin-bottom: 20px;
}
.customImageUploadContainer .imageContainer .removeImage {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.customImageUploadContainer .imageContainer .removeImage img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.customImageUploadContainer .imageContainer .selectedImage {
  max-height: 120px;
  max-width: 240px;
  padding: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.dropContainer {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.dropContainer .dropButton {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.dropContainer .dropDescription {
  text-align: center;
}

.content {
  padding: 10px 20px;
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
}
.linkContainer {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eeeeee;
  border-radius: 7px;
}
.linkContainer .link {
  display: flex;
  flex-direction: row;
  height: 30px;
  line-height: 30px;
  width: 90%;
  padding-left: 10px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
}
.linkContainer .url {
  border: 0;
  flex: 1;
  padding-right: 20px;
  outline: none;
}
.linkContainer .copyLinkButton {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
  text-align: center;
}
.linkContainer .copyLinkButton.copied {
  background-color: #95989a;
}

.row {
  margin: 10px 0;
}
.row.topBorder {
  border-top: 1px solid #e7e7e7;
  margin-top: 15px;
  padding-top: 15px;
  position: relative;
}
.row.flexRow {
  display: flex;
}
.flexRow .flexColumn {
  width: 50%;
  margin-right: 5px;
}
.row .rowText {
  margin-bottom: 10px;
}
.thankYouScreenConversionMessageError {
  color: #ef6c6c;
}
