.sendPushContainer {
  padding: 20px;
}
.title {
  font-family: 'Roboto Medium';
  font-size: 18px;
  margin-bottom: 20px;
}
.title .templatesIcon {
  font-size: 14px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.inputContainer input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.footerContainer {
  margin-top: 20px;
  display: flex;
}
.sendPushButton {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  -webkit-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-right: 10px;
}
.schedulePushButton {
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  -webkit-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: 1px solid #eaeaea;
}
.sendPushButtonDisabled {
  background-color: #b5b5b8;
  cursor: not-allowed;
}
.sendPushButton:hover,
.schedulePushButton:hover {
  opacity: 0.7;
}
.filterDropdownStyle {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 150px;
  height: 15px;
}
.filterDropdownStyle .selectedOptionsLabel {
  width: 150px;
}
.inputTitle {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.dropdownFilterStyle {
}
.dropdownFilterStyle > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 815px;
}
.dropdownFilterStyle.selectedValue > div:first-child {
  color: #000000;
}
.dropdownFilterStyle input {
  width: 91%;
  margin-bottom: 0;
}
.dropdownWiderFilterStyle {
  margin-bottom: 25px;
}
.dropdownWiderFilterStyle > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 815px;
}
.dropdownWiderFilterStyle.selectedValue > div:first-child {
  color: #000000;
}
.dropdownWiderFilterStyle input {
  width: 91%;
  margin-bottom: 0;
}
.sendNotificationFilterContainer {
  margin-top: 20px;
}
.sectionDetailsContainer {
  margin-top: 10px;
}
.filterDropdownPopup {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 26px;
  width: 500px;
  border: 1px solid #dfe0e4;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
