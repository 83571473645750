.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
.blockTitle {
  display: flex;
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.blockIcon {
  margin-left: 56px;
  width: 35px;
  color: #8500f0;
  margin-top: 4px;
}
.blockIcon img {
  width: 16px;
  height: 16px;
}
.chartContainer {
  margin-bottom: 15px;
  margin-left: 95px;
  margin-right: 40px;
}
.opportunityChartContainer {
  margin-bottom: 15px;
  margin-left: 15x;
  margin-right: 15px;
}
.removeMergedGraph {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 12px;
  color: #707070;
}
.editMergedGraph {
  position: absolute;
  top: 13px;
  right: 65px;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
}
.blockContent {
  width: 100%;
}
.opportunityGraphContent {
  display: flex;
}
.blockText {
  margin-left: 6px;
  font-size: 18px;
  color: #221f1f;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.noData {
  width: 100%;
}
.graphInfoContainer {
  flex-grow: 1;
  min-width: 0;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
.disclaimer {
  font-size: 12px;
  color: #8b8b8d;
  padding: 0 30px 20px 95px;
}
