.container {
  display: flex;
  border-bottom: 1px solid #efefef;
  padding-left: 95px;
  height: 45px;
  line-height: 45px;
}
.tab {
  font-family: 'Open Sans Bold';
  margin-right: 25px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.15s ease-in-out;
}
.tab.active {
  color: #000;
}
