.container {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.content {
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.invalid {
  opacity: 0.12;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.input {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.input::placeholder {
  color: #cbcbcb;
}
.input:focus {
  outline: none;
}
.copyLinkButton {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.copyLinkButton .linkIcon {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.dropdownContainer {
  margin-bottom: 0px;
}
.invitationLinkContainer {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.invitationLinkContainer input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.invitationLinkContainer .copyInputLink {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.invitationLinkContainer .copiedInputLink {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.loaderContainer {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.dropdownLicenceTopContainer {
  display: flex;
}
.dropdownLicenceContainer {
  width: 245px;
  margin-right: 10px;
}
.dropdownLicenceContainer:last-child {
  margin-right: 0;
}
.additionalOptionCheck {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.additionalOptionCheck input {
  width: 13px;
  height: 13px;
  margin-right: 7px;
  cursor: pointer;
}
.numberInputRows {
  margin-top: 10px;
  margin-bottom: 10px;
}
.errorContainer {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.warningErrorIcon {
  width: 15px;
  margin-right: 10px;
}
.licenceSelectorRow {
  margin-bottom: 10px;
}
.licenceCheckboxContainer {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}
.licenceCheckboxContainer input {
  margin-right: 5px;
  margin-top: 1px;
  accent-color: #5200f1;
  outline: none;
  cursor: pointer;
}
.licenceDatesContainer {
  display: flex;
}
.licenceSingleDateInputContainer {
}
.licenceSingleDateInputContainer .dateInputWrapper:first-child {
  margin-right: 12px;
}
.licenceSingleDateInputContainer input {
  font-family: 'Open Sans Regular';
  width: 200px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
}
.licenceSingleDateInputContainer input:first-child {
  margin-right: 10px;
}
.licenceSingleDateInputContainer input::placeholder {
  color: #cbcbcb;
}
.licenceSingleDateInputContainer input:focus {
  outline: none;
}
.datePickerOuterContainer {
  height: 37px;
  position: static;
}
.datePickerOuterContainer .datePickerOuterAbsoluteContainerStartDate {
  position: absolute;
  top: -10px;
  left: 0px;
  z-index: 99999;
}
.datePickerOuterContainer .datePickerOuterAbsoluteContainerEndDate {
  position: absolute;
  top: -10px;
  left: 137px;
  z-index: 99999;
}

.datePickerOuterContainer .datePickerOuterFixedContainer {
  position: fixed;
}
.licenceSingleDateTitleStartDate {
  margin-bottom: 10px;
}
.licenceSingleDateTitleEndDate {
  margin-bottom: 10px;
  left: 137px;
}
