.container {
  width: 500px;
  font-size: 14px;
  color: #1b1b1b;
  box-shadow: -15px 10px 15px #fff;
}
.content {
  padding: 50px;
  padding-bottom: 20px;
}
.contentContainer {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.invalid {
  opacity: 0.12;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.title {
  font-family: 'Roboto Bold';
  color: #5300f2;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}
.description {
  margin-bottom: 10px;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.label {
  margin-bottom: 5px;
}
.input {
  font-family: 'Open Sans Regular';
  width: 100%;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.input::placeholder {
  color: #cbcbcb;
}
.input:focus {
  outline: none;
}
.dropdown {
  padding-left: 10px;
}
.loaderContainer {
  position: relative;
  top: -8px;
  left: 2px;
  display: inline-block;
  height: 25px;
}
.errorContainer {
  background-color: #fdf2f7;
  min-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}
.errorContainer .errorText {
  margin-left: 5px;
}
.warningErrorIcon {
  width: 15px;
  margin-right: 10px;
}
