.customGraphAxis line {
  color: #e3e3e3;
}
.customGraphAxis text {
  color: #000;
}
.opportunityGraphBottomAxis {
  text-transform: uppercase;
}
.label {
  cursor: pointer;
}
.labelInput {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.xAxisLabel {
  position: absolute;
  left: 0px;
  bottom: 4px;
  padding-left: 135px;
  padding-right: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.xAxisLabel.axisLabelSet,
.yAxisLabel.axisLabelSet {
  color: #000;
}
.xAxisLabelRating {
  bottom: 8px;
  padding-left: 60px;
  padding-right: 40px;
}
.yAxisLabel {
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
  padding: 0 100px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yAxisLabelRating {
  left: calc(-50% + 20px);
}
.yLabelContainer {
  transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}
