.container {
  padding-top: 40px;
  font-family: 'Roboto Light';
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 32px;
  color: #000;
  margin-top: 40px;
  margin-bottom: 30px;
}
.section {
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.07);
  padding: 50px;
  margin-bottom: 30px;
}
.section.sectionLoading {
  height: 300px;
  background-color: #f6f7fb;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .section {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}
