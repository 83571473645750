.container {
  font-family: 'Roboto Light';
}
.container .gridContainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 40px auto;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 13px;
}
.container .mobileContainer {
  display: none;
}
.gridContainer .row {
  display: flex;
}
.questionChoice {
  width: 50%;
  min-width: 50%;
  text-align: left;
  box-sizing: border-box;
  padding: 10px 30px 10px 10px;
  display: flex;
  align-items: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.questionChoice .imageContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  user-select: none;
}
.questionChoice .imageContainer .answerImage {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}
.imageContainer .zoomInIconContainer {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0);
  transition: box-shadow 0.1s ease-in-out;
}
.imageContainer:hover .zoomInIconContainer {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.imageContainer .zoomInIconContainer .zoomInIcon {
  position: relative;
  width: 10px;
  height: 10px;
}

.columnTitle {
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.columnTitle span {
  display: inline-block;
  vertical-align: middle;
  max-width: 70px;
  text-align: center;
}

.row .answerOption {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.row .answerOption .selectorContainer {
  width: 25px;
  height: 25px;
  background-color: var(--mc-question-answer-background-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
}
.row .answerOption .selectorContainer .selector {
  width: 17px;
  height: 17px;
  background-color: var(--mc-question-answer-background-color);
  border-radius: 100%;
  visibility: hidden;
}
.row .answerOption .selectorContainer:hover .selector {
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
  visibility: visible;
  transition: all 0.1s ease-in;
}
.row .answerOption .selector.selected {
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
  visibility: visible;
}

/* Border fixes */
.row:first-child .questionChoice {
  border: none;
}
.row:nth-child(2) .questionChoice {
  border-top-left-radius: 5px;
}
.row:last-child .questionChoice {
  border-bottom-width: 1px;
  border-bottom-left-radius: 5px;
}
.columnTitle:nth-child(2) {
  border-top-left-radius: 5px;
}
.columnTitle:last-child {
  border-top-right-radius: 5px;
  border-right-width: 1px;
}
.row:last-child .answerOption {
  border-bottom-width: 1px;
}
.row .answerOption:last-child {
  border-right-width: 1px;
}
.row:last-child .answerOption:last-child {
  border-bottom-right-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .container .gridContainer {
    display: none;
  }
  .container .mobileContainer {
    text-align: center;
    display: block;
  }

  .mobileQuestionChoice {
    margin-bottom: 40px;
  }

  .mobileQuestionChoice .imageContainer {
    background-color: #fafafa;
  }
  .mobileQuestionChoice .imageContainer .answerImage {
    max-width: 100%;
    max-height: 240px;
    display: block;
    margin: 0 auto;
  }
  .mobileQuestionChoice .imageContainer .zoomInIconContainer {
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
  }
  .mobileQuestionChoice .imageContainer:hover .zoomInIconContainer {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  }
  .mobileQuestionChoice .imageContainer .zoomInIconContainer .zoomInIcon {
    width: 24px;
    height: 24px;
  }

  .mobileQuestionChoice .answerText {
    margin: 20px 0;
    padding: 15px 30px;
  }
  .mobileQuestionChoice .answerOptionsContainer {
    margin: 20px 0;
    padding: 15px 30px;
  }
  .mobileQuestionChoice .answerOptionsContainer .answerRange {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    margin-bottom: 35px;
  }
  .mobileQuestionChoice .answerOptionsContainer .answerRange span:first-child {
    text-align: left;
    width: 120px;
  }
  .mobileQuestionChoice .answerOptionsContainer .answerRange .line {
    height: 40px;
    border-right: 1px solid black;
  }
  .mobileQuestionChoice .answerOptionsContainer .answerRange span:last-child {
    text-align: right;
    width: 120px;
  }
  .mobileQuestionChoice .answerOptionsContainer .answerOptions {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 0 auto;
  }
  .mobileQuestionChoice .answerOption .selectorContainer {
    width: 40px;
    height: 40px;
    background-color: var(--mc-question-answer-background-color, #e9f7fc);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
  }
  .mobileQuestionChoice .answerOption .selectorContainer .selector {
    width: 28px;
    height: 28px;
    background-color: var(--mc-question-answer-background-color, #e9f7fc);
    border-radius: 100%;
    visibility: hidden;
  }
  .mobileQuestionChoice .answerOption .selectorContainer:hover .selector {
    background-color: var(
      --mc-question-image-answer-background-color-active,
      #24b2e8
    );
    transition: all 0.1s ease-in;
    visibility: visible;
  }
  .mobileQuestionChoice .answerOption .selector.selected {
    background-color: var(
      --mc-question-image-answer-background-color-active,
      #24b2e8
    );
    visibility: visible;
  }
}
