.container {
  border-top: 1px solid #f3f3f3;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.selectorsContainer {
  display: flex;
}
.answer {
  margin-bottom: 10px;
}
