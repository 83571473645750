.contactContainer {
  margin-top: 50px;
  padding-bottom: 100px;
  text-align: center;
}
.button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #792483;
  border-radius: 20px;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  font-family: 'Montserrat Bold';
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
