.container {
  margin: 10px 4px 10px 0;
}
.addProfilingQuestion {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #5300f2;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
