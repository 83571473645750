.tooltipContainer {
  display: inline-block;
}
.tooltipIcon {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.tooltipPopup {
  position: fixed;
  padding: 10px 20px;
  width: 260px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 13px;
}
.tooltipIcon:hover + .tooltipPopup {
  display: block;
}
