.appBarContainer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 66px;
  top: 0px;
  left: 0px;
  right: 0px;
  line-height: 70px;
  background-color: #221f1f;
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}
.appBarAbsolute {
  position: absolute;
}
.appBarBuilder .appBarLeft {
  max-width: calc(100% - 730px);
}
.appBarRight {
  display: inline-flex;
  color: #ffffff;
}
.appBarRight .appBarButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.appBarRight .appBarButtonsContainer .button {
  margin-right: 20px;
  height: 40px;
  line-height: 37px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  white-space: nowrap;
}
.appBarRight .authContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}
.appBarRight .authContainer img {
  margin-left: 2px;
  margin-right: 2px;
}
.appBarRight .authContainer .dropdownContent {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  cursor: default;
  position: absolute;
  right: 0px;
  top: 55px;
  color: #000000;
}
.appBarRight .authContainer:hover .dropdownContent {
  display: block;
  font-size: 16px;
}
.appBarRight .authContainer .dropdownContent .clientEmail {
  height: 35px;
  line-height: 35px;
  font-family: 'Open Sans SemiBold';
  margin-bottom: 20px;
}
.appBarRight .authContainer .dropdownContent .clientLogout {
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  white-space: nowrap;
}
.bufflLogoWhiteSmall {
  height: 18px;
  cursor: pointer;
}
.bufflLogoWhiteShort {
  width: 18px;
  height: 18px;
}

.bufflVersion {
  color: #fff;
  font-size: 0.8em;
}
.clientNameContainer {
  padding-right: 25px;
  border-right: 1px solid #3b3838;
}
.clientName {
}
.logoutAction {
  padding-left: 25px;
  font-family: 'Open Sans SemiBold';
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
}
.appBarShortLogoContainer {
  margin-right: 25px;
  height: 66px;
  line-height: 66px;
  color: #ffffff;
  font-family: 'Open Sans SemiBold';
  display: flex;
}
.appBarShortLogoContainer .logoPart {
  display: inline-block;
  min-width: 70px;
}
.appBarShortLogoContainer .logoPart.backend {
  min-width: 130px;
}
.appBarShortLogoContainer img {
  margin-right: 8px;
}
.appBarShortLogoContainer .myCampaignsAction {
  font-family: 'Open Sans Regular';
  border-left: 1px solid #3b3838;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
  min-width: 130px;
}
.appBarShortLogoContainer .routeSubtitleName {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.appBarCenter {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
}
.appBarCenter .pathOption {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8f8e8e;
  margin-left: 35px;
  cursor: pointer;
}
.appBarCenter .pathOption:first-child {
  margin-left: 0px;
}
.appBarCenter .pathOption.active {
  color: #ffffff;
  border-bottom: 3px solid #ffffff;
  cursor: default;
}
.appBarCenter .pathOption .count {
  background-color: #937ff0;
  color: #ffffff;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 990px) {
  .appBarShortLogoContainer .myCampaignsAction {
    display: none;
  }
  .appBarShortLogoContainer .routeSubtitleName {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .bufflVersion {
    display: none;
  }
}

@media print {
  .appBarContainer {
    position: absolute;
  }
}
