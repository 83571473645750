.container {
  display: flex;
  flex-direction: column;
  z-index: 0;
}
.schedulerContainer {
  display: flex;
  width: 45vh;
  height: 18vh;
}
.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 20px);
  left: -10px;
  border-top: 1px solid #e6e6e6;
  z-index: -1;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 40px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  border: 0;
  margin: 15px 15px 5px 15px;
  cursor: pointer;
}
.buttonDisabled {
  background: #cccccc !important;
  pointer-events: none;
}
.leftButton {
  color: #fc025d;
}
.rightButton {
  color: #ffffff;
  border-radius: 5px;
  background-color: #4926e9;
}
