.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 66px;
}
.headerContainer {
  height: 80px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.leftHeader {
  display: flex;
}

.leftHeader .leftHeaderArrow {
  position: relative;
  top: 5px;
  display: block;
  height: 13px;
  margin-right: 10px;
  cursor: pointer;
}

.rightHeader {
}
.rightHeader .rightHeaderButton {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.rightHeader .rightHeaderButton.disabled {
  background-color: #d0d0d0;
}
.filtersContainer {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #d3d3d3;
}
.filtersContainer :global .community-dashboard-filters-container {
  margin-bottom: 0;
}
