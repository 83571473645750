.wordCloudContainer {
  font-size: 12px;
}

.actionsBar {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.actionsBarLeft {
  display: inline-flex;
  align-items: center;
  width: 28%;
  box-sizing: border-box;
  padding: 15px 20px 15px 30px;
}
.actionsBarRight {
  display: inline-flex;
  width: 72%;
  box-sizing: border-box;
  padding: 15px 0 15px 15px;
  border-left: 1px solid #efefef;
}
.searchAnswerInputContainer {
  width: 100%;
}
.searchAnswerInputContainer .searchAnswerInput {
  height: 30px;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.searchAnswerInputContainer .searchAnswerInput::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.searchAnswerInputContainer .closeIcon {
  position: absolute;
  right: 5px;
  line-height: 30px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.hideAll {
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #efefef;
}
.hideAll .visibleBox {
  margin-left: 0;
}
.hideAll .visibleBox .hideAllLine {
  position: absolute;
  top: 4px;
  left: 1px;
  width: 8px;
  height: 1px;
  background-color: #fff;
}
.actionButton {
  min-height: 30px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
}
.actionButton:hover * {
  opacity: 0.7;
}
.actionButton.disabledButton:hover * {
  opacity: 0.5;
}
.disabledButton {
  cursor: default;
}
.disabledButton * {
  filter: grayscale(1);
  opacity: 0.5;
}
.mergeIcon {
  width: 13px;
  height: 13px;
  transform: rotate(90deg);
}
.listMergeIcon {
  width: 15px;
  height: 15px;
  margin-left: 12px;
  cursor: pointer;
}
.hideIcon {
  width: 12px;
  height: 12px;
}
.actionButton .addIcon {
  height: 10px;
  width: 10px;
}

.sidebar {
  display: inline-block;
  width: 28%;
}
.inActive {
  color: #8b8b8d;
  user-select: none;
}
.active {
  color: #000000;
  user-select: none;
}
.filterArrowDown {
  height: 14px;
  vertical-align: middle;
  margin-left: 2px;
}
.filterArrowUp {
  height: 14px;
  vertical-align: middle;
  margin-left: 2px;
  transform: rotate(180deg);
}
.sideBarList {
  box-sizing: border-box;
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
  transform: translate3d(0, 0, 0);
}
.sideBarList .oddWord {
  background-color: rgba(83, 0, 242, 0.03);
}
.sideBarList .listItem {
  padding: 15px 0;
  display: flex;
  height: 50px;
  box-sizing: border-box;
  background-color: rgba(83, 0, 242, 0.01);
  align-items: center;
}
.text {
  display: inline-block;
  padding-left: 8px;
}
.wordListText {
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.visibleBox {
  margin-left: 30px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.visibleBox.active {
  background-color: #5300f2;
}
.visibleTick {
  width: 10px;
}

.mainContent {
  display: inline-block;
  width: 72%;
}
.cloudVersionsContainer {
  height: 60px;
  line-height: 60px;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
}
.cloudVersionsContainer .cloudVersionsTabs {
  display: inline-block;
  margin-left: 15px;
}
.cloudVersionsContainer .cloudVersionsTabs .cloudTabNameInput {
  color: #000;
  font-family: 'Roboto Bold';
  font-size: 1rem;
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
}
.cloudVersionsContainer .cloudVersionsTabs .cloudTabContainer {
  display: inline-block;
}
.cloudVersionsContainer .cloudVersionsTabs .cloudTab {
  font-family: 'Roboto Bold';
  margin: 0 15px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline;
  float: left;
  z-index: 1;
}
.cloudVersionsContainer .cloudVersionsTabs .cloudTab.cloudTabActive {
  color: #000;
}
.cloudVersionsContainer .cloudVersionsTabs .nameOverflowInfo {
  position: absolute;
  top: 10px;
  left: 145px;
  line-height: 13px;
  width: 115px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 2;
}
.cloudVersionsContainer .cloudVersionsActions {
  display: inline-block;
  margin-right: 15px;
}
.cloudVersionsContainer .cloudVersionsActions .actionIconContainer {
  display: inline-block;
  position: relative;
}
.cloudVersionsContainer .cloudVersionsActions .actionIconContainer .addIcon {
  height: 11px;
}
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .addDisabledInfo,
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .trashDisabledInfo {
  position: absolute;
  top: 10px;
  right: 15px;
  line-height: 13px;
  width: 135px;
  padding: 5px 10px;
  border-radius: 4px;
  display: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
}
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .addIcon:hover
  + .addDisabledInfo,
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .trashIcon:hover
  + .trashDisabledInfo {
  display: inline-block !important;
}
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .addDisabledInfo:hover,
.cloudVersionsContainer
  .cloudVersionsActions
  .actionIconContainer
  .trashDisabledInfo:hover {
  display: inline-block;
}
.cloudVersionsContainer .cloudVersionsActions .actionIconContainer .trashIcon {
  height: 12px;
  margin-left: 12px;
}
.cloudVersionsContainer
  .cloudVersionsActions
  .addIcon.versionActionActive:hover,
.cloudVersionsContainer
  .cloudVersionsActions
  .trashIcon.versionActionActive:hover {
  opacity: 0.7;
  cursor: pointer;
}
.cloudVersionsContainer .cloudVersionsActions .addIcon.versionActionDisabled,
.cloudVersionsContainer .cloudVersionsActions .trashIcon.versionActionDisabled {
  filter: grayscale(1) opacity(0.4);
}

.cloudContainer {
  border-left: 1px solid #efefef;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.noWords {
  color: #95989a;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 14px;
}
.dropDownContainer {
  min-height: 30px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 95% 12px;
  background-size: 10px 5px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 4px;
}
.filterDropDown {
  min-width: 125px;
  width: 150px;
}
.propertyDropDown {
  min-width: 110px;
  width: 110px;
}
.dropDownArrow {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: transform 0.3s ease;
}
.dropDownArrowActive {
  transform: rotate(180deg) scaleX(-1);
}
.dropDown {
  width: 100%;
  position: absolute;
  top: 35px;
  left: -1px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.dropDownOption {
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
}
.dropDownOption:hover {
  background-color: #edeef1;
}
.wordOverflowInfo {
  position: fixed;
  line-height: 13px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
}
