.parentContainer {
  display: flex;
  min-height: calc(100vh - 150px); /* Appbar is 150px in height */
  flex-direction: column;
  font-family: 'Roboto Light';
}
.mainContainer {
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.campaignName {
  font-family: 'Montserrat Bold';
  font-size: 20px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 10px;
}
.campaignQuestion {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #221f1f;
  padding-top: 0px;
  padding-bottom: 40px;
  white-space: pre-line;
  width: 800px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}
.campaignDescription {
  padding-top: 0px;
  padding-bottom: 20px;
  white-space: pre-line;
}
.campaignImage {
  background-color: grey;
  height: 400px;
}
.questionImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.questionImage img {
  max-width: 100%;
  max-height: 400px;
}

.progressBarContainer {
  height: 10px;
  border-radius: 10px;
  background-color: #dadada;
  margin-bottom: 30px;
}
.progressBarContainer .progressBar {
  height: 10px;
  border-radius: 10px;
  background-color: #687df0;
}

.questionContainer,
.inputFieldContainer {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.sliderContainer {
  text-align: center;
}
.questionContainer .answer {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  background-color: var(--mc-question-answer-background-color, #e9f7fc);
  border-radius: 27px;
  padding: 17px 22px;
  cursor: pointer;
  display: inline-flex;
  white-space: pre-line;
  width: 480px;
  box-sizing: border-box;
  transition: background-color 0.1s ease-in-out;
  text-align: left;
}
.mcQuestionContainer .answer {
  margin-bottom: 0;
}
.questionContainer.sliderContainer .answer.sliderAnswer {
  display: inline-block;
  width: auto;
  padding: 17px 34px;
}
.questionContainer .imageAnswer {
  width: 280px;
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  padding: 25px 27px;
  background-color: var(--mc-question-answer-background-color, #e9f7fc);
  transition: background-color 0.1s ease-in-out;
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  break-inside: avoid;
  user-select: none;
}
.imageAnswerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionContainer .imageAnswerText {
  font-family: 'Roboto Light';
  font-size: 14px;
  padding-top: 25px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.questionContainer .imageAnswer img {
  max-width: 100%;
  max-height: 300px;
}
.questionContainer .answer.active {
  background-color: var(--mc-question-answer-background-color-active, #a7e0f5);
}
.questionContainer .imageAnswer.active {
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
}
.questionContainer .imageAnswer.active:hover {
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
}
.questionContainer .imageAnswer .zoomInIconContainer,
.questionImage .zoomInIconContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: none;
}
.questionContainer .imageAnswer .zoomInIconContainer .zoomInIcon,
.questionImage .zoomInIconContainer .zoomInIcon {
  position: relative;
  display: block;
  left: 14px;
  top: 14px;
}
.questionContainer .imageAnswer .zoomInIconContainer.visible,
.questionImage .zoomInIconContainer.visible {
  display: block;
}
.questionContainer .answer .questionCounter {
  left: -4px;
  top: 0;
}
.questionContainer .imageQuestionCounter {
  left: -8px;
  top: -3px;
}
.questionContainer .answer .questionCounter,
.questionContainer .imageQuestionCounter {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-family: 'Roboto Light';
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
}
.questionContainer .answer .questionText {
}
.questionContainer .inputField {
  display: block;
  font-size: 16px !important;
  height: 200px;
  font-family: 'Roboto Light';
  background: #fafafa;
  border-radius: 27px;
  padding: 17px 22px;
  color: #000000;
  border: none;
  outline: none;
  margin: 0 auto;
  box-sizing: border-box;
}
.questionContainer .inputField.openAnswerInputField {
  width: 700px;
}
.questionContainer .inputField.otherAnswerInputField {
  width: 480px;
}
.questionContainer .inputFieldDescription {
  width: 700px;
  margin: 10px auto 0 auto;
  font-size: 14px;
  text-align: right;
  font-family: 'Roboto Light';
}
.iframeContainer {
  width: 95%;
  min-height: 600px;
  height: auto;
  border: none;
  display: block;
  margin: 0 auto;
  border: 1px solid #eeeeee;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageContainer img {
  max-width: 100%;
  max-height: 400px;
}
.loadingQuestionImageFailed {
  width: 430px;
  max-width: 85%;
  height: 245px;
  font-family: 'Roboto Light';
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loadingQuestionImageFailed .imageNotFoundIcon {
  margin-bottom: 10px;
}

.sliderOrContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Roboto Light';
  font-size: 20px;
  margin-bottom: 30px;
}
.sliderOrContainerDivider {
  width: 125px;
  height: 1px;
  background-color: grey;
}
.sliderOrContainer span {
  margin: 0 8px;
}

.videoPlayerContainer {
  margin-top: 20px;
  padding-bottom: 40px;
  max-width: 85vw;
  max-height: 80vh;
}

.mcQuestionContainer {
  text-align: center;
}
.mcQuestionContainer .amountOfAnswerOptionsContainer {
  font-family: 'Roboto Light';
  margin-bottom: 10px;
  color: #808080;
}
.mcQuestionAnswers {
  column-count: 3;
  column-gap: 10px;
}
.mcQuestionContainer .mcAnswerContainer {
  column-span: all;
  display: block;
  margin: 10px 0;
}
.mcQuestionAnswers .inputField {
  column-span: all;
  display: block;
  margin-top: 5px;
}

@media only screen and (min-width: 900px) {
  /* Hover states only apply on desktop devices */
  .questionContainer .answer:hover {
    background-color: var(
      --mc-question-answer-background-color-active,
      #a7e0f5
    );
  }
  .questionContainer .imageAnswer:hover {
    background-color: var(
      --mc-question-image-answer-background-color-hover,
      #a7e0f5
    );
  }
}

@media only screen and (max-width: 900px) {
  .mainContainer {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .questionRootContainer {
    width: 90%;
  }
  .questionContainer {
    width: 90%;
  }
  .campaignQuestion {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .questionContainer .imageAnswer {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
  .questionContainer .inputField.openAnswerInputField,
  .questionContainer .inputField.otherAnswerInputField {
    width: 100%;
    box-sizing: border-box;
  }
  .questionContainer .inputFieldDescription {
    width: 100%;
    font-size: 10px;
  }
  .mcQuestionContainer .answer {
    width: 100%;
  }
  .mcQuestionContainer .inputFieldContainer {
    width: 100%;
  }
  .mcQuestionContainer .inputFieldContainer .inputField {
    width: 100%;
    box-sizing: border-box;
  }
  .mcQuestionAnswers {
    display: flex;
    flex-flow: column wrap;
    column-count: auto;
    column-gap: normal;
  }
}

@media only screen and (max-width: 768px) {
  .parentContainer {
    min-height: calc(100vh - 70px); /* Appbar is 70px in height */
  }
  .iframeContainer {
    width: 85%;
    min-height: calc(100vh - 260px);
  }
}
