.blockTitle {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.icon {
  width: 15px;
  height: 12px;
  margin-left: 10px;
  margin-right: 5px;
}
.blockContentWithTooltip {
  padding-right: 10px;
  padding-left: 20px;
}
