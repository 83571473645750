.sectionContainer {
  width: 500px;
  max-width: 100%;
  margin-bottom: 15px;
}
.sectionTitle {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.sectionDetails {
  font-size: 13px;
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.sectionDetails .item {
  color: #8c8c8c;
  margin-top: 15px;
}
.sectionDetails .item:first-child {
  margin-top: 0;
}
.explanation {
  margin-bottom: 20px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-line;
}
