.gridLines path {
  display: none;
}
.gridLines .gridLineTick line {
  stroke: #e9e9e9;
  stroke-width: 1px;
  stroke-opacity: 0.9;
}
.gridLines .gridLineTick:first-of-type {
  display: none;
}

.xAxis path,
.yAxis path {
  stroke: #939393;
}
.xAxisTick,
.yAxisTick {
  font-style: italic;
  font-family: 'Open Sans Regular';
}
.xAxisIRTick {
  font-style: italic;
  font-family: 'Open Sans SemiBold';
}
.xAxisTick line,
.yAxisTick line {
  display: none;
}
.yAxisLegend {
  font-style: italic;
  font-size: 11px;
}
