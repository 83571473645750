.title {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 20px;
}
.subTitle {
  font-family: 'Roboto Regular';
  color: #000;
  margin-bottom: 4px;
}
.linkInputContainer {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  width: 650px;
  height: 25px;
  top: -2px;
}
.linkInputContainer .linkInput {
  padding-left: 10px;
  height: 25px;
  line-height: 25px;
  width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.linkInputContainer .copyIconContainer {
  margin-left: auto;
  padding-left: 2px;
  background-color: #792483;
  height: 25px;
  width: 48px;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 28%;
  background-position: center center;
}
.linkInputContainer .copyIconContainer:active,
.linkInputContainer .copyIconContainer:focus {
  opacity: 0.7;
}

@media only screen and (max-width: 1024px) {
  .linkLabel {
    margin-bottom: 15px;
  }
  .linkInputContainer {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
}
