.gridLines line {
  stroke: #e5e5e5;
}
.gridLines path {
  display: none;
}

.xAxis line {
  display: none;
}

.yAxis path,
.yAxis line {
  display: none;
}

.mainLine {
  fill: none;
  stroke: #a57bfa;
  stroke-width: 1;
}
