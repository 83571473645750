.container {
  padding: 15px;
  width: 650px;
  font-size: 13px;
}
.title {
  font-family: Open Sans SemiBold;
  color: #5200f1;
}
.description {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: italic;
}
.questionsContainer {
  padding-top: 25px;
  padding-bottom: 5px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
}
.question {
  display: flex;
  margin-bottom: 15px;
}
.question .selectorContainer {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.question .selectorContainer .selectorIcon {
  cursor: pointer;
}
.question .number {
  width: 5%;
  text-align: center;
}
.question .iconContainer {
  width: 5%;
  text-align: center;
}
.question .iconContainer img {
  height: 12px;
}
.question .questionTextContainer {
  width: 85%;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.selectAllContainer {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}
.selectAllContainer .selectorContainer {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.selectAllContainer .selectorContainer span {
  cursor: pointer;
}
.deletePopulation {
  color: #fc035d;
  cursor: pointer;
}
.deletePopulation .deletePopulationIcon {
  height: 12px;
  margin-right: 7px;
}
