.container {
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 40px;
  margin-right: 40px;
}
.additionalDetailsContainer {
  padding: 20px 30px;
  font-size: 13px;
}
.averageRatingCount {
  font-family: 'Roboto Bold';
}
.switchContainer {
  text-align: right;
  margin-bottom: 10px;
  margin-left: 20px;
  display: inline-block;
}
.ratingSwitch {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.ratingSlider {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  user-select: none;
}
input:checked + .ratingSlider:before {
  transform: translateX(0px) translateY(-50%);
}
.ratingSlider:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  transform: translateX(30px) translateY(-50%);
}
.ratingPercentageOn,
.ratingPercentageOff,
.ratingNumberOn,
.ratingNumberOff {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.ratingPercentageOn,
.ratingPercentageOff {
  left: 15px;
}
.ratingNumberOn,
.ratingNumberOff {
  right: 17px;
}
.ratingPercentageOff,
.ratingNumberOff {
  color: #fff;
}
.ratingPercentageOn,
.ratingNumberOn {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.input {
  display: none;
}
.sliderSettingsContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.dropDownContainer {
  height: 30px;
  min-width: 125px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 95% 12px;
  background-size: 10px 5px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
}
.dropDownActive {
  border-radius: 3px 3px 0 0;
}
.dropDownArrow {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: transform 0.3s ease;
}
.dropDownArrowActive {
  transform: rotate(180deg) scaleX(-1);
}
.dropDown {
  width: 100%;
  position: absolute;
  top: 29px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 3px 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.dropDownOption {
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
}
.dropDownOption:hover {
  background-color: #edeef1;
}
.horizontal {
  min-width: 165px;
}
