.statistics {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  height: 50px;
  justify-content: center;
  color: #221f1f;
}
.statistics.disabled {
  opacity: 0.5;
}
.statisticsItem {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.count {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  font-size: 28px;
}
.statistics.disabled .count {
  color: #95989a;
}
.countPercentage {
  font-size: 14px;
  margin-left: 4px;
  font-family: Open Sans Bold;
}
.label {
  font-size: 12px;
  margin-left: 8px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .statistics.disabled {
    width: calc(100% - 20px);
  }
  .statistics {
    flex-wrap: wrap;
    height: auto;
  }
}
