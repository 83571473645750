.headerInputContainer {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  top: 8px;
  width: 40%;
  flex-grow: 1;
}
.headerInputContainer input {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 15px;
  width: 190px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.headerInputContainer .close {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #777777;
}
