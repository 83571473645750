.title {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 0;
}
.contentContainer {
}
.table {
  flex-basis: 100%;
}
.tableRow {
  display: flex;
  margin-top: 35px;
  line-height: 20px;
}
.tableRow .index {
  margin-right: 15px;
  color: #792483;
  font-family: 'Montserrat Bold';
  font-size: 20px;
  width: 20px;
}
.tableRow .name {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableRow .tokens {
  margin-left: auto;
  text-align: right;
  min-width: fit-content;
  padding-left: 15px;
}
.noDataAvailable {
  margin-top: 40px;
  color: #8c8c8c;
}

@media only screen and (max-width: 1024px) {
  .contentContainer {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
}
