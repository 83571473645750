.chartContainerBackground {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  width: 100%;
}
.chartContainerBackground .loadingContainer {
  min-height: 391px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 20px;
}
