.container {
  width: 800px;
}
.title {
  font-family: 'Open Sans Regular';
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.title .purple {
  color: #4a25e8;
}
.optionsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.option {
  width: 180px;
  height: 180px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.option:last-child {
  margin-right: 0px;
}
.option img {
  margin-bottom: 20px;
  width: 100px;
  height: 40px;
}
.option .optionLabel {
  font-size: 12px;
}
.selectedBar {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #ebe6fc;
  left: 0px;
  bottom: 0px;
}
.info {
  margin-bottom: 40px;
  background-color: #f7f4fd;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.footerContainer {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
