ul.pagination-organisation {
  font-family: 'Roboto Medium';
}
ul.pagination-organisation li.active a {
  background-color: #792483;
}
ul.pagination-organisation li.previous,
ul.pagination-organisation li.next {
  color: #792483;
}
