.title {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 0;
}
.table {
  width: 100%;
}
.tableRow {
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
}
.tableRow .value {
  text-align: right;
}
.nestedTable {
  margin-top: 30px;
  margin-left: 30px;
  padding-left: 35px;
  border-left: 1px solid #792483;
}
.nestedTableRow {
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.nestedTableDataRow {
  display: flex;
  margin-bottom: 5px;
}
.nestedTableRowTitle {
  color: #792483;
  font-family: 'Roboto Regular';
}
.nestedTableRowValue {
  margin-left: auto;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
  .nestedTable {
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 25px;
    border-left: 1px solid #792483;
  }
}
