.container {
  width: calc(100% + 65px);
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.dropdownRowParent {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
}
.dropdownRow {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.dropdownRow .dropdownContainer {
  width: 50%;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdownRow .dropdownContainer.dropdownVisisble:before {
  content: '';
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
.dropdownRow .dropdownContainerLabel img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.dropdownRow .dropdownContainerArrow {
  width: 20px;
  height: 20px;
}
.dropdownRow .close {
  cursor: pointer;
}
.dropdownRow .close img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.actionsContainer {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.actionsContainer .delete {
  color: #fc2270;
  cursor: pointer;
}
.actionsContainer .delete img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.actionsContainer .confirm {
  cursor: pointer;
}
.actionsContainer .confirm img {
  width: 18px;
  height: 11px;
}
.ljArrow {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.evenOtherAreSelected {
  font-style: italic;
  padding-top: 2px;
  padding-bottom: 10px;
  font-size: 12px;
  padding-left: 50px;
}
