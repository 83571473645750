.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleContainer {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.titleContainer .title {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.detailContainer {
  font-size: 18px;
  color: #221f1f;
  padding: 10px 20px 0 20px;
}
.detailContainer.welcomeAccountManagmenetRecordSelectContainer
  div:nth-child(2) {
  margin-right: 0;
}
.detailTitleContainer {
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.detailTitleContainer .title {
  font-size: 14px;
}
.surveyName {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
}
.description {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.description.disabled {
  opacity: 0.5;
  background-color: #ebebeb;
  border-color: #dcdcdc;
}
.description.disabled::placeholder {
  color: #8b8b8d;
}
.description.fakeTextarea {
  height: auto;
}
.numberInput {
  width: 50px;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  outline: none;
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: right;
}
.notesField {
}
.notesTitleContainer {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.notesTitleContainer .title {
  font-size: 14px;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.imageContainer {
  display: inline-block;
  margin-bottom: 20px;
}
.imageContainer .removeImageContainer {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.imageContainer .removeImageContainer img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.imageContainer .coverImage {
  max-height: 100px;
  max-width: 300px;
}
.dropContainerTitle {
  font-size: 12px;
  margin-bottom: 15px;
}
.dropContainer {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.dropContainer .button {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.dropContainer .dropDescription {
  text-align: center;
}
.wordCount {
  font-size: 12px;
  font-family: 'Open Sans Light';
  text-transform: uppercase;
  color: #bbb;
  padding: 4px 20px 10px 20px;
}
.translationOfOtherSurvey {
  font-size: 11px;
  text-align: right;
  margin-bottom: 10px;
}
textarea::placeholder {
  color: #bebebe;
}
.rowText {
  margin-bottom: 10px;
  font-size: 12px;
  color: #8b8b8d;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.welcomeFilterDropdownStyle:has(div) {
  border: 0;
  width: 100%;
  height: 34px;
  line-height: 34px;
}
.welcomeFilterDropdownStyle {
  position: relative;
  height: 30px;
  line-height: 30px;
  margin-right: 0;
  max-width: 100%;
}
.welcomeFilterDropdownStyle > div:first-child {
  border: 1px solid #efefef;
  font-size: 12px;
  color: #000000;
  height: 34px;
  line-height: 34px;
}
.welcomeFilterDropdownStyle img {
  position: absolute;
  right: 17px;
  top: 15px;
  opacity: 0.5;
  width: 12px;
  height: 7px;
}
.moreInfoContainer {
  display: inline-block;
  margin-left: 5px;
}
.moreInfoContainer .questionMark {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.moreInfoContainer .tooltip {
  display: none;
  position: absolute;
  top: -20px;
  left: 25px;
  z-index: 100;
  font-size: 12px;
  width: 640px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px 20px;
  color: #000000;
  border-radius: 5px;
  line-height: 18px;
  white-space: pre-wrap;
  border: 1px solid #e3e3e4;
}
.moreInfoContainer:hover .tooltip {
  display: block;
}
.moreInfoContainer .tooltip ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.checkboxContainer {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  user-select: none;
}
.checkboxContainer img {
  position: relative;
  top: 1px;
}
.checkboxContainer .checkboxText {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  color: #8b8b8d;
  vertical-align: top;
}
