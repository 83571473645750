.container {
  color: #221f1f;
  background-color: #f6f7fb;
  border-bottom: 1px solid #ebecf0;
  padding-top: 10px;
}
.container .dropdownPlaceholder {
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  margin-bottom: 10px;
}
.container .title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 20px;
  margin-bottom: 10px;
}
.container .additionalPropertiesActions {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.container .additionalPropertiesActions .additionalPropertiesCloseButton {
  font-size: 11px;
  color: #221f1f;
  cursor: pointer;
}
.container .additionalPropertiesActions .additionalPropertiesCloseButton img {
  height: 20px;
  width: 20px;
}
.container .additionalPropertiesActions .additionalPropertiesEditButton {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.container
  .additionalPropertiesActions
  .additionalPropertiesEditButton.disabled {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8b8b8d;
  cursor: not-allowed;
}

.container .logic-jump-action-editor-dropdown {
  position: fixed;
  width: 220px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 215px;
  background-color: red;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  scrollbar-width: thin;
  font-size: 14px;
}
.container .logic-jump-action-editor-dropdown .logic-jump-dropdown-item {
  height: 35px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
}
.container .logic-jump-action-editor-dropdown .logic-jump-dropdown-item.active {
  background-color: #efefef;
}
.container .logic-jump-action-editor-dropdown .logic-jump-dropdown-item:hover {
  opacity: 0.7;
}
.container
  .logic-jump-action-editor-dropdown
  .logic-jump-dropdown-item
  .option-icon {
  color: #697df0;
  font-size: 12px;
  margin-right: 10px;
}
.containerStyle {
  display: block;
  width: auto !important;
  position: relative;
  background-color: #ffffff;
  font-size: 12px !important;
  height: 32px !important;
  line-height: 32px !important;
  border: 1px solid #dfe0e4 !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  cursor: pointer;
  padding-left: 10px !important;
  background-position: right 7px center !important;
  background-repeat: no-repeat;
  background-size: 9px !important;
  padding-right: 20px !important;
  margin-bottom: 10px !important;
}
