.container {
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  width: calc(50vw - 540px);
  height: calc(100vh - 66px);
  top: 66px;
  z-index: 10000;
}
.container.expanded {
  width: 60vw;
  -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.expandButton {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 150px;
  cursor: pointer;
  z-index: 9;
  left: calc(50vw - 530px);
}
.expandButton .icon {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 5px;
}
.expandButton .label {
  font-family: 'Open Sans SemiBold';
  color: #909090;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 12px;
  padding: 5px;
  background-color: #ffffff;
}
.container.expanded .expandButton {
  right: 0px;
  position: absolute;
  left: auto;
  top: 75px;
}
.container.expanded .expandButton .icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dagContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.dagContainer :global .edgePath path {
  fill: none;
}
.dagContainer :global .flow-chart-question-type {
  cursor: pointer;
}
.dagContainer :global .flow-chart-zoom-control-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  display: flex;
}
.dagContainer :global .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}
.emptyStateDescriptionContainer {
  color: #95989a;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.emptyStateDescriptionContainer div {
  padding-left: 18px;
}

@media only screen and (max-width: 1070px) {
  .expandButton {
    left: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .container:not(.expanded) .emptyStateDescriptionContainer {
    display: none;
  }
}
