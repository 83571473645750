.tooltipContainer {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  font-style: italic;
  font-size: 12px;
  background-color: #fff;
  width: 400px;
  position: fixed;
  display: block;
  right: 105px;
}
.tooltipContainer .questionTitle {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 5px;
}
.tooltipContainer .questionText {
  margin-bottom: 15px;
}
.featuresContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 350px;
  overflow: auto;
}
/* Works on Firefox */
.featuresContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}
/* Works on Chrome, Edge, and Safari */
.featuresContainer::-webkit-scrollbar {
  width: 7px;
}
.featuresContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}
.featuresContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}
.featuresContainer.noScrolling {
  max-height: none;
}

.group {
  margin-bottom: 25px;
  padding-right: 10px;
}
.branchLabelContainer {
  font-size: 12px;
  padding-bottom: 10px;
  border-bottom: solid 2px #e7e7e7;
}
.branchLabelContainer .branchLabel {
  font-size: 13px;
  margin-right: 5px;
}
.feature {
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 5px;
  display: flex;
}
.circle {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 3px;
}
.sampleSize {
  margin-top: 5px;
  color: #aaaaaa;
}
.loversHatersTooltipContainer {
  display: inline-block;
  margin-left: 5px;
}
.loversHatersTooltipContainer .loversHatersTooltipIcon {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
}
.loversHatersTooltipPopup {
  position: absolute;
  left: 20px;
  top: -25px;
  padding: 4px 8px;
  width: 240px;
  background-color: #ffffff;
  display: none;
  border: 1px solid #dddddd;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
}
.loversHatersTooltipIcon:hover + .loversHatersTooltipPopup {
  display: block;
}
.right {
  float: right;
  display: inline-flex;
  align-items: flex-start;
  margin-left: auto;
  margin-top: 3px;
}
.editIcon {
  cursor: pointer;
  height: 14px;
  width: 14px;
  opacity: 0.3;
  margin-right: 8px;
}
