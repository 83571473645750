.container {
  position: absolute;
  right: -315px;
  top: 0;
  width: 305px;
  padding-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  font-size: 14px;
  z-index: 20;
  cursor: default;
}
.title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
}
.titleLabel {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.content {
  max-height: calc(100vh - 210px);
  overflow-y: visible;
  overflow-x: visible;
  scrollbar-width: thin;
}
.settingsIcon {
  position: absolute;
  top: 25px;
  left: -25px;
}
