.populationRow {
  font-size: 11px;
  display: flex;
  height: 45px;
  align-items: center;
  text-transform: uppercase;
  background-color: #ebe5fc;
  margin-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow: visible;
  font-size: 9px;
}
.populationRow:last-child {
  margin-bottom: 0px;
}
.populationRow .toggleContainer {
  width: 10%;
}
.populationRow .typeContainer {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.populationRow .valueContainer {
  width: 55%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.populationRow .deleteContainer {
  width: 5%;
  text-align: right;
}
.populationRow .deleteContainer img {
  cursor: pointer;
  height: 20px;
}
.textInput {
  width: 50px;
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 11px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.populationRowExtended {
  font-size: 11px;
  align-items: center;
  text-transform: uppercase;
  background-color: #ebe5fc;
  top: -5px;
  margin-bottom: 5px;
  padding-left: 88px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: visible;
  border-top: 1px solid #d8d8d8;
}
.populationRowExtended .extendedRow {
  height: 38px;
  padding-right: 70px;
}
