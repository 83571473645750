.container {
  width: 500px;
}
.container.containerSuccess {
  text-align: center;
  padding: 20px 0 25px 0;
  width: 360px;
}
.content {
  padding: 25px 20px 0 20px;
}
.title {
  font-family: 'Open Sans Bold';
  font-size: 13px;
  color: #5300f2;
}
.successMessage {
  font-size: 11px;
  color: #9c9696;
  margin: 8px auto 10px auto;
  max-width: 250px;
}
.checkmark {
  width: 60px;
  height: 60px;
}
.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0;
}
.field {
  background-color: #ffffff;
  border: 1px solid #efefef;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 10px;
  width: 48%;
  outline: none;
  border-radius: 4px;
  margin-bottom: 8px;
  box-sizing: border-box;
}
.field::placeholder {
  color: #95989a;
}
.field.textarea {
  height: 75px;
  line-height: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Open Sans Regular';
  width: 100%;
  resize: none;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 2px solid #efefef;
}
.footer.error {
  background-color: #fff2f7;
}
.footer .errorMessage {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-left: 25px;
}
.footer .errorMessage .warningIcon {
  height: 20px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.confirmIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.confirmIconDisabled {
  filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.closeIcon {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
