.container {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
