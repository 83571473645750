.form {
  width: 100%;
  font-family: 'Roboto Regular';
  text-align: left;
}
.input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e7ec;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Roboto Regular';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.dateInput {
  display: block;
}
.label:first-child {
  margin-top: 0;
}
.label {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  font-family: 'Roboto Medium';
}
.button {
  display: inline-block;
  background-color: #6f2a7f;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Montserrat SemiBold';
  margin-top: 50px;
  padding: 15px 30px;
  user-select: none;
}
.button.buttonDisabled {
  background-color: #7b7b7b;
  cursor: auto;
}
.button:hover {
  opacity: 0.7;
}
.button.buttonDisabled:hover {
  opacity: 1;
}
.acceptInputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.acceptInputContainer .checkmark {
  width: 17px;
  height: 17px;
  min-width: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: #7b7b7b;
}
.acceptInputContainer .checkmark.checkmarkActive {
  background-color: #6f2a7f;
  border-color: #6f2a7f;
}
.acceptInputContainer .tick {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.acceptInputContainer .termsText {
  text-align: left;
  font-size: 14px;
}
.acceptInputContainer .termsText .termsLink {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}
.error {
  margin-top: 4px;
  font-size: 13px;
  color: #ec008b;
  padding-left: 5px;
  border-radius: 2px;
  display: inline-block;
}
.componentError {
  margin: 5px 0;
  font-size: 13px;
  color: #ec008b;
  background-color: rgba(236, 0, 139, 0.05);
  border-radius: 2px;
  display: inline-block;
  padding: 1px 5px;
}
