.button {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.button-icon {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.primary-active {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
.primary-active:hover {
  background-color: #4e00e5;
}
.primary-disabled {
  border: 0;
  background-color: #b5b5b8;
  color: #ffffff;
  cursor: not-allowed;
}
.secondary-active {
  background-color: #ffffff;
  border: 1px solid #5200f1;
  color: #5200f1;
  cursor: pointer;
}
.secondary-disabled {
  background-color: #ffffff;
  border: 1px solid #b5b5b8;
  color: #b5b5b8;
  cursor: not-allowed;
}
.black-active {
  border: 0;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #ffffff;
}
.black-active:hover {
  background-color: #000000;
}
.white-active {
  border: 1px solid #ececec;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
}
.white-disabled {
  border: 0;
  background-color: #ffffff;
  color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.white-active:hover {
  background-color: #ffffff;
}
