.video-popup-container {
  width: 700px;
  height: 500px;
  margin: 5px;
}
.video-popup-container .video-popup-actions-container {
  display: flex;
  justify-content: flex-end;
}
.video-popup-container .video-popup-actions-container a:link,
.video-popup-container .video-popup-actions-container a:visited {
  text-decoration: none;
}
.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-left: -45px !important;
  margin-top: -22px !important;
}
