.container {
  width: 110px;
  padding: 2px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-right: 6px;
  display: flex;
  cursor: pointer;
}
.colorRectangle {
  width: 30px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.colorCode {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.pickerContainer {
  position: absolute;
  z-index: 99999;
}
.fixedPickerContainer {
  position: fixed;
  z-index: 99999;
}
.transparentPageConver {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;
  width: 100%;
  height: 100%;
}
