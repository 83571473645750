.main-container {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 66px;
}
.main-container .warning-message {
  background: rgba(200, 0, 0, 0.2);
  border: rgba(200, 0, 0, 0.8);
  border-radius: 8px;
  padding: 8px;
  color: white;
  margin: 16px 0;
}
.content-header {
  position: fixed;
  width: 1393px;
  background-color: #f6f7fb;
  display: flex;
  justify-content: space-between;
  height: 55px;
  top: 66px;
  line-height: 55px;
  padding-top: 24px;
  padding-bottom: 30px;
  z-index: 99;
}
.left-header-content {
}
.left-header-content .header1 {
  font-family: 'Roboto Bold';
  font-size: 23px;
  color: #221f1f;
  height: 30px;
  line-height: 30px;
  max-width: 1010px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.left-header-content .header2 {
  font-size: 14px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.right-header-content {
  display: inline-flex;
}
.right-header-content .button {
  margin-left: 15px;
}
.right-header-content .error-message {
  font-family: 'Open Sans SemiBold';
  color: #f10054;
  font-size: 13px;
  margin-right: 10px;
}
.content {
}
.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.container.question {
  margin-top: 60px;
  margin-bottom: 120px;
}
.container-title {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
}
.action-column-container {
  position: fixed;
  top: 90px;
  width: 220px;
  z-index: 10;
}
.action-column-actions-container {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
}
.blocks-column-container {
  top: 25px;
  width: 785px;
  margin-left: 240px;
}
.blocks-column-container .container {
  /* margin-bottom: 55px; */
}
.blocks-column-container .container .container-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 30px;
}
.blocks-column-container .block-container {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  padding-top: 35px;
}
.blocks-column-container .block-container.focused {
  border-left: 4px solid #8500f0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}
.blocks-column-container .block-container.out-of-focus {
  opacity: 0.2;
}
.blocks-column-container .block-container .block-dropdown {
  text-align: center;
  font-size: 14px;
  color: #8b8b8d;
  border: 1px dashed #d1d1d1;
  border-style: dashed;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 5px;
  margin-bottom: 35px;
}
.block-container .block-structure {
  display: inline-flex;
}
.block-container .block-structure .media-upload {
  position: absolute;
  top: -3px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 10;
}
.block-container .block-structure .media-upload .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
}
.block-container .block-structure .media-upload .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.block-container .block-structure .media-upload .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}
.block-container .block-structure .block-number {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}

.block-container .block-structure .mandatory {
  color: #8500f0;
}
.block-container .block-structure .block-icon {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.block-container .block-structure .block-icon .icon {
  font-size: 16px;
}
.block-container .block-structure .block-content {
  width: 650px;
}
.block-content .block-text {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
}
.block-content .block-text .block {
  font-family: 'Open Sans Regular';
  font-size: 18px;
  border: 0;
  outline: none;
  width: 600px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 10px;

  resize: none;
  overflow: hidden;
}
.block-content .block-text .block:invalid {
  border-left: 3px solid red;
}
.block-content .block-text .description-title {
  font-size: 14px;
  color: #8b8b8d;
}
.block-content .block-text .description {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 650px;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.block-content .block-text .description.notes-field {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 12px;
  background-color: #f6f7fb;
}
.block-content .block-text .description-notes-content {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.block-content .block-text .description-notes-title {
  font-size: 14px;
}
.block-structure ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8e8e;
}
.block-structure :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8e8e;
}
.block-structure ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8e8e;
}
.block-content .block-website {
}
.block-container .block-additional-options {
}
.block-container .block-additional-options .description-title {
  font-size: 14px;
  color: #8b8b8d;
  padding-left: 95px;
}
.block-container .block-additional-options .block-additional-option {
  margin-left: 95px;
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .answer-cover-image-container {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.block-additional-option .answer-cover-image-container .left-container {
  width: 22px;
  height: 22px;
}
.answer-cover-image-container .left-container .image-placeholder {
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  display: block;
}
.answer-cover-image-container .left-container .image-placeholder span {
  font-size: 26px;
  position: absolute;
  left: -2.4px;
  top: -3.5px;
  padding: 0px;
  margin: 0px;
}
.answer-cover-image-container .left-container .cover-image-preview {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.block-additional-option .answer-cover-image-container .right-container {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
}
.block-additional-option
  .answer-cover-image-container
  .right-container
  .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;
}
.block-additional-option
  .answer-cover-image-container
  .right-container
  .action
  span {
  position: absolute;
  left: 2.2px;
  top: 2.25px;
  font-size: 13px;
  z-index: 10;
}
.answer-cover-image-container .right-container .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 22px;
  padding: 0px;
  margin: 0px;
  z-index: 11;
}
.block-container .block-additional-options .block-additional-option:last-child {
  border-bottom: 0;
}
.block-container .block-additional-options .block-additional-option textarea {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.block-container .block-additional-options .block-additional-option.input-add {
  margin-bottom: 20px;
  margin-top: 0px;
}
.block-container
  .block-additional-options
  .block-additional-option.input-add
  textarea {
  margin-left: 0px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload {
  position: absolute;
  right: 5px;
  top: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload
  .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 90px;
  cursor: pointer;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload
  .action {
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.block-container .block-additional-options .block-additional-option .close {
  color: #8b8b8d;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 50%;
}
.block-container
  .block-additional-options
  .block-additional-option
  .allow-other {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .add-option {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-container {
  position: absolute;
  right: -40px;
  bottom: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-to-block {
  position: relative;
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  left: 20px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-end-survey {
  position: relative;
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  left: 5px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-end-survey.active,
.block-container
  .block-additional-options
  .block-additional-option
  .jump-to-block.active {
  color: #a87ff8;
}
.block-container .blocks-jump {
  font-size: 14px;
  color: #221f1f;
  background-color: #f6f7fb;
  line-height: 60px;
  border-bottom: 1px solid #ebecf0;
  padding-left: 95px;
}
.block-container .blocks-jump .block-jump-icon {
  position: absolute;
  left: 50px;
}
.block-container .blocks-jump .description {
  width: 580px;
  display: inline-block;
  line-height: 22px;
  margin-top: 20px;
}
.block-container .blocks-jump .edit-button {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 63px;
  color: #8500f0;
  cursor: pointer;
}
.block-container .blocks-jump .close {
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 30px;
}
.properties-column-container {
  position: fixed;
  top: 90px;
  margin-left: 1044px;
  width: 290px;
  padding-bottom: 20px;
  z-index: 9;
}
.side-container-title-item {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.side-container-content {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.side-container-content .emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.side-container-content .emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.side-container-content .emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.side-container-content .emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-top: 15px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.side-container-content .emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.side-container-content .emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.side-container-content .emoji-picker .emoji:hover {
  opacity: 0.6;
}
.side-container-action {
  padding: 15px 0 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
}
.side-container-action.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.side-container-action.disabled .icon {
  color: #8b8b8d;
}
.side-container-action .soon {
  color: #ff3018;
  margin-left: 10px;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action:hover {
  opacity: 0.7;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action .icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
  top: 3px;
  font-size: 14px;
  position: relative;
  color: #5200f1;
}
.edit-block-title {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.edit-block-container {
}
.text-editor-container {
  margin-left: 18px;
  margin-right: 18px;
  border-bottom: 1px solid #eeeeee !important;
  height: 50px;
  line-height: 50px;
}
.text-editor-action {
  display: inline-block;
  margin-right: 10px;
}
.text-editor-action-button {
  padding: 0 10px;
  filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.text-editor-action-button:hover {
  opacity: 0.3;
}
.text-editor-action.true .text-editor-action-button {
  filter: grayscale(0);
  opacity: 1;
}
.text-editor-action-button.picker-active {
  filter: grayscale(0);
  opacity: 1;
}
.side-container-properties {
  width: 255px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.side-container-properties .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 60px;
  cursor: pointer;
}
.side-container-properties .icon {
  color: #8500f0;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
}
.side-container-properties .media-upload a:link,
.side-container-properties .media-upload a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.side-container-properties .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.side-container-properties .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
}
.side-container-properties .action.remove {
  color: red;
}

.side-container-properties-campaign-web-url-container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side-container-properties-campaign-web-url-container {
  margin-top: 10px;
  display: flex;
  flex: 1;
  width: 100%;
}
.side-container-properties-campaign-web-url {
  width: 90%;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 12px;
}
.side-container-properties-campaign-web-url-click-link {
  margin-top: 8px;
  display: inline-block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 1px 6px;

  font-size: 14px;
  font-family: 'Open Sans SemiBold';
  border: none;
  background-color: transparent;
  color: #5200f1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}
.side-container-properties-campaign-web-url-click-link:hover {
  background-color: rgba(82, 0, 241, 0.1);
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Inactive */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5200f1; /* Active */
}

input:focus + .slider {
  box-shadow: 0 0 1px #5200f1; /* Active */
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.main-container .loader-container {
  background-color: #e3e3e3;
}
.main-container .loader-container.button {
  height: 55px;
  width: 150px;
}
.main-container .loader-container.column {
  height: 400px;
}
.main-container .loader-container.item {
  height: 220px;
  margin-bottom: 50px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.campaign-builder-mini-map-container {
  position: fixed;
  width: 55px;
  top: 67px;
  right: 0px;
  border-left: 1px solid #ebe9ef;
  height: calc(100vh - 67px);
  padding-top: 20px;
  z-index: 99;
}
.campaign-builder-mini-map-container .single-question-icon {
  text-align: center;
  color: #b4b2b6;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.campaign-builder-mini-map-container .single-question-icon.active {
  color: #8500f0;
}
.campaign-builder-mini-map-container .single-question-icon:hover span {
  opacity: 0.7;
}
.campaign-builder-mini-map-container
  .single-question-icon
  .single-question-popup {
  display: none;
  position: relative;
}
.campaign-builder-mini-map-container .single-question-icon .error-dot {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}
.campaign-builder-mini-map-container
  .single-question-icon:hover
  .single-question-popup {
  position: absolute;
  display: block;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  font-size: 13px;
  color: #8b8b8d;
  top: 0px;
  right: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  text-align: left;
  border-radius: 5px;
}

.campaign-builder-errors-container {
}
.campaign-builder-error {
  background-color: #f5a0a142;
  color: #b03223;
  font-size: 14px;
  line-height: 35px;
  padding-left: 20px;
}
.campaign-builder-error:last-child {
  border-bottom: 1px solid #d5aea9;
}

@media only screen and (max-width: 1420px) {
  .main-container {
    width: auto;
  }
  .content-header {
    width: 97vw;
    padding-left: 10px;
  }
  .content {
    padding-left: 10px;
  }
  .blocks-column-container {
    width: calc(100vw - 240px - 405px);
  }
  .properties-column-container {
    right: 70px;
  }
  .block-container .block-structure {
    width: 100%;
  }
  .block-container .block-structure .media-upload {
    right: 30px;
  }
  .block-container .block-structure .block-content {
    width: calc(90% - 100px);
  }
  .block-container .block-additional-options .block-additional-option input {
    width: calc(90% - 30px);
  }
  .block-content .block-text .description {
    display: block;
    width: 100%;
  }
  .block-content .block-text .block {
    width: 100% !important;
  }
  .block-container .block-structure .jump-container {
    right: 30px;
  }
  .block-container .blocks-jump .description {
    width: 70%;
  }
}

.flow-chart-node-container {
  max-width: 400px;
  overflow: auto;
  white-space: pre-wrap;
  margin: auto;
  display: inline-block;
  padding: 5px;
}

.node rect {
  stroke: #333;
  fill: #fff;
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

.svg-text-icon {
  width: 13px;
}

.svg-text-icon-question,
.svg-text-icon-bigger {
  width: 15px;
}

.svg-text-minimap-icon {
  width: 15px;
  filter: grayscale(1) opacity(0.35);
}

.single-question-icon:hover .svg-text-minimap-icon {
  opacity: 0.7;
}

.single-question-icon.active .svg-text-minimap-icon {
  width: 15px;
  filter: grayscale(0) opacity(1);
}
.multiple-answers-possible-container {
  font-size: 14px;
  color: #8b8b8d;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 95px;
  margin-bottom: 20px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.multiple-answers-possible-container .logic-jump-error {
  display: inline-flex;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}
.multiple-answers-possible-container .logic-jump-error .warning-icon {
  width: 20px;
}
.multiple-answers-possible-container .logic-jump-error .logic-error-message {
  font-weight: bold;
  font-size: 11px;
  color: #fc035d;
  margin-left: 8px;
}
.multiple-answers-possible-container .logic-jump-error .close {
  color: #8b8b8d;
  margin-left: 8px;
  cursor: pointer;
  font-weight: normal;
}
.multiple-answers-possible-container .logic-jump-error .close span {
  vertical-align: bottom;
}

.multiple-answers-possible-container .multiple-answers-possible-controls {
  display: flex;
  align-items: center;
  height: 40px;
}
.multiple-answers-possible-container .multiple-answers-controls-values {
  display: flex;
  align-items: center;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled {
  opacity: 0.8;
}
.multiple-answers-possible-container .multiple-answers-possible-text {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.multiple-answers-possible-container .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  padding: 5px 6px;
  height: 22px;
}
.multiple-answers-possible-container .action.active {
  background-color: rgba(82, 0, 241, 0.1);
  cursor: grab;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .action.active {
  background: none;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .action {
  cursor: grab;
  color: #8b8b8d;
}
.multiple-answers-possible-container .multiple-answers-possible-text input {
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #8b8b8d;
  max-width: 30px;
}
.multiple-answers-possible-container
  .multiple-answers-possible-text
  input::placeholder {
  color: #d5d5d5;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .multiple-answers-possible-text
  input:disabled {
  background-color: #f1f1f1;
}
