.container {
  padding-top: 5px;
}
.title {
  display: inline-flex;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  margin-top: 10px;
  background-color: #ebe6fc;
  padding: 5px 10px 5px 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.collapsedTitle {
  border-radius: 5px;
}
.insightsIcon {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-right: 7px;
}
.arrowIcon {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-left: 7px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.rotatedArrow {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
