.title {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  margin-bottom: 15px;
}
.container {
  margin-bottom: 20px;
  display: flex;
}
.deliveryItemsContainer {
  display: flex;
  flex-grow: 1;
}
.deliveryContainer {
  flex-basis: 100%;
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 40px;
}
.deliveryContainer .loadingContainer {
  flex: 1;
  margin-top: 10px;
}
.deliveryContainer .deliveryTitleContainer {
  display: flex;
  align-items: center;
}
.deliveryContainer .deliveryTitleContainer .deliveryTitle {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
}
.deliveryContainer .mainContainer {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.deliveryContainer .mainContainer .maxDeliveryPotentialCount {
  color: #5200f1;
  font-family: 'Open Sans Bold';
  font-size: 32px;
  margin-right: 10px;
}
.trafficLightContainer {
  width: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}
.light {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-bottom: 6px;
}
.light:last-of-type {
  margin-bottom: 0;
}
.redLight,
.orangeLight,
.greenLight {
  background-color: #e8e8e8;
  transition: all 0.45s ease-in-out;
}
.redLight.active {
  background-color: #ff005f;
}
.orangeLight.active {
  background-color: #e8bd2b;
}
.greenLight.active {
  background-color: #a0d035;
}
.moreInfoContainer {
  display: inline-block;
  margin-left: 15px;
}
.moreInfoContainer .questionMark {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.moreInfoContainer .tooltip {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.moreInfoContainer:hover .tooltip {
  display: block;
}
.calculateButton {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.calculateButtonDisabled {
  border: 0;
  background-color: #c4c4c4;
  color: #ffffff;
  cursor: not-allowed;
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
