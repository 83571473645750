.container {
  background-color: #fff;
  padding: 0 30px 0 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 0;
  height: 0;
  max-width: 100%;
  line-height: 30px;
  visibility: hidden;
  opacity: 0;
  transition: min-height 0.25s ease-in-out, opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out, padding 0.2s ease-in-out;
  border-bottom: 1px solid #efefef;
}
.container.containerActive {
  opacity: 1;
  min-height: 30px;
  visibility: visible;
  padding: 8px 0;
  padding: 8px 25px 8px 0;
  height: auto;
}
.subContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.toolbarItem {
  border-right: 1px solid #e9e5f0;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  margin: 2px 0;
}
.greyTextToolbarItem {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  color: #95989a;
  font-style: italic;
}
.toolbarItem.clickable:hover {
  cursor: pointer;
  opacity: 0.7;
}
.toolbarItem.verticalAlign {
  display: inline-flex;
  align-items: center;
}
.toolbarItem:first-of-type {
  padding-left: 0;
}
.toolbarItem:last-of-type {
  padding-right: 0;
  border: none;
}
.dropdownContainer {
  display: inline-block;
  min-width: 150px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.dropdownArrowActive {
  transform: rotate(180deg) scaleX(-1);
}
.dropdownActive {
  border-radius: 3px 3px 0 0;
}
.dropdownArrow {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: transform 0.3s ease;
}
.dropdownList {
  width: 100%;
  position: absolute;
  top: 35px;
  left: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.dropdownOption {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
  color: #221f1f;
}
.dropdownOption:hover {
  background-color: #edeef1;
}
.toolbarItemRandom {
  padding-left: 4px;
}
.randomDisabled {
  color: #95989a;
  padding-left: 4px;
}
.visibleBox {
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.visibleBox.visibleBoxActive {
  background-color: #5300f2;
}
.visibleBox .visibleTick {
  width: 10px;
  visibility: hidden;
}
.visibleBox.visibleBoxActive .visibleTick {
  width: 10px;
  visibility: visible;
}
.tickIcon {
  width: 14px;
  margin-right: 6px;
}
.crossIcon {
  height: 19px;
  width: 19px;
  vertical-align: text-bottom;
  margin-right: 2px;
}
.dropDownToolbarIcon {
  position: absolute;
  top: 10px;
  right: 0;
  width: 14px;
  height: 9px;
  margin: 9px 12px 0 auto;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
.dropDownToolbarIcon.dropDownToolbarIconInactive {
  transform: rotate(180deg);
}
