.container {
  width: calc(100% + 65px);
  background-color: #f6f2fe;
  margin-top: 4px;
}
.dropdownRowParent {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  height: 30px;
}
.dropdownRow {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: space-between;
}
.ljArrow {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
