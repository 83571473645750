.container {
  display: flex;
  font-size: 12px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}
.container:last-child {
  margin-right: 0;
}
.container img {
  margin-right: 5px;
}
