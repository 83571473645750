.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.scheduleButton {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
}
.scheduleButtonAdditionalText {
  color: #5200f1;
  font-size: 10px;
}
.schedulerHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}

.schedulerHeaderTitle {
  font-size: 16px;
  font-weight: bold;
}

.schedulerHeaderExtraTitle {
  font-size: 12px;
  font-weight: bold;
  color: #5200f1;
}

.schedulerActionsContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.checkboxLabel {
  font-style: italic;
  font-size: 13px;
  margin-left: 10px;
}

.checkboxLabel .actionLabel {
  color: #5200f1;
  text-transform: capitalize;
  font-weight: bold;
}

.actionOption {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 15px;
}
.middle {
  width: 1px;
  background-color: #e6e6e6;
}

.schedulerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedulerFooter .scheduleButton {
  font-size: 16px;
  border-radius: 8px;
  font-family: 'Open Sans Regular';
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding: 5px 30px 5px 30px;
  line-height: 45px;
  color: #ffffff;
  background-color: #5200f1;
  width: 140px;
}

.switcherContainer {
  display: flex;
  flex-direction: row;
}

.dateTimeContainer {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.dateContainer {
  display: flex;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.disabled * {
  color: #f2f2f2 !important;
  pointer-events: none;
}

.disabled * > .timeInput {
  border: 2px solid #f2f2f2;
}

.disabled * > .dateInput input {
  border: 2px solid #f2f2f2;
}

.disabled * ::placeholder {
  color: #f2f2f2;
}

.dateTimeInputContainer {
  display: flex;
  width: 35%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.timeInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.dateInput input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.dateInput::placeholder {
  color: #000000;
}

.datePickerContainer {
  position: absolute;
}

.datePicker {
  display: block;
}

.errorMessage {
  color: red;
  font-size: 12px;
  font-family: 'Open Sans Bold';
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0px;
}

.extendedErrorMessage {
  width: 40vh;
}

.errorMessageBorder input {
  border: 2px solid red;
}

.scheduleButtonDisabled {
  background: #cccccc !important;
  pointer-events: none;
}

.orPublishNowButton {
  font-family: 'Open Sans Bold';
  font-size: 12px;
  color: #5200f1;
  margin-top: 15px;
  cursor: pointer;
}
.footer {
  display: flex;
  padding-top: 20px;
  justify-content: right;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  left: 0px;
  width: calc(100% + 20px);
}
.sendPushButton {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  -webkit-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-right: 20px;
}
.sendPushButtonDisabled {
  background-color: #b5b5b8;
  cursor: not-allowed;
}
.sendPushButton:hover {
  opacity: 0.7;
}
