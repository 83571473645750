.container {
}
.questionsList {
  margin-left: 125px;
  margin-right: 40px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e6e6e6;
}
.question {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.questionColor {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 20px;
}
.questionNumber {
}
.questionString {
  margin-left: 50px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.questionString.matrixQuestionString {
  margin-left: 0;
  white-space: pre-line;
}
.questionToggle {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
}
.questionSwitch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.questionSlider {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  user-select: none;
}
input:checked + .questionSlider:before {
  transform: translateX(20px) translateY(-50%);
}
.questionSlider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 15px;
  left: -15px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  transform: translateX(30px) translateY(-50%);
}
.input {
  display: none;
}
