.container {
  width: 700px;
  font-size: 13px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.top {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.title {
  font-size: 15px;
  margin-bottom: 5px;
  white-space: pre-line;
  font-family: 'Open Sans SemiBold';
  color: #5200f5;
  padding-bottom: 10px;
}
.axisScaleFieldContainers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-right: 10px;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
