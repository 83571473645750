.container {
  overflow: hidden;
}
.bottomButtonWrapper {
  margin-top: 60px;
}
.bottomButtonContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
  clear: both;
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.bottomButtonContainer .leftButtonContainer {
  min-width: 160px;
}
.bottomButtonContainer .rightButtonContainer {
  min-width: 160px;
}
.button {
  font-family: 'Montserrat Bold';
  cursor: pointer;
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
}
.buttonDisabled {
  cursor: auto;
  opacity: 0.3;
}
.backgroundGrid {
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.bottomBarContainer {
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 1024px) {
  .container.fixedContainer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: visible;
  }
}

@media only screen and (max-width: 900px) {
  .container.fixedContainer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: visible;
  }
}

@media only screen and (max-width: 768px) {
  .bottomButtonWrapper {
    padding-bottom: 25px;
  }
  .bottomButtonWrapper.noMobileTopMargin {
    margin-top: 0;
  }
  .bottomButtonContainer {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 40px;
    justify-content: center;
    display: flex;
    width: auto;
  }
  .bottomButtonContainer .leftButtonContainer,
  .bottomButtonContainer .rightButtonContainer {
    box-sizing: border-box;
    margin-top: 35px;
  }
  .bottomButtonContainer .leftButtonContainer {
    margin-right: 5px;
    padding-left: 10px;
  }
  .bottomButtonContainer .rightButtonContainer {
    margin-left: 5px;
    padding-right: 10px;
  }
  .button {
    width: 150px;
    height: 48px;
    line-height: 48px;
  }
}
