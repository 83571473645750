.parentContainer {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
}
.mainContainer {
  flex: 1;
  width: 100%;
  overflow: hidden;
}
.container {
  width: 900px;
  max-width: 90%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-sizing: border-box;
}
.campaignName {
  font-family: 'Montserrat Bold';
  font-size: 20px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 10px;
}
.description {
  font-family: 'Roboto Light';
  margin-bottom: 40px;
  width: 540px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  font-size: inherit;
}
.textAreaTranslation {
  white-space: pre-line;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #221f1f;
  margin-bottom: 20px;
  white-space: pre-line;
  width: 540px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}
.title.termsTitle {
  width: 580px;
}
.userDetailTitle {
  font-family: 'Roboto Light';
  font-size: 15px;
  margin-bottom: 15px;
}
.text {
  font-family: 'Roboto Light';
}
.text:last-of-type {
  margin-bottom: 0;
}
.textDescription {
  margin-bottom: 10px;
  font-size: 14px;
}

.userDetailsPage .formContainer .formContainerSection {
  display: flex;
  justify-content: center;
}
.userDetailsPage .formContainer input,
.userDetailsPage .formContainer select {
  font-family: 'Roboto Light';
  display: inline-block;
  width: 150px;
  color: #221f1f;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;

  background-repeat: no-repeat;
  background-size: 15px 9px;
  background-position: 86% 50%;
}
.userDetailsPage .formContainer input.errorField,
.userDetailsPage .formContainer select.errorField {
  border-color: #ec008b;
}
.userDetailsPage .formContainer input::placeholder {
  color: #8e8e8e;
}
.userDetailsPage .formContainer select.placeholder {
  color: #8e8e8e;
}
.userDetailsPage .formContainer input:focus,
.userDetailsPage .formContainer select:focus {
  background-color: #ffffff;
  outline: none;
}
.userDetailsPage .formContainer .createUserProfile {
  width: 100%;
  height: 50px;
  font-size: 13px;
  line-height: 50px;
  background-color: #efefef;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}
.dontWantProfile {
  font-family: 'Roboto Light';
  color: red;
  margin-bottom: 40px;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
}

.userDetailsError {
  background: #ec008b;
  color: #ec008b;
  border: 1px solid #ec008b;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.languagesSelectorContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.languagesSelectorContainer .terms-language-selector {
  margin-right: 20px;
}

.readFullDocumentsContainer div {
  color: blue;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}
.readFullDocumentsContainer div:hover {
  text-decoration: underline;
}

.termsContentImage {
  display: block;
  width: 400px;
  max-width: 90%;
  margin: 16px auto 32px;
}

.textAlignCenter {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.restartSurveyButtonContainer {
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: right;
}

.genderSelectorContainer {
  display: inline-block;
  flex-direction: column;
  text-align: left;
  margin-right: 10px;
  margin-bottom: 15px;
}
.ageSelectorContainer {
  display: inline-block;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 15px;
}
.postalSelectorContainer {
  margin-bottom: 0px;
}
.postalSelectorContainer .userDetailTitle {
  margin-bottom: 0;
}
.errorContainer {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.errorContainer .errorIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.termsContentContainer {
  display: flex;
}

.isThatYouTopContainer {
  display: flex;
  margin-top: 40px;
  margin-bottom: 120px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto Light';
}
.isThatYouTopContainerTitle {
  font-weight: bold;
}
.isThatYouContainer {
  margin-top: 30px;
  width: 500px;
}
.isThatYouOptionSelector {
  height: 60px;
  line-height: 60px;
  border-radius: 38px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  text-align: center;
  font-family: 'Roboto Light';
  user-select: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .container {
    margin-top: 60px;
  }
  .termsContentImage {
    width: 80%;
  }
  .termsContentContainer {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .parentContainer {
    min-height: calc(100vh - 70px);
  }
  .title {
    font-size: 28px;
  }
  .description {
    font-size: 14px;
  }
  .userDetailTitle {
    font-size: 14px;
  }
  .userDetailsPage .formContainer input,
  .userDetailsPage .formContainer select {
    width: 130px;
    height: 44px;
    line-height: 44px;
    background-size: 13px 7px;
  }
  .errorContainer {
    font-size: 14px;
  }
  .isThatYouContainer {
    width: 93vw;
    margin-bottom: 30px;
    display: block;
  }
}
