.container {
  width: 520px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
}
.termsLink {
  text-decoration: underline;
  cursor: pointer;
}
.buttonWrapper {
  margin-top: 25px;
}
.button {
  display: inline-block;
  user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  padding: 0 45px;
  box-sizing: border-box;
  cursor: pointer;
  color: #ffffff;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .button {
    padding: 0 30px;
    font-size: 14px;
  }
}
