.disclaimer {
  margin: 15px 0;
  font-size: 12px;
}
.contactInnovationExpertButton {
  display: inline-block;
  padding: 6px 25px;
  color: #fff;
  background-color: #5200f1;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
}
