.container {
  width: 650px;
  font-size: 13px;
  max-height: 1000px;
}
.container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.templateTitle {
  padding: 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.bottomLine {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.templateTitle .bottomLine {
  width: calc(100% - 30px);
  transform: translateX(15px);
}
.description {
  padding: 10px 15px 15px 15px;
  font-style: italic;
}
.templateDescription {
  padding-top: 15px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.footer .details {
  color: #5200f1;
}
.footer .footerErrorBackground {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  left: 0;
  top: 0;
  background-color: #fff2f7;
  border-radius: 0 0 10px 10px;
}
.footer .error {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.footer .error .warningIcon {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
