.container {
  color: #999696;
  padding-bottom: 7px;
  margin-bottom: 15px;
  padding-left: 3px;
}
.container:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.title {
  display: flex;
  margin-bottom: 20px;
}
.title span {
  text-transform: uppercase;
  margin-right: 15px;
}
.optionContainer {
  margin-right: 10px;
}
.optionContainer input {
  margin-right: 5px;
}
.description {
  white-space: break-spaces;
}
