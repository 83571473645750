.container {
  display: flex;
  background-color: #f9f8f8;
  min-height: calc(110vh - 196px);
}
.container .mainDiagramContainer {
  width: calc(100vw - 420px);
  display: flex;
  justify-content: center;
}
.container .rightColumnContainer {
  width: 420px;
  background-color: #ffffff;
  border-left: 1px solid #d3d3d3;
}
