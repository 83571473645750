.list-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.list-item-header-container {
  display: inline-flex;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.list-item-header-section-title {
  font-family: 'Roboto Bold';
  font-size: 23px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}

.list-item-container {
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
}
.list-item-container:hover {
  background-color: #ffffff;
}

.list-item {
  height: 77px;
  line-height: 77px;
  border-top: 1px solid #ebeaef;
}
.list-item .list-item-campaign-name {
  height: 22px;
  line-height: 22px;
  margin-top: 16px;
  font-family: 'Open Sans SemiBold';
  color: #8500f0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.list-item .list-item-campaign-name .tooltip {
  display: none;
  position: fixed;
  z-index: 9999999999999999;
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 8px 10px;
  border-radius: 5px;
  color: #000;
  max-width: 500px;
  height: auto;
  text-wrap: wrap;
}
.list-item .list-item-client-email .only-web-version-icon {
  height: 10px;
  margin-left: 10px;
}
.list-item .list-item-client-email {
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list-item.show-actions {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
}
.list-item .list-item-campaign-star {
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.list-item .list-item-campaign-star:hover {
  opacity: 0.5;
}

.column-1 {
  width: 50px;
}

.column-2 {
  width: 80px;
}

.column-3 {
  width: 190px;
}

.column-05 {
  width: 30px;
}

.column-max-width {
  width: 250px;
}

.column-4 {
  width: 300px;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.column-5 {
  width: 150px;
}

.column-6 {
  width: 150px;
  padding-left: 40px;
  display: inline-flex;
}
.column-6 .percentage {
  width: 30%;
}
.column-6 .bar-container {
  width: 50%;
  height: 5px;
  background-color: #dfe0e4;
  top: 36px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.column-6 .bar-container .bar {
  height: 5px;
  background-color: #5200f1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.column-7 {
  width: 100px;
}
.column-7.campaign-list-created-at {
  width: 90px;
}

.campaign-img {
  width: 30px;
  height: 30px;
  background-color: #dfe0e4;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  top: 10px;
}

.completion-column {
  padding-left: 15px;
}

.column-account {
  padding: 0 10px;
  width: 160px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.align-right {
  text-align: right;
}

.list-container .loader-container {
  width: 1200px;
  background-color: #f6f7fb;
}
.list-container .loader-container .item {
  background-color: #e3e3e3;
  height: 75px;
  margin-bottom: 10px;
}

.list-item.campaign-imag-container .flags-container {
  position: absolute;
  bottom: 12px;
  left: 0px;
  display: flex;
  width: 30px;
  justify-content: space-evenly;
}
.list-item.campaign-imag-container .flags-container img {
  width: 10px;
  height: 6px;
}

@media only screen and (max-width: 768px) {
  .campaigns-table-main-container {
    padding: 10px;
    margin-top: 70px !important;
    padding-bottom: 20px !important;
  }
  .column-2,
  .column-5,
  .column-6,
  .column-7,
  .column-account {
    display: none;
  }
  .campaigns-table-main-container .campaigns-table-header {
    display: block !important;
    height: auto !important;
  }
  .campaign-list-name {
    width: calc(100vw - 100px) !important;
  }
  .list-item-header-container {
    width: 95%;
  }
  .list-item-container {
    display: flex;
    width: 100%;
  }
  .list-item .campaign-imag-container .flags-container {
    display: none;
  }
}
