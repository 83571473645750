.listContainer {
  margin-top: 15px;
}
.listTitle {
  font-family: 'Open Sans Semibold';
}
.row {
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-top: 1px solid #ebeaef;
}
.row {
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-top: 1px solid #ebeaef;
}
.row .checkboxContainer {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.row .checkboxContainer img {
  position: relative;
  top: 3px;
}
.row .surveyProjectName {
  max-width: calc(100% - 175px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row .surveyPublishDate {
  margin-left: auto;
  display: block;
  width: 150px;
}
.checkedRow:last-of-type {
  border-bottom: 1px solid #ebeaef;
}
.headerRow {
  border: none;
  color: #8b8b8d;
  text-transform: uppercase;
  font-size: 12px;
}
.loadingRow {
  height: 36px;
  background-color: #ebebeb;
  margin-bottom: 5px;
}
