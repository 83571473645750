.parentContainer {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.mainContainer {
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.contentContainer {
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  width: 600px;
  max-width: 100%;
  text-align: center;
}
.description {
  width: 600px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.textAreaTranslation {
  white-space: pre-line;
}
.termsContentImage {
  width: 60%;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}
.downloadAppMessage {
  font-size: 18px;
  margin-bottom: 10px;
}
.qrCode {
  width: 130px;
}
.appStoreButtons {
  display: none;
}
.appStoreButtons a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.appStoreButtons img {
  height: 45px;
  border-radius: 4px;
}

.loaderContainer {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 60px));
}
.loaderContainer .loaderText {
  color: grey;
  opacity: 0.7;
  top: 60px;
}

@media only screen and (max-width: 900px) {
  .contentContainer {
    margin-top: 100px;
  }
  .title {
    font-size: 28px;
  }
  .qrCode {
    display: none;
  }
  .appStoreButtons {
    display: block;
  }
  .appStoreButtons a {
    height: 35px;
  }
  .appStoreButtons img {
    height: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .parentContainer {
    min-height: calc(100vh - 70px);
  }
  .loaderContainer {
    transform: translate(-50%, calc(-50% - 80px));
  }
  .loaderContainer .loaderText {
    top: 80px;
  }
}
