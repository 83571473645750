.container {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.logicContainer {
  font-size: 14px;
  color: #221f1f;
}
.addJumpButton {
  cursor: pointer;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
}
.addJumpButton svg {
  width: 17px;
  height: 11px;
  margin-right: 8px;
}
