.container {
  width: 795px;
  margin-right: 5px;
}
.textContainer {
  overflow-y: auto;
  max-height: 480px;
  margin: 30px;
  margin-left: 10px;
  margin-right: 0;
  padding-right: 20px;
}

/* Works on Firefox */
.textContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.textContainer::-webkit-scrollbar {
  width: 7px;
}

.textContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.textContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.text {
  margin-bottom: 15px;
  margin-left: 20px;
}
.label {
  margin-bottom: 15px;
  margin-left: 20px;
}
.label strong {
  font-family: 'Open Sans SemiBold';
}
.assumptionsContainer {
  background-color: #f7f4fd;
  border-left: 3px solid #4a25e8;
  padding: 15px 17px 10px 17px;
  margin-bottom: 20px;
}
.assumptionLine {
  margin-bottom: 7px;
}
.assumptionLine .bold {
  font-family: 'Open Sans SemiBold';
}
.assumptionNumberInput {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  border-radius: 3px;
  width: 30px;
  margin-right: 5px;
  font-size: 12px;
  text-align: center;
  font-family: 'Open Sans Regular';
}
.assumptionDropdown {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  border-radius: 3px;
  width: 185px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
  font-family: 'Open Sans Regular';
}
.disclaimer {
  margin-left: 20px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-right: 40px;
}
.footerContainer {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
