.container {
  width: 360px;
  position: fixed;
}
.container.popupVisible {
  z-index: 99;
}
.createAPopulationButtonContainer {
  margin-bottom: 30px;
}
.createAPopulationButton {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 13px;
  font-family: Open Sans SemiBold;
  color: #5200f1;
  cursor: pointer;
}
.createAPopulationButton img {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 13px;
}
.populationContainer {
  min-height: 300px;
  max-height: calc(100vh - 390px);
  overflow: auto;
}
.createPopulationHeadry {
  width: 80%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.populationsInfoContainer {
  background-color: #ede5fe;
  padding: 15px;
  box-sizing: border-box;
}
.infoTitleWrapper {
  display: flex;
  margin-bottom: 5px;
}
.infoIcon {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.infoTitle {
  font-family: 'Roboto Medium';
}
.infoText {
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  /*font-family: Open Sans SemiBold;*/
}
