.container {
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.rightContainer {
  display: flex;
}
