.outerContainer {
  position: fixed;
  top: 154px;
  left: 50%;
  margin-left: -514px;
  padding-left: 26px;
  padding-right: 26px;
  z-index: 9999;
  width: 964px;
  background-color: #ffffff;
}
.container {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 10px 0;
  box-sizing: border-box;
}
.back {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #8b8b8d;
  text-transform: uppercase;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
}
.title:last-child {
  border-right: none;
}
.title:hover {
  color: rgba(149, 152, 154, 0.7);
}
.title.titleActive {
  color: #5300f2;
}
.next {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.arrow.left {
  transform: rotate(180deg);
}
