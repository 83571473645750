.filterTopContainer {
}
.filterTopContainer {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.filterTopContainer .counter {
}
.filterTopContainer .addFilterButton {
  border: 1px solid #ba97ff;
  color: #5200f1;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.filterTopContainer .addFilterButton .addFilterButtonIcon {
  font-size: 10px;
  margin-left: 5px;
}
.filterItemContainer {
  display: flex;
  margin-bottom: 10px;
}
.filterItemContainer .filterType {
  display: flex;
  width: 30%;
}
.filterItemContainer .filterType .closeIcon {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.filterItemContainer .filterDropdown {
  position: relative;
  width: calc(100% - 30px);
  height: 25px;
  line-height: 25px;
  margin: 0 4px;
}
.rangeFieldContainer {
  display: flex;
}
.rangeFieldContainer .rangeFieldContainerStartContainer {
}
.rangeFieldContainer .rangeFieldContainerEndContainer {
}
.rangeFilterInput {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
}
.andContainer {
  position: relative;
  border-top: 1px solid #d5d5d5;
  height: 20px;
  margin-top: 20px;
}
.andContainer .and {
  font-size: 11px;
  color: #ffffff;
  background-color: #5200f1;
  padding: 5px;
  position: absolute;
  top: -13px;
  border-radius: 5px;
  left: 85px;
}
.countLoader {
  position: relative;
  bottom: 10px;
}
.surveysFilterDescrption {
  color: #9e9e9e;
  font-size: 11px;
}
