.targetFilterContainer {
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.targetFilterItem {
}
.targetFilterLabel {
  font-size: 13px;
  margin-top: 10px;
}
.targetFilterSingleLabel {
  color: #8b8b8d;
  font-size: 12px;
}
.targetFilterMultiLabel {
  color: #8b8b8d;
  font-size: 12px;
}
.targetFilterRangeLabel {
  color: #8b8b8d;
  font-size: 12px;
}
