.container {
  display: flex;
}
.editIcon {
  display: block;
  position: relative;
  top: 5px;
  cursor: pointer;
  margin-left: 5px;
  height: 13px;
}
.inputFieldContainer {
}
.inputFieldContainer.placeholder {
  color: #adadad;
}
.inputFieldContainer .inputField {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  width: 50vw;
}
.inputFieldContainer span {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  max-width: 50vw;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 4px;
}
