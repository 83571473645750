.RichEditor-root {
  padding-bottom: 10px;
  display: inline-block;
}

.RichEditor-message {
  padding-bottom: 10px;
}

.RichEditor-editor {
  cursor: text;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  border: none;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.public-DraftEditorPlaceholder-root {
  overflow: hidden;
  height: 24px;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  color: #8f8e8e;
}

.RichEditor-root.block {
  width: 100% !important;
}

.matrixQuestionText {
  color: #000;
  font-size: 14px;
  font-family: 'Open Sans Regular';
  min-height: 28px;
  line-height: 18px;
}
