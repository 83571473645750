.rowContainer {
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #dddddd;
}
.subRecord {
  padding-left: 30px;
}
.arrow {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.arrowPointingUp {
  transform: rotate(-180deg);
}
.actionsContainer {
  padding-right: 10px;
  text-align: right;
}
.actionsContainer .actionsButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
