.container {
  position: relative;
  z-index: 100;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
}
.left {
  display: flex;
}
.logoClick {
  cursor: pointer;
}
.logo {
  width: 89px;
}
.title {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #000000;
  font-size: 19px;
  text-transform: uppercase;
}
.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.menu li {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 10px;
  border-left: 1px solid #000000;
  padding-left: 10px;
}
.menu li:first-child {
  margin-left: 0px;
  border-left: 0;
  padding-left: 0px;
}
.languageOption {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: #792483;
}
.languageOption:last-of-type {
  margin-left: 10px;
}
.languageOption.languageOptionActive {
  font-family: 'Roboto Medium';
}

@media only screen and (max-width: 1024px) {
  .container {
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .authContainer {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .right {
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .authContainer .right {
    margin-top: 0;
    width: auto;
    margin-left: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .menu li {
    display: none;
  }
  .logout {
    display: inline-block;
  }
}
