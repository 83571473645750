.container {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-bottom: 20px;
}
.errorsContainer {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.error {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}
