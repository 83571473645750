.activeFiltersContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.activeFilterItem {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  min-width: 100px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
}
.activeFilterItem .removeIcon {
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.clearAllFilters {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}
