.container {
  background-color: #f3f4f7;
  padding: 30px;
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 9999999;
  font-family: 'Open Sans Regular';
  width: 600px;
  font-size: 13px;
  border-radius: 5px;
}
.title {
  font-family: 'Open Sans Bold', Arial;
  font-size: 14px;
  margin-bottom: 15px;
}
.description {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 18px;
}
.description a:link,
.description a:visited {
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
}
.buttonsContainer {
  display: flex;
  justify-content: right;
}
.buttonsContainer .rejectButton {
  display: inline-block;
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  line-height: 35px;
  text-decoration: underline;
  cursor: pointer;
}
.buttonsContainer .acceptButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Open Sans Bold', Arial;
  margin-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .container {
    background-color: #f3f4f7;
    padding: 30px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    font-family: 'Open Sans Regular';
    width: calc(100vw - 60px);
    font-size: 13px;
    border-radius: 5px;
  }
}
