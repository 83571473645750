.multipleAnswersPossibleContainer {
  font-size: 14px;
  color: #8b8b8d;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.multipleAnswersPossibleContainer.multipleAnswersNotPossible {
  padding-bottom: 0;
}
.multipleAnswersPossibleContainer .logicJumpError {
  display: flex;
  background-color: #ede5fe;
  padding: 10px;
  margin-top: 5px;
  align-items: center;
  min-height: 50px;
  box-sizing: border-box;
}
.multipleAnswersPossibleContainer .logicJumpError .infoIcon {
  width: 20px;
  height: 20px;
}
.multipleAnswersPossibleContainer .logicJumpError .logicErrorMessage {
  font-weight: bold;
  font-size: 11px;
  font-style: italic;
  margin-left: 10px;
  color: #000000;
}

.multipleAnswersPossibleContainer .multipleAnswersPossibleControls {
  display: flex;
  align-items: center;
  height: 40px;
}
.multipleAnswersPossibleContainer .multipleAnswersControlsValues {
  display: flex;
  align-items: center;
}
.multipleAnswersPossibleContainer .multiple-answers-controls-values-disabled {
  opacity: 0.8;
}
.multipleAnswersPossibleContainer .multipleAnswersPossibleText {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}
.multipleAnswersPossibleContainer .inputContainer {
  padding-left: 8px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  border-left: 1px solid #e4e4e4;
}
.multipleAnswersPossibleContainer .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  padding: 5px 6px;
  height: 22px;
}
.multipleAnswersPossibleContainer .action.active {
  background-color: rgba(82, 0, 241, 0.1);
  cursor: grab;
}
.multipleAnswersPossibleContainer
  .multiple-answers-controls-values-disabled
  .action.active {
  background: none;
}
.multipleAnswersPossibleContainer
  .multiple-answers-controls-values-disabled
  .action {
  cursor: grab;
  color: #8b8b8d;
}
.multipleAnswersPossibleContainer .inputContainer input {
  padding: 5px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #8b8b8d;
  max-width: 30px;
}
.multipleAnswersPossibleContainer .inputContainer input:disabled {
  background-color: #95989a40;
}
.multipleAnswersPossibleContainer .inputContainer input::placeholder {
  color: #d5d5d5;
}
.multipleAnswersPossibleContainer
  .multiple-answers-controls-values-disabled
  .inputContainer
  input:disabled {
  background-color: #f1f1f1;
}
.multipleAnswersPossibleContainer .setToMax {
  display: inline-flex;
  align-items: center;
}
.multipleAnswersPossibleContainer .setToMax .visibleBox {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.multipleAnswersPossibleContainer .setToMax .visibleBox.visibleBoxActive {
  background-color: #5300f2;
}
.multipleAnswersPossibleContainer .setToMax .visibleBox .tickIcon {
  width: 10px;
}

@media only screen and (max-width: 975px) {
  .multipleAnswersPossibleContainer .multipleAnswersPossibleControls {
    flex-wrap: wrap;
    height: auto;
  }
}
