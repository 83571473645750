.popupContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 320px;
}
.mainTitle {
  font-family: 'Open Sans Bold';
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.wordsToMerge {
  padding: 0 20px;
}
.wordsToMerge .wordsToMergeList {
  margin: 10px 0;
  max-height: 200px;
  overflow-y: scroll;
}
.wordsToMerge .wordsToMergeList::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.wordsToMerge .wordsToMergeList::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.wordsToMerge .wordsToMergeList .wordsToMergeListItem {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.wordsToMerge .wordsToMergeList .visibleBox {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
  margin-right: 10px;
}
.wordsToMerge .wordsToMergeList .visibleBox.active {
  background-color: #5300f2;
}
.wordsToMerge .wordsToMergeList .visibleBox.disabled {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.wordsToMerge .wordsToMergeList .visibleBox .visibleTick {
  width: 10px;
}
.wordsToMerge .wordsToMergeList .wordsToMergeListItemText {
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
.popupTitle {
  padding: 5px 20px 10px 20px;
}
.inputContainer {
  padding: 0 20px 15px 20px;
}
.inputContainer .inputContainerInput {
  background-color: #ffffff;
  border: 1px solid #efefef;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding: 0 10px;
  width: 250px;
  outline: none;
  border-radius: 2px;
}
.errorContainer {
  color: #fc035d;
  background-color: #fff2f7;
  padding: 10px 20px;
  max-width: 320px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.errorContainer .errorIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  float: left;
}
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.confirmIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.confirmIconDisabled {
  filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.closeIcon {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
