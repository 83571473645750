.pageContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
}

.pageHeader .header1 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
  margin-bottom: 32px;
}
.pageContent {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
.whiteContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.sections {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 32px;
  padding: 0;
}
.sections .sectionName {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.sections .sectionName.active {
  color: #000000;
}

.info {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 32px;
  padding: 16px;
  padding-bottom: 0;
}

.fieldName {
  font-size: 14px;
  color: #8b8b8d;
}

.dropText {
  font-size: 30px;
  color: #aaa;
}

.or {
  font-size: 20px;
  text-align: center;
}

.keyInput {
  height: 200px;
  width: calc(100% - 16px);
  padding: 8px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.button {
  padding: 8px;
  min-width: 200px;
  margin-bottom: 32px;
}

.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleContainer {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
}
.titleContainer .title {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.detailsContainer {
  padding: 10px 0 10px;
}
.detailContainer {
  font-size: 18px;
  color: #221f1f;
  padding: 10px 20px 0 20px;
}
.detailTitleContainer {
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.detailTitleContainer .title {
  font-size: 14px;
}
.field {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.description {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.notesField {
}
.notesTitleContainer {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.notesTitleContainer .title {
  font-size: 14px;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  transform: rotate(180deg);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.imageContainer {
  display: inline-block;
  margin: 10px;
}
.imageContainer .removeImageContainer {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.imageContainer .removeImageContainer img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.imageContainer .coverImage {
  max-height: 100px;
  max-width: 300px;
}
.errorText {
  font-size: 12px;
  margin: 2px 12px 1px;
  color: #d9534f;
}
.dropContainer {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.dropContainer .button {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.dropContainer .dropDescription {
  text-align: center;
}
.previewFilename {
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  font-size: 13px;
  width: 100px;
  text-align: center;
}

.bigButton {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
