.flow-bar-root-container {
  padding-top: 16px;
  background-color: #ffffff;
  position: fixed;
  margin-top: 0px;
  z-index: 9999;
  width: 1028px;
}
.flow-bar-container {
  margin: 32px;
  margin-top: 0px;
  display: flex;
  padding: 10px 0;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 0px;
  background-color: #fff;
  z-index: 1000;
}
.flow-bar-tab-wrapper {
  flex-grow: 1;
}
.flow-bar-tab-wrapper:hover .flow-bar-tab {
  color: rgba(149, 152, 154, 0.7);
}
.flow-bar-tab {
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #95989a;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
}
.flow-bar-tab-wrapper .flow-bar-tab-active,
.flow-bar-tab-wrapper:hover .flow-bar-tab-active {
  color: #5300f2;
}
.flow-bar-tab-wrapper:last-child .flow-bar-tab {
  border: none;
}

@media only screen and (max-width: 1035px) {
  .flow-bar-root-container {
    width: 100vw;
  }
}

@media only screen and (max-width: 750px) {
  .flow-bar-tab {
    font-size: 2vw;
  }
}
