.DraftEditor {
  cursor: text;
  padding-bottom: 40px;
  font-family: 'Roboto Light';
  font-size: 16px;
  width: 800px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}
