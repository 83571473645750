.loaderFixedContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.loaderFillContainer {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 50px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 6px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
