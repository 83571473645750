.container {
  float: right;
  position: static;
  text-transform: initial;
}
.symbol {
  background-color: #a97ff8;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  position: static;
}
