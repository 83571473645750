.parentContainer {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.mainContainer {
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.contentContainer {
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.contentContainer.contentContainerMargin {
  margin-top: 80px;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  max-width: 100%;
  text-align: center;
}
.description {
  width: 540px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.textAreaTranslation {
  white-space: pre-line;
}
.acceptInputContainer {
  margin-top: 50px;
  margin-bottom: 40px;
}
.declineTermsContainer {
  margin-top: 20px;
}
.inputContainer {
  margin-top: 30px;
}
.inputContainer .input {
  font-family: 'Roboto Light';
  display: inline-block;
  width: 420px;
  max-width: 100%;
  color: #000;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #cecece;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
}
.inputContainer .input::placeholder {
  color: #cecece;
}
.inputContainer .input:focus {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  outline: none;
}
.inputContainer .input:disabled {
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.05);
}
.inputContainer .input.errorField {
  border-color: #ec008b;
}
.inputContainer .errorContainer {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.inputContainer .errorContainer .errorIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.nextSelector {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  padding: 0 45px;
  box-sizing: border-box;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
}
.downloadAppMessage {
  font-size: 18px;
  margin-bottom: 10px;
}
.qrCode {
  width: 130px;
}
.appStoreButtons {
  display: none;
}
.appStoreButtons a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.appStoreButtons img {
  height: 45px;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  .contentContainer {
    margin-top: 60px;
  }
  .contentContainer.contentContainerMargin {
    margin-top: 80px;
  }
  .title {
    font-size: 28px;
  }
  .qrCode {
    display: none;
  }
  .appStoreButtons {
    display: block;
  }
  .appStoreButtons a {
    height: 35px;
  }
  .appStoreButtons img {
    height: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .parentContainer {
    min-height: calc(100vh - 70px);
  }
  .nextSelector {
    height: 54px;
    line-height: 54px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
}
