.blockAdditionalOptions {
  margin-bottom: 30px;
}
.descriptionTitle {
  font-size: 14px;
  color: #8b8b8d;
}
.matrixDescriptionTitle {
  margin-bottom: 5px;
}
.answerCoverImageContainer {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.leftContainer {
  width: 22px;
  height: 22px;
}
.imagePlaceholder {
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  display: block;
  font-size: 20px;
}
.answerCoverImageContainer .leftContainer .image-placeholder span {
  font-size: 26px;
  position: absolute;
  left: -2.4px;
  top: -3.5px;
  padding: 0px;
  margin: 0px;
}
.answerCoverImageContainer .leftContainer .coverImagePreview {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.rightContainer {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
}
.rightContainer .actionButton {
  width: 18px;
  height: 14px;
  top: 3px;
  left: 2px;
  position: relative;
  display: inline-block;
}
.blockAdditionalOption .answerCoverImageContainer .rightContainer .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;
  font-size: 20px;
}
.blockAdditionalOption .answerCoverImageContainer .rightContainer .action span {
  position: absolute;
  left: 2.2px;
  top: 2.25px;
  font-size: 13px;
}
.answerCoverImageContainer .rightContainer .action .fileUploadInput {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 22px;
  padding: 0px;
  margin: 0px;
  z-index: 10;
}
.block-container .blockAdditionalOptions .blockAdditionalOption:last-child {
  border-bottom: 0;
}
.inputAdd {
  margin-bottom: 20px;
  margin-top: 0px;
}
.inputAddText {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 0px;
  resize: none;
  overflow: hidden;
}
.coverImagePreview {
  width: 30px;
  height: 30px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 0px;
  cursor: pointer;
}
.addOption {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.properties-column-container {
  position: fixed;
  top: 90px;
  margin-left: 1044px;
  width: 290px;
  padding-bottom: 20px;
}
.side-container-title-item {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.side-container-content {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.side-container-content .emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.side-container-content .emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.side-container-content .emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.side-container-content .emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-top: 15px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.side-container-content .emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.side-container-content .emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.side-container-content .emoji-picker .emoji:hover {
  opacity: 0.6;
}
.side-container-action {
  padding: 15px 0 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
}
.side-container-action.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.side-container-action.disabled .icon {
  color: #8b8b8d;
}
.side-container-action .soon {
  color: #ff3018;
  margin-left: 10px;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action:hover {
  opacity: 0.7;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action .icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
  top: 3px;
  font-size: 14px;
  position: relative;
  color: #5200f1;
}
.edit-block-title {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.edit-block-container {
}
.text-editor-container {
  margin-left: 18px;
  margin-right: 18px;
  border-bottom: 1px solid #eeeeee !important;
  height: 50px;
  line-height: 50px;
}
.text-editor-action {
  display: inline-block;
  margin-right: 10px;
}
.text-editor-action-button {
  padding: 0 10px;
  filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.text-editor-action-button:hover {
  opacity: 0.3;
}
.text-editor-action.true .text-editor-action-button {
  filter: grayscale(0);
  opacity: 1;
}
.text-editor-action-button.picker-active {
  filter: grayscale(0);
  opacity: 1;
}
.side-container-properties {
  width: 255px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.side-container-properties .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 60px;
  cursor: pointer;
}
.side-container-properties .icon {
  color: #8500f0;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
}
.side-container-properties .media-upload a:link,
.side-container-properties .media-upload a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.side-container-properties .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.side-container-properties .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 48px;
  height: 20px;
  font-size: 0;
}
.side-container-properties .action.remove {
  color: red;
}

.side-container-properties-campaign-web-url-container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side-container-properties-campaign-web-url-container {
  margin-top: 10px;
  display: flex;
  flex: 1;
  width: 100%;
}
.side-container-properties-campaign-web-url {
  width: 90%;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 12px;
}
.side-container-properties-campaign-web-url-click-link {
  margin-top: 8px;
  display: inline-block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 1px 6px;
  font-size: 14px;
  font-family: 'Open Sans SemiBold';
  border: none;
  background-color: transparent;
  color: #5200f1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}
.side-container-properties-campaign-web-url-click-link:hover {
  background-color: rgba(82, 0, 241, 0.1);
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Inactive */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5200f1; /* Active */
}

input:focus + .slider {
  box-shadow: 0 0 1px #5200f1; /* Active */
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.main-container .loader-container {
  background-color: #e3e3e3;
}
.main-container .loader-container.button {
  height: 55px;
  width: 150px;
}
.main-container .loader-container.column {
  height: 400px;
}
.main-container .loader-container.item {
  height: 220px;
  margin-bottom: 50px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.campaign-builder-mini-map-container {
  position: fixed;
  width: 55px;
  top: 67px;
  right: 0px;
  border-left: 1px solid #ebe9ef;
  height: calc(100vh - 67px);
  padding-top: 20px;
}
.campaign-builder-mini-map-container .single-question-icon {
  text-align: center;
  color: #b4b2b6;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.campaign-builder-mini-map-container .single-question-icon.active {
  color: #8500f0;
}
.campaign-builder-mini-map-container .single-question-icon:hover span {
  opacity: 0.7;
}
.campaign-builder-mini-map-container
  .single-question-icon
  .single-question-popup {
  display: none;
  position: relative;
}
.campaign-builder-mini-map-container .single-question-icon .error-dot {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}
.campaign-builder-mini-map-container
  .single-question-icon:hover
  .single-question-popup {
  position: absolute;
  display: block;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  font-size: 13px;
  color: #8b8b8d;
  top: 0px;
  right: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  text-align: left;
  border-radius: 5px;
}

.campaign-builder-errors-container {
}
.campaign-builder-error {
  background-color: #f5a0a142;
  color: #b03223;
  font-size: 14px;
  line-height: 35px;
  padding-left: 20px;
}
.campaign-builder-error:last-child {
  border-bottom: 1px solid #d5aea9;
}

.blockAdditionalOption {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}

@media only screen and (max-width: 1420px) {
  .main-container {
    width: auto;
  }
  .content-header {
    width: 97vw;
    padding-left: 10px;
  }
  .content {
    padding-left: 10px;
  }
  .blocks-column-container {
    width: calc(100vw - 240px - 405px);
  }
  .properties-column-container {
    right: 70px;
  }
  .block-content .block-text .description {
    display: block;
    width: 100%;
  }
  .block-content .block-text .block {
    width: 100% !important;
  }
}

.flow-chart-node-container {
  max-width: 400px;
  overflow: auto;
  white-space: pre-wrap;
  margin: auto;
  display: inline-block;
  padding: 5px;
}

.node rect {
  stroke: #333;
  fill: #fff;
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

.svg-text-icon {
  width: 13px;
}

.svg-text-icon-question,
.svg-text-icon-bigger {
  width: 15px;
}

.svg-text-minimap-icon {
  width: 15px;
  filter: grayscale(1) opacity(0.35);
}

.single-question-icon:hover .svg-text-minimap-icon {
  opacity: 0.7;
}

.single-question-icon.active .svg-text-minimap-icon {
  width: 15px;
  filter: grayscale(0) opacity(1);
}
.randomisedIcon {
  position: absolute;
  left: -48px;
}

.questionMark:hover {
  background-color: #5301f2;
}
