.filterItemContainer {
  display: flex;
}
.filterItemContainer .filterType {
  display: flex;
}
.filterItemContainer .filterType .closeIcon {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.filterItemContainer .filterType {
  display: flex;
}
.filterItemContainer .filterType .closeIcon {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
