.container {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.container.noClick {
  cursor: default;
}
.container:first-child {
  border-top: 1px solid #dddddd;
}
.detailsContainer {
  padding-left: 10px;
}
.actionsContainer {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.actionsContainer .actionsButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.starredUser {
  display: inline-block;
  height: 14px;
  margin-right: 7px;
}
.record.isRecordHovered {
  cursor: pointer;
}
.rowContainer {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
}
.record.isRecordHovered .nameContainer {
  opacity: 0.7;
}
.nameContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 300px;
  line-height: 18px;
  position: relative;
  top: 3px;
}
.touchpointsContainer {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.touchpointNumber {
  width: 100px;
}
.barContainer {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #e7e7e8;
}
.bar {
  height: 5px;
}
.warningTooltipContainer {
  display: inline-block;
}
.warningTooltipIcon {
  display: inline-block;
  cursor: pointer;
  height: 15px;
  position: relative;
  top: 3px;
  margin-left: 10px;
}
.warningTooltipPopup {
  position: absolute;
  left: 50px;
  bottom: 4px;
  padding: 0px 20px;
  background-color: #f61553;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.warningTooltipPopupTriangle {
  position: absolute;
  left: -8px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f61555 transparent transparent;
}
.warningTooltipIcon:hover + .warningTooltipPopup {
  display: block;
}
