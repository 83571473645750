.noFeatures {
  color: #8b8b8d;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.feature {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 15px;
  justify-content: space-between;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ebe5fc;
}
.description {
  width: calc(100% - 5px);
  background-color: #ffffff;
  font-size: 13px;
  height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  outline: none;
}
.featureFields {
  display: inline-flex;
  flex-direction: column;
  width: 95%;
}
.featureFieldsDropdowns {
  display: flex;
}
.close {
  position: relative;
  top: 2px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.colorPickersContainer {
  display: flex;
  flex-wrap: wrap;
}
.colorPickersContainer .colorPicker {
  margin-bottom: 5px;
  margin-right: 6px;
  background-color: #fff;
  border-radius: 5px;
}
