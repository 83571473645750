.container {
  margin-top: 30px;
  margin-left: 20px;
}
.additionalDetailsContentContainer {
  display: inline-block;
}
.additionalDetailsContainer {
  padding: 10px 30px 20px 30px;
  font-size: 13px;
  display: flex;
  justify-content: center;
}
.averageRatingCount {
  font-family: 'Roboto Bold';
}
.switchContainer {
  text-align: right;
  margin-right: 45px;
  margin-bottom: 10px;
}
.ratingSwitch {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.ratingSlider {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  user-select: none;
}
input:checked + .ratingSlider:before {
  transform: translateX(0px) translateY(-50%);
}
.ratingSlider:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  transform: translateX(30px) translateY(-50%);
}
.ratingPercentageOn,
.ratingPercentageOff,
.ratingNumberOn,
.ratingNumberOff {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.ratingPercentageOn,
.ratingPercentageOff {
  left: 15px;
}
.ratingNumberOn,
.ratingNumberOff {
  right: 17px;
}
.ratingPercentageOff,
.ratingNumberOff {
  color: #fff;
}
.ratingPercentageOn,
.ratingNumberOn {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.input {
  display: none;
}
.disclaimer {
  font-size: 12px;
  color: #8b8b8d;
  padding: 0 30px 20px 30px;
}
.bullet {
  display: inline-block;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin-right: 6px;
  margin-bottom: 2px;
}
