.container {
  font-family: 'Roboto Regular';
  color: #6b7589;
  padding-right: 30px;
}
.desktopContainer {
  display: block;
}
.mobileContainer {
  display: none;
}
.titleLabel {
  display: inline-block;
  background-color: #f4ecfb;
  color: #6f2a7f;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-family: 'Roboto Medium';
}
.title {
  font-family: 'Montserrat SemiBold';
  font-size: 32px;
  color: #000;
}
.subTitle {
  font-family: 'Roboto Medium';
  margin-bottom: 4px;
}
.text {
  line-height: 26px;
}
.textItalic {
  font-style: italic;
}
p {
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
}
p:last-child {
  margin-block-end: 0;
}

@media only screen and (max-width: 992px) {
  .container {
    padding-right: 0;
  }
  .desktopContainer {
    display: none;
  }
  .mobileContainer.showMobileContainer {
    display: block;
  }
  .mobileContainer .title {
    margin-top: 80px;
  }
}
