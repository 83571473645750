.container {
  display: flex;
}
.inputContainer {
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
}
.inputFieldContainer {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  color: #000000;
  width: 100%;
  border: 0;
  outline: none;
}
.inputFieldContainer.inactive {
  color: #000000;
}
.inputFieldContainer::placeholder {
  color: #8b8b8d;
}
.blockAdditionalOption {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.blockAdditionalOption.inactive {
  margin-right: 0px;
}
.answerTextarea {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 73%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.answerTextareaSpan {
  margin-bottom: 10px;
  line-height: 19px;
  padding-left: 2px;
  padding-top: 2px;
}
.close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2px;
}
.close img {
  width: 20px;
  height: 20px;
}
.logicJumpAdmin {
  cursor: pointer;
  position: absolute;
  right: -60px;
}
.logicJump {
  cursor: pointer;
  position: absolute;
  right: -30px;
}
.quota {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -3px;
  font-size: 18px;
}
.logicJump svg,
.logicJumpAdmin svg,
.quota svg {
  width: 17px;
  height: 11px;
}
.allowOther {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.answerLabel {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 62px;
  resize: none;
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 2px;
  min-height: 19px;
}
.lockIcon {
  align-self: flex-start;
  padding-top: 4px;
  padding-left: 4px;
}
.lockTooltip {
  position: absolute;
  display: none;
  background-color: #a97ff8;
  right: -225px;
  top: 0px;
  font-size: 12px;
  color: #fff;
  width: 200px;
  text-align: center;
  padding: 4px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 25;
}
.lockTooltipArrow {
  width: 0;
  height: 0;
  border-top: 12.5px solid transparent;
  border-bottom: 12.5px solid transparent;
  border-right: 12.5px solid #a97ff8;
  position: absolute;
  left: -12.5px;
  top: 0px;
}
.lockIcon:hover + .lockTooltip {
  display: block;
}
.deboundeIcon {
  margin-left: 60px;
  padding-bottom: 11px;
}
.isOtherDebounce {
  width: calc(73% - 26px);
  margin-left: 10px;
}
.inactiveClose {
  cursor: pointer;
}
.inactiveClose img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
