.container {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.label {
  font-size: 13px;
  margin-right: 10px;
}
.disabled {
  opacity: 0.5;
}
