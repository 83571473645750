.resultAnswersDistributionContainer {
  max-height: 600px !important;
}
.disclaimer {
  font-size: 12px;
  color: #8b8b8d;
  margin-top: 20px;
}
.actionContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 13px;
}
.searchAnswerInputContainer {
  display: inline-block;
  margin-right: 4px;
}
.searchAnswerInputContainer .searchAnswerInput {
  min-height: 30px;
  width: 230px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.searchAnswerInputContainer .searchAnswerInput::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.searchAnswerInputContainer .closeIcon {
  position: absolute;
  right: 5px;
  line-height: 30px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.actionButton {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  user-select: none;
}
.actionButton * {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.actionButton:hover * {
  opacity: 0.7;
}
.actionButton.disabledButton {
  cursor: not-allowed;
}
.actionButton.disabledButton *,
.actionButton.disabledButton:hover * {
  filter: grayscale(1);
  opacity: 0.5;
}
.actionButton .buttonText {
  display: inline-block;
  padding-left: 8px;
}
.actionButton .flagIcon {
  width: 14px;
  height: 14px;
}
.actionButton .trashIcon {
  height: 12px;
}
.visibleBox {
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.visibleBox.visibleBoxActive {
  background-color: #5300f2;
}
.visibleBox .visibleTick {
  width: 10px;
  visibility: hidden;
}
.visibleBox.visibleBoxActive .visibleTick {
  width: 10px;
  visibility: visible;
}
.tickIcon {
  width: 14px;
  margin-right: 6px;
}
