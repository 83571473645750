.container {
  margin-bottom: 15px;
}
.title {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
.filterDropdownSelectorContainer {
}
.selectedOptionsLabel {
  display: block;
  width: 100%;
  height: 40px;
  min-height: 30px;
  line-height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  background-position: right;
  background-origin: content-box;
  background-size: 15px;
  background-repeat: no-repeat;
}
.selectedOptionsLabel.placeholder {
  color: #cbcbcb;
}
.selectedOptionsLabel.disabled {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.filterDropdownPopup {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 42px;
  width: 100%;
  border: 1px solid #dfe0e4;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.noAvailableClientAccounts {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.filterDropdownPopupItemsContainer {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.filterDropdownItem {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.filterDropdownItemLabel {
  display: inline-block;
}
