.container {
  display: flex;
  padding-bottom: 30px;
}
.quotaInputContainer {
  font-size: 11px;
  font-family: 'Open Sans Regular';
  height: 30px;
  line-height: 30px;
}
.quotaInput {
  height: 30px;
  line-height: 30px;
  width: 70px;
  padding-left: 25px;
  padding-right: 10px;
  border: 1px solid #bcbbbb;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 11px;
}

.quotaIcon {
  position: absolute;
  left: 10px;
  font-size: 13px;
}
.deleteIcon {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}
