.demographic-range-selector-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.demographic-range-selector-container .input-range__slider {
  background: #5200f1;
  border: 1px solid #5200f1;
  width: 10px;
  height: 10px;
  top: 2px;
}
.demographic-range-selector-container .input-range__label {
  font-size: 12px;
}
.demographic-range-selector-activate-button {
  font-family: 'Open Sans SemiBold';
  text-align: center;
  font-size: 13px;
  color: #5300f2;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.demographic-range-selector-container .input-range {
  position: absolute;
}
.demographic-range-selector-container .input-range__track--active {
  z-index: 1;
  background: #5300f2;
}
.demographic-range-selector-container .input-range__slider-container {
  z-index: 1;
}
.demographic-range-selector-container .input-range__label--min {
  display: none;
}
.demographic-range-selector-container .input-range__label--max {
  display: none;
}
.demographic-range-selector-container .demographic-range-selector-min {
  position: absolute;
  left: -7px;
  top: 20px;
}
.demographic-range-selector-container .demographic-range-selector-max {
  position: absolute;
  right: -7px;
  top: 20px;
}
