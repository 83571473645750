.container {
  min-height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.mainContainer {
  display: flex;
  min-height: calc(100vh - 20px);
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 110px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.invitationContent {
  display: flex;
  flex-direction: column;
}
.invitationContent div {
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-family: 'Open Sans Light';
  font-size: 35px;
}
.title span {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.invitationExplanation {
  font-family: 'Open Sans Regular';
  margin-bottom: 20px;
}
.text {
  font-size: 17px;
  color: #666666;
}
.text a:link,
.text a:visited {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  text-decoration: none;
}
.text a:hover {
  cursor: pointer;
}
.button {
  display: block;
  position: relative;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 15px;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
.mainLoadingContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
  }
  .mainContainer {
    width: 90%;
  }
}
