:root {
  --slider-circle-color: #24b2e8;
  --mc-question-answer-background-color: #e9f7fc;
  --mc-question-answer-background-color-active: #a7e0f5;
  --mc-question-image-answer-background-color-hover: #a7e0f5;
  --mc-question-image-answer-background-color-active: #24b2e8;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: local('Open Sans Regular'),
    url('./fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: local('Open Sans Bold'),
    url('./fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'),
    url('./fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'),
    url('./fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans LightItalic';
  src: local('Open Sans LightItalic'),
    url('./fonts/Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'BufflClientIcons';
  src: url('./fonts/Buffl/bufflclienticons.eot');
  src: url('./fonts/Buffl/bufflclienticons.ttf') format('truetype'),
    url('./fonts/Buffl/bufflclienticons.eot?#iefix') format('embedded-opentype'); /* IE6-IE8 */
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'),
    url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'),
    url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'),
    url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'),
    url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'),
    url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'),
    url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'),
    url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

html,
body {
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  font-family: 'Open Sans Regular';
  height: 100%;
}
div {
  margin: 0px;
  padding: 0px;
  position: relative;
}
.tooltip-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
  min-width: 150px;
  display: none;
}

@keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loader-container {
  -webkit-animation: loaderContainerAnimation 2s infinite;
  -moz-animation: loaderContainerAnimation 2s infinite;
  -o-animation: loaderContainerAnimation 2s infinite;
  animation: loaderContainerAnimation 2s infinite;
}
.no-chart-data {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #8b8b8d;
}

.DayPicker-NavButton {
  z-index: 999999999;
}
.roboto-medium-font {
  font-family: 'Roboto Medium';
}
.roboto-bold-font {
  font-family: 'Roboto Bold';
}
.roboto-light-font {
  font-family: 'Roboto Light';
}
