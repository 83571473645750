.testingBanner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: 'Roboto Light';
}
