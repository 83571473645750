.container {
  display: flex;
}
/*
.container.inGroup {
  width: 606px;
}
*/
.questionParentContainer {
}
.questionParentRandomizerContainer {
  border-radius: 5px;
}
.questionParentRandomizerContainer > div > .questionContainer.active {
  border-bottom: 1px solid #bf9dff;
}
.questionContainer {
  width: calc(100% - 5px);
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  border-left: 4px solid #ffffff;
}
.questionContainer.active {
  border-left: 4px solid #8500f0;
  border-top: none;
  opacity: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.questionContainer.questionInGroup {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0px;
}
div:first-child > div > div > .questionContainer.active {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
div:last-child > div > div > .questionContainer.active {
  border-bottom-left-radius: 0;
}
div:first-child > div > div > .questionContainer {
  border-top: none;
}
div:last-child > div > div > .questionContainer {
  border-bottom: none;
}
.blockContainer {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  padding-top: 35px;
  margin-left: 20px;
  border-radius: 5px;
}
.blockStructure {
  display: flex;
}
.blockNumber {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}
.blockIcon {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.icon {
  margin-right: 10px;
  color: #5200f1;
  font-size: 16px;
}
.icon img {
  width: 18px;
  height: 18px;
}
.blockContent {
  width: calc(90% - 70px);
}
.blockRow {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
  cursor: text;
}

.groupLine {
  width: 35px;
  background-color: #e4dafb;
  /* border-bottom: 1px solid #b5b5b8; */
}
div:first-child > div > div > .groupLine {
  border-top-right-radius: 5px;
}
div:last-child > div:last-child > div > div > .groupLine {
  border-bottom-right-radius: 5px;
}
.groupLine.groupLineActive {
  width: 32px;
  border-left: 3px solid #5300f2;
}
.groupLine .groupShuffleIcon {
  width: 20px;
  height: 10px;
  display: block;
  position: absolute;
  top: 15px;
  left: 3px;
}

.questionContainerNumber {
  position: absolute;
  left: 43px;
  top: 35px;
  color: #221f1f;
  font-size: 18px;
  z-index: 5;
}

.noQuestionPlaceholder {
  font-style: italic;
  font-size: 16px;
}
.draftOverlay {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 25;
}
