.parentContainer {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.mainContainer {
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 30px;
  color: #000;
  padding-top: 40px;
  padding-bottom: 20px;
}
.campaignName {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: pre-line;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  text-align: center;
  display: block;
}
.campaignImage {
  display: block;
  margin: 0 auto;
  max-width: 400px;
  max-height: 340px;
}
.campaignDescriptionContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  font-family: 'Roboto Light';
  text-align: center;
}
.campaignDescriptionFlex {
  flex: 3;
  text-align: center;
  font-family: 'Roboto Light';
  text-align: center;
}
.campaignDescriptionStatic {
  width: auto;
  max-width: 470px;
  margin: 0 auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.campaignDescriptionTranslation {
  font-size: inherit;
  width: 470px;
  max-width: 100%;
  text-align: center;
  display: inline-block;
  font-family: 'Roboto Light';
  text-align: center;
}
.languagesSelectorContainer {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.languageSelector {
  width: 130px;
  opacity: 0.3;
  cursor: pointer;
}
.languageSelector.selectedLanguage {
  opacity: 1;
}
.languageSelector,
.startSelector {
  margin-left: 20px;
  margin-right: 20px;
  user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
}
.languageSelector .languageSelectorMobileContent {
  display: none;
}
.startSelector {
  padding: 0 45px;
  box-sizing: border-box;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
}
.readyLaunchTitle {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  padding-top: 40px;
  padding-bottom: 20px;
  white-space: pre-line;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.readyLaunchDescription {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  font-size: inherit;
  text-align: center;
  display: block;
  font-family: 'Roboto Light';
  text-align: center;
}
.textAreaTranslation {
  white-space: pre-line;
}
.acceptInputContainer {
  margin-top: 40px;
}
.notAvailableContentContainer {
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.notAvailableTitle {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  width: 600px;
  max-width: 100%;
  margin-bottom: 40px;
  text-align: center;
}
.notAvailableDescription {
  width: 600px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.downloadAppMessage {
  font-size: 18px;
  margin-bottom: 10px;
}
.qrCode {
  width: 130px;
}
.appStoreButtons {
  display: none;
}
.appStoreButtons a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.appStoreButtons img {
  height: 45px;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  .campaignName {
    width: 90%;
  }
  .campaignDescriptionContainer {
    width: 90%;
    display: block;
  }
  .readyLaunchTitle,
  .readyLaunchDescription {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .notAvailableContentContainer {
    margin-top: 60px;
  }
  .notAvailableTitle {
    font-size: 28px;
  }
  .qrCode {
    display: none;
  }
  .appStoreButtons {
    display: block;
  }
  .appStoreButtons a {
    height: 35px;
  }
  .appStoreButtons img {
    height: 35px;
  }
}
@media only screen and (max-width: 768px) {
  .parentContainer {
    min-height: calc(100vh - 70px);
  }
  .campaignDescriptionFlex {
    padding: 0 20px;
  }
  .campaignName {
    font-size: 28px;
  }
  .campaignImage {
    max-width: 100%;
  }
  .languagesSelectorContainer {
    display: block;
  }
  .languageSelector,
  .startSelector {
    height: 54px;
    line-height: 54px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .languageSelector .languageSelectorDesktopContent {
    display: none;
  }
  .languageSelector .languageSelectorMobileContent {
    display: block;
  }
  .startSelector {
    padding: 0;
  }
  .acceptInputContainer {
    margin-bottom: 0;
  }
}
