.stackedBarContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.stackedBarContainer.additionalMarginBottom {
  margin-bottom: 80px;
}
.stackedBarContainer.noMarginBottom {
  margin-bottom: 0;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;
  font-size: 14px;
  border-right: 1px solid #fff;
  min-height: 80px;
}
.left.leftIsFiltered {
  border-color: #000;
  margin-bottom: 0;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}
.right.rightIsFiltered {
  padding-top: 20px;
}
.right.rightNoAverageScores {
  padding-top: 4px;
}
.average {
  text-align: center;
  font-size: 12px;
}
.legend {
  text-align: center;
  margin-left: auto;
}
.row {
  color: #000;
  margin-right: 20px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}
.row:last-child {
  margin-right: 0;
}
.row .rectangle {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
}
.bold {
  font-family: 'Open Sans SemiBold';
}
