.sample {
  display: inline-block;
  margin-right: 10px;
}
.bullet {
  display: inline-block;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
}
