.answerTextarea {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 73%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.answerTextarea.draftField {
  min-height: 24px;
  height: auto;
  padding: 2px 2px 6px 2px;
  cursor: text;
}
.answerTextarea.textIndent {
  text-indent: 58px;
}
.answerTextarea::placeholder {
  color: #c4c4c4;
}
.answerTextareaSpan {
  margin-bottom: 10px;
  line-height: 19px;
  padding-left: 2px;
  padding-top: 2px;
}
.answerTextarea.disabled {
  opacity: 0.3;
  cursor: default;
}

.isOtherDebounce {
  text-indent: 58px;
}
.isOtherDebounce::first-line {
  line-height: 21px;
}
.draftOverlay {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 25;
}
