.pickerContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 200px;
  overflow: hidden;
  z-index: 1;
  left: 2px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.valueContainer {
  flex: 1;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

.value {
  display: flex;
  list-style: none;
  padding: 5px 0px 5px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.active {
  background-color: aliceblue;
}

.wrapper {
  position: relative;
}
