.container {
  width: 600px;
  overflow: visible;
}
.content {
  padding: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #1b1b1b;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.invalid {
  opacity: 0.12;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 20px;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 15px;
}
.dateInputsContainer {
  display: flex;
}
.dateInputsContainer .dateInputWrapper:first-child {
  margin-right: 12px;
}
.dateInputsContainer input {
  font-family: 'Open Sans Regular';
  width: inherit;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
}
.dateInputsContainer input::placeholder {
  color: #cbcbcb;
}
.dateInputsContainer input:focus {
  outline: none;
}
.note {
  color: #8b8b8d;
  margin: 20px 0;
}
.loaderContainer {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.errorContainer {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.warningErrorIcon {
  width: 15px;
  margin-right: 10px;
}
