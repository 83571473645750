.column {
  cursor: pointer;
  line-height: initial;
}
.column:hover {
  opacity: 0.8;
}
.activeColumn {
  color: #4a25e8;
}
.arrow {
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.arrowTop {
  transform: rotate(-180deg);
}
