.container {
  display: flex;
  justify-content: center;
}
.option {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  width: 220px;
  min-height: 120px;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 15px;
  cursor: pointer;
}
.option:last-child {
  margin-right: 0;
}
.option.selected {
  background-color: #ebe6fc;
}
.answer {
}
