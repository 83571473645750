.container {
}
.goalOverviewContainer {
  display: flex;
}
.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #b5b5b5;
  min-width: 200px;
  height: 190px;
  margin-right: 25px;
}
.logo {
  display: block;
  max-width: 90%;
  max-height: 90%;
}
.detailsContainer {
  margin: auto 0;
}
.subTitle {
  font-family: 'Roboto Regular';
  color: #000;
  margin-bottom: 4px;
}
.description {
  font-size: 16px;
  line-height: 17.5px;
  margin-bottom: 15px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.valueContainer {
  margin-bottom: 15px;
}
.valueContainer .currentValue {
  color: #792483;
  font-size: 30px;
  font-family: 'Montserrat Bold';
  margin-top: 4px;
}
.valueContainer .loaderContainer {
  width: 20px;
}
.editGoalButton {
  width: 17px;
  height: 17px;
  z-index: 99;
  position: absolute;
  top: -30px;
  right: -30px;
  padding: 15px;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .logo {
    max-width: 100%;
    max-height: 100%;
  }
  .goalOverviewContainer {
    padding: 0px;
    display: block;
  }
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 240px;
    margin-right: 0;
    border-radius: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
}
