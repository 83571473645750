.rowContainer {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  max-width: 300px;
}
.firstColumn {
  padding-left: 20px;
  box-sizing: border-box;
}
.typeColumn {
  width: 200px;
}
.nameColumn {
  width: 290px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.createdAtColumn {
  width: 150px;
}
.actionsContainer {
  padding-right: 10px;
  text-align: right;
}
.actionsContainer .actionsButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
