.filterDropdownSelectorContainer {
  position: relative;
  height: 30px;
  line-height: 30px;
  max-width: 290px;
  margin-right: 10px;
}
.selectedOptionsLabel {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.selectedOptionsLabel.valueSelected {
  color: #000000;
}
.filterDropdownPopup {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.filterDropdownItem {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.allOption {
  border-bottom: 1px solid #ececec;
}
.filterDropdownItem:hover {
  background-color: #f5f5f5;
}
.filterDropdownItem.active {
  background-color: #fafafa;
}
.filterDropdownItem.selfRecord {
  border-top: 1px solid #ececec;
}
.filterDropdownItem.notSelectable {
  cursor: not-allowed;
}
.filterDropdownItem.notSelectable:hover {
  background-color: #ffffff;
}
.filterDropdownItemCheckboxSelected {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.filterDropdownItemCheckbox {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.filterDropdownItemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.filterDropdownItemLabel .level2Padding {
  display: inline-block;
  width: 20px;
  height: 5px;
}
.noAvailableClientAccounts {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.filterDropdownPopupSearchInputContainer {
  display: flex;
}
.filterDropdownPopupSearchInputContainer input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.filterDropdownPopupSearchInputContainer .inputLoaderContainer > div {
  position: relative;
  top: -10px;
}
.filterDropdownPopupItemsContainer {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  color: #000000;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.filterDropdownPopupItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.filterDropdownPopupFooterContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.filterDropdownPopupConfirm {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.addItemIcon {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}
.dropdownPicker {
  font-family: 'Open Sans Regular';
  height: 40px;
  line-height: 42px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  color: #535353;
  cursor: pointer;
  position: relative;
  bottom: 5px;
  display: flex;
  align-items: center;
}
.dropdownPicker.disabled {
  background-color: #ebebeb;
  border-color: #dcdcdc !important;
}
.dropdownPickerLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
}
.arrowDown {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  bottom: 0px;
}
