.topMarginContainer {
  position: relative;
  height: 55px;
}
.container {
  background-color: #f3f4f7;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  font-family: 'Open Sans Regular';
  width: 100vw;
  font-size: 11px;
  border-radius: 5px;
}
.testingContainer {
  top: 35px;
  padding: 5px 10px;
}
.contentContainer {
  display: flex;
  width: 1100px;
  max-width: 90%;
  margin: 0 auto;
}
.description {
  font-size: 12px;
  line-height: 18px;
}
.description a:link,
.description a:visited {
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
}
.acceptButtonsContainer {
  width: 155px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acceptButtonsContainer .acceptButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Open Sans Bold', Arial;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 20px;
  line-height: 20px;
}
.rejectButtonsContainer {
  width: 180px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rejectButtonsContainer .rejectButton {
  display: inline-block;
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    background-color: #f3f4f7;
    padding: 30px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    font-family: 'Open Sans Regular';
    width: calc(100vw - 60px);
    font-size: 13px;
    border-radius: 5px;
    height: fit-content;
  }
  .topMarginContainer {
    height: 300px;
  }
  .description {
    margin-bottom: 20px;
  }
  .contentContainer {
    display: block;
    width: auto;
    max-width: auto;
    margin: 0 auto;
  }
  .acceptButtonsContainer {
    width: auto;
    margin-bottom: 20px;
  }
  .acceptButtonsContainer .acceptButton {
    width: 120px;
  }
  .rejectButtonsContainer {
    width: auto;
    margin-bottom: 20px;
  }
}
