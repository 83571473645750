.container {
  top: 0px;
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
  display: flex;
  border: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 4px solid #8500f0;
  border-bottom: 1px solid #bf9dff;
}
.container.group {
  border: 1px solid #bf9dff;
  border-left: 4px solid #8500f0;
  border-top: 0;
  margin-top: -3px;
}
.container.lastGroupQuestion {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.containerActiveFlow {
  top: -2px;
}
.container.parentGroupJumpContainer {
  margin-left: -30px;
  width: calc(100% + 60px);
  top: -44px;
  margin-bottom: -44px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.groupBorder {
  width: 30px;
  border-left: 3px solid #5300f2;
  border-bottom-right-radius: 5px;
  background-color: #e4dafb;
}
.title {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8b8b8d;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  padding-left: 15px;
  padding-right: 15px;
}
.title .branchIcon {
  background-color: #8500f0;
  position: absolute;
  top: 25px;
  left: -25px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title .branchIcon svg {
  width: 17px;
  height: 11px;
}
.dropdownRow {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex: 1;
  min-width: 0;
}
.parentGroupJumpContainer .dropdownRow,
.container:last-child .dropdownRow {
  border: none;
}
.actionsContainer {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.actionsContainer .delete {
  color: #fc2270;
  cursor: pointer;
}
.actionsContainer .delete img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.actionsContainer .confirm {
  cursor: pointer;
}
.actionsContainer .confirm img {
  width: 18px;
  height: 11px;
}

.additionalJumpInformation {
  background-color: #e4d9fd;
  padding: 10px;
}
.additionalJumpInformation .addJumpButton {
  color: #5300f2;
  font-family: 'Open Sans Bold';
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
}
.ljArrow {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.dummyLeftVerticalLine {
  height: 50px;
  width: 1px;
  background-color: #b593f6;
}
.containerRow {
  width: 100%;
}
.additionalRow {
  border-top: 1px solid #cfcfcf !important;
  padding: 4px 15px;
}
.secondsInput {
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 30px;
}
