.container {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 644px;
  top: -30px;
}
.nestedContainer {
  margin-top: 40px;
  margin-bottom: 40px;
  top: -30px;
}

.container:last-child {
  margin-bottom: 110px;
}
.titleContainer {
  display: flex;
  height: 30px;
}
.titleContainer .title {
  font-family: 'Open Sans SemiBold';
  background-color: #bf9dff;
  height: 30px;
  line-height: 30px;
  left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}
.draggableBackgroundDragging {
  background-color: #f2ebff;
}

.wrapper {
  padding: 30px;
  padding-bottom: 0;
  border: 1px solid #bf9dff;
  border-radius: 5px;
  background-color: rgba(191, 157, 255, 0.2);
}

.wrapper *:last-child {
  margin-bottom: 0;
}

.dropZoneContainer {
  position: absolute;
  width: 100%;
  height: 50px;
  transition: margin-bottom 0.3s ease;
  overflow: visible;
}

.wrapper .dropZoneTop {
  width: calc(100% - 60px);
}

.dropZoneBottom {
  margin-top: 4px;
}

.dropZoneTop {
  top: 4px;
}

.dropZonePlaceholder {
  transition: height 0.3s ease;
  background-color: #f2ebff;
}
.wrapper .dropZonePlaceholder {
  background-color: #dfd4f5;
}

.dropZonePlaceholderActive {
  height: 50px;
}
.dropZoneBottom .dropZonePlaceholderActive {
  margin-top: 25px;
}

.dropdownPlaceholderContainer {
  width: 644px;
  padding-bottom: 40px;
  padding-top: 70px;
}
.dropdownPlaceholder {
  background-color: #f9f5ff;
  color: #ceb7fc;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.questionSet {
  background-color: #f1e9ff;
  border: 1px solid #bf9dff;
  border-radius: 5px;
}
.questionSet.setInGroup {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.questionSet.dragging {
  background-color: #f1e9ff;
}

@media only screen and (max-width: 1035px) {
  .container {
    width: calc(100vw - 377px);
  }
  .dropdownPlaceholderContainer {
    width: calc(100vw - 377px);
  }
}
