.container {
  color: #000000;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 20px;
}
.noDataAvailable {
  margin-top: 40px;
  color: #8c8c8c;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
}
