.container {
}
.container :global .tick line {
  visibility: hidden;
}
.container :global .tick text {
  font-family: 'Open Sans Regular';
}
.container :global path.domain {
  stroke: #b5b5b8;
}
