.container :global .input-range__slider {
  background-color: #4a25e8;
  border: 0;
  width: 12px;
  height: 12px;
  margin-top: -8px;
}
.container :global .input-range__track--active {
  background-color: #4a25e8;
}
.container :global .input-range__track {
  height: 3px;
}
.container :global .input-range__label--min,
.container :global .input-range__label--max {
  display: none;
}
