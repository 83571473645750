.addInsightButton {
  display: inline-block;
  line-height: 30px;
  padding: 0 10px 0 8px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.backgroundContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
}
.popupContainer {
  position: fixed;
  width: 660px;
  left: calc(50% - 330px);
  top: 150px;
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: visible;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.popupContent {
  width: 600px;
  max-height: 600px;
  overflow: visible;
  padding-left: 30px;
  padding-right: 30px;
}

.title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
}
.filtersContainer :global(.filters-bar-container) {
  padding: 0;
}
.filtersContainer :global(.single-active-filte)r {
  padding-right: 10px !important;
}
.filtersContainer :global(.single-active-filter-filters-relation) {
  position: relative;
  top: 10px;
  font-family: 'Roboto Bold';
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
}
.filtersContainer :global(.insight-nested-active-filter-filters-relation) {
  font-family: 'Roboto Bold';
  font-size: 10px;
  margin-left: 3px;
  margin-right: 3px;
  color: #707070;
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 5px;
}
.filtersBarWrapper {
  display: flex;
}
.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
  margin-top: 20px;
}
.footer .details {
  color: #5200f1;
}
.footer .footerErrorBackground {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.footer .error {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.footer .error .warningIcon {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.disabledConfirm {
  opacity: 0.2;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.inputElement {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 13px;
  width: calc(100% - 12px);
}
.inputElement:focus {
  outline: none;
}
.fieldsContainer input::placeholder {
  color: #8f8e8e;
  opacity: 1; /* Firefox */
}
.fieldsContainer input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8f8e8e;
}
.baseQuestionContainer {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
}
.baseQuestionContainer img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.baseQuestionContainerNumber {
  padding-right: 2px;
}
.loaderContainer {
  display: inline-block;
  bottom: 13px;
  right: -11px;
}
.scopingDescription {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 13px;
  width: calc(100% - 12px);
  background-color: #f9f8f8;
  color: #8b8b8d;
}
.dataPointsContainer {
  height: 135px;
  left: -30px;
  width: calc(100% + 60px);
  overflow-y: scroll;
}
.dataPointsContainer::-webkit-scrollbar {
  width: 7px;
}
.dataPointsContainer::-webkit-scrollbar-track {
  background: #a5a5a5;
  border-radius: 10px;
}
.dataPointsContainer::-webkit-scrollbar-thumb {
  background: rgba(4, 4, 4, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.singleDataPointsContainer {
  background-color: #ebe5fc;
  padding-left: 30px;
  padding-right: 45px;
}
.addFeatureButton {
  display: inline-flex;
  margin-top: 15px;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.addIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.draggingIcon {
  position: absolute;
  width: 18px;
  height: 10px;
  left: 50%;
  margin-left: -9px;
  top: 10px;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 20px;
}
