.chartContainer {
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 40px;
}
.switchParentContainer {
  display: flex;
  justify-content: flex-end;
}
.switchContainer {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
  top: -60px;
}
.ratingSwitch {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.ratingSlider {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  user-select: none;
}
input:checked + .ratingSlider:before {
  transform: translateX(0px) translateY(-50%);
}
.ratingSlider:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  transform: translateX(30px) translateY(-50%);
}
.ratingPercentageOn,
.ratingPercentageOff,
.ratingNumberOn,
.ratingNumberOff {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.ratingPercentageOn,
.ratingPercentageOff {
  left: 15px;
}
.ratingNumberOn,
.ratingNumberOff {
  right: 17px;
}
.ratingPercentageOff,
.ratingNumberOff {
  color: #fff;
}
.ratingPercentageOn,
.ratingNumberOn {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.input {
  display: none;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
