.container {
  position: fixed;
  bottom: 10px;
  width: 400px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ffbfd7;
  border-radius: 2px;
  padding: 2px 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  font-family: 'Roboto Light';
  background-color: #fff;
  color: #fc035d;
  background-color: #ffd6e5;
  text-align: center;
}
