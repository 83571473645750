.fieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.fieldValues {
  display: inline-flex;
  align-items: center;
}
.input {
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin: 0 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}
.logicJumpError {
  display: inline-flex;
  align-items: center;
}
.warningIcon {
  width: 20px;
}
.logicErrorMessage {
  font-weight: bold;
  font-size: 11px;
  color: #fc035d;
  margin-left: 8px;
}
.close {
  cursor: pointer;
}
.close img {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}
.label {
  width: 147px;
}
