.container {
  width: 800px;
  height: 500px;
}
.textContainer {
  overflow-y: auto;
  height: 450px;
  margin: 50px;
  margin-right: 0;
  padding-right: 40px;
}

/* Works on Firefox */
.textContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.textContainer::-webkit-scrollbar {
  width: 7px;
}

.textContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.textContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.closePopupButton {
  position: absolute;
  width: 22px;
  height: 22px;
  top: -45px;
  right: 0px;
  cursor: pointer;
}
.subtitle {
  color: #4a25e8;
  margin-bottom: 15px;
}
.text {
  margin-bottom: 15px;
}
.text .bold {
  font-family: 'Open Sans SemiBold';
}
.text a:link,
.text a:visited,
.text .purple {
  color: #4a25e8;
  cursor: pointer;
  text-decoration: none;
}
.canvasImage {
  width: 250px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
