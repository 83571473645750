.singleProperty {
  padding: 10px 0;
  margin: 0 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}
.singleProperty:last-child {
  border-bottom: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
.switch :global .slider:before {
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
}
