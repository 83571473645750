.featuresContainer {
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px;
}
.featuresContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.featuresContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.noFeatures {
  color: #8b8b8d;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.addFeatureButton {
  display: inline-flex;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  user-select: none;
  margin-left: 15px;
}
.addFeatureButtonDisabled {
  filter: grayscale(1);
  opacity: 0.5;
  cursor: default;
}
.addIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
