.title {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
}
.description {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
}
.panel {
  width: 620px;
  padding: 20px 40px 40px 40px;
  margin-top: 30px;
  margin-bottom: 80px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.01);
  -moz-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.01);
  box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
}
.button {
  background-color: #4926e9;
  height: 50px;
  line-height: 50px;
  width: 200px;
  color: #ffffff;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.login {
  margin-top: 30px;
  text-align: center;
  color: #4926e9;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 30px;
}
.register:hover {
  text-decoration: underline;
}
.inputLabel {
  margin-top: 20px;
  margin-bottom: 5px;
}
.input {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
}
.textarea {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 22px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding: 10px 20px;
  background-color: #ffffff;
  outline: none;
  height: 120px;
  font-size: 14px;
}
.file {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
}
.logoName {
  position: absolute;
  right: 15px;
  top: 12px;
  color: #221f1f;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 430px;
}
.error {
  color: #ff3c00;
  font-size: 13px;
  float: right;
}
.imageContainer {
  display: flex;
}
.imageThumbnail {
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #8f8f90;
  margin-right: 15px;
}
@media only screen and (max-width: 1024px) {
  .logoName {
    display: none;
  }
}
