.meduaUpload {
  position: absolute;
  top: -3px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 15;
}
:global .ReactModalPortal {
  z-index: 100000;
}
.meduaUpload :global .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px !important;
}
.meduaUpload :global .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.meduaUpload :global .video-file-preview img {
  left: 9px;
  top: 7px;
  position: absolute;
}
.meduaUpload :global .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}
