.container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ececec;
  color: #000000;
  justify-content: space-between;
  padding-bottom: 10px;
  cursor: default;
}
.addPathIcon {
  width: 15px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}
