.container {
  margin-bottom: 15px;
}
.container input {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
}
.title {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.requiredAsterisk {
  margin-left: 0;
  color: #be0000;
}
