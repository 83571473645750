.container {
  width: 795px;
}
.textContainer {
  padding: 20px;
}

/* Works on Firefox */
.textContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.textContainer::-webkit-scrollbar {
  width: 7px;
}

.textContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.textContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 20px;
}
.text {
  margin-bottom: 15px;
}
.text .bold {
  font-family: 'Open Sans SemiBold';
}
.text a:link,
.text a:visited {
  color: #4a25e8;
  text-decoration: none;
}
.inputWrapper {
  margin-bottom: 10px;
}
.inputWrapper textarea {
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  width: calc(100% - 40px);
  height: 130px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
}
.footerContainer {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
