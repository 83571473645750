.container {
  text-align: center;
  width: 100%;
}
.title {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  margin-bottom: 20px;
}
.buttonsContainer {
  margin-bottom: 20px;
}
.socialButton {
  border: 2px solid #e4e7ec;
  border-radius: 4px;
  max-width: 80%;
  margin: 4px auto;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  font-size: 14px;
}
.socialButton:hover * {
  opacity: 0.7;
}
.socialButton.loading {
  animation: loading-animation 1s ease infinite;
}
.socialButton .facebookIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.socialButton .googleIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.socialButton .appleIcon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.proceedWithEmail {
  font-family: 'Roboto Regular';
  color: #6b7589;
  cursor: pointer;
}
.proceedWithEmail:hover {
  opacity: 0.7;
}
.proceedWithEmail .proceedWithEmailBold {
  font-family: 'Roboto Medium';
}
.error {
  font-size: 13px;
  color: #ec008b;
  margin-bottom: 4px;
}

.backgroundBlur {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: #fff;
  opacity: 0.5;
}
.popup {
  z-index: 99;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background-color: #fff;
  width: 520px;
  max-width: 90%;
  box-sizing: border-box;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  box-shadow: 0 15px 34px #d4d4d433, 0 61px 61px #d4d4d433,
    0 137px 82px #d4d4d433, 0 244px 98px #d4d4d403, 0 381px 107px #d4d4d400;
  font-size: 14px;
}
.popup .closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
.popup .closeIcon:hover {
  opacity: 0.7;
}
.popup .popupDescription {
  text-align: left;
}
.popup .acceptInputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.popup .acceptInputContainer .checkmark {
  width: 17px;
  min-width: 17px;
  height: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: #7b7b7b;
}
.popup .acceptInputContainer .checkmark.checkmarkActive {
  background-color: #6f2a7f;
  border-color: #6f2a7f;
}
.popup .acceptInputContainer .tick {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.popup .acceptInputContainer .termsText {
  text-align: left;
  font-size: 14px;
}
.popup .acceptInputContainer .termsText .termsLink {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}
.popup .confirmButton {
  display: inline-block;
  background-color: #6f2a7f;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Montserrat SemiBold';
  margin-top: 40px;
  padding: 15px 30px;
}
.popup .confirmButton.confirmButtonDisabled {
  background-color: #7b7b7b;
  cursor: auto;
}

@keyframes loading-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 992px) {
  .title {
    display: none;
  }
  .buttonsContainer {
    margin-top: 24px;
  }
  .socialButton {
    border: 2px solid #7c7c7c;
    font-family: 'Roboto Medium';
  }
  .popup {
    padding: 20px;
  }
  .popup .closeIcon {
    top: 5px;
    right: 5px;
  }
}
