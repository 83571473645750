.filters-bar-container {
  width: 1010px;
  z-index: 100;
  justify-content: left;
  padding: 5px 0 10px 20px;
  display: flex;
  flex-wrap: wrap;
}
.filters-bar-container .active-filters-title {
  color: #221f1f;
  font-family: 'Open Sans Bold';
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-right: 15px;
}
.filters-bar-container .reset-all-filters {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
}
.filters-bar-container .reset-all-filters:hover {
  opacity: 0.6;
}
.filters-bar-container .active-filter-row {
  display: flex;
  flex-wrap: wrap;
}
.filters-bar-container .single-active-filter {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 12px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  overflow: hidden;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.filters-bar-container .single-active-filter.nested-active-filter {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  height: auto;
}
.filters-bar-container [class~='single-active-filter']:last-of-type {
  margin-right: 15px;
}
.filters-bar-container .single-active-filter:hover {
  background-color: rgba(83, 0, 242, 0.05);
}
.filters-bar-container .single-active-filter.single-active-filter-warning {
  background-color: rgba(252, 3, 93, 0.05);
}
.filters-bar-container .single-active-filter .single-active-filter-label {
  display: inline-flex;
  max-width: 380px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-extreme-user-filter-name {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-filter-label-parentheses {
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-filter-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  height: auto;
}
.filters-bar-container .single-active-filter-relations {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #707070;
  border: 1px solid #d3d3d3;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 70px;
  box-sizing: border-box;
  user-select: none;
}
.filters-bar-container
  .single-active-filter-relations.single-active-filter-relations-active {
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}
.filters-bar-container
  .single-active-filter-relations
  .single-active-filter-relations-dropdown {
  width: 70px;
  position: absolute;
  top: 29px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 5px 5px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
}
.filters-bar-container
  .single-active-filter-relations
  .single-active-filter-relations-dropdown-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  transform: translateY(5px);
  filter: grayscale(1);
  opacity: 0.5;
}
.filters-bar-container
  .single-active-filter
  .nested-active-filter-filters-relation {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #707070;
}
.filters-bar-container .single-active-filter .remove-single-active-filter {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 9px;
  height: 30px !important;
  line-height: 30px !important;
  padding-left: 10px;
  padding-right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.filters-bar-container
  .single-active-filter:hover
  .remove-single-active-filter {
  visibility: visible;
  opacity: 1;
}
.filters-bar-container .mutually-exclusive-filters-warning {
  color: #fc035d;
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid #d3d3d3;
  height: 30px;
  max-width: 150px;
}
.filters-bar-container
  .mutually-exclusive-filters-warning
  .filters-warning-icon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  float: left;
}

@media only screen and (max-width: 1395px) {
  .filters-bar-container {
    width: calc(100vw - 417px);
  }
}

@media only screen and (max-width: 768px) {
  .filters-bar-container {
    width: 100%;
  }
  .blocks-results-column-container .blocks-results-header-content {
    position: relative;
    width: 100%;
    top: 0px;
    height: auto;
    display: block;
  }
}
