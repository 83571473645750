.mcContainer {
  display: flex;
  position: relative;
  bottom: -30px;
  left: -119px;
  margin-right: -157px;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
  font-size: 13px;
}
.sliderContainer {
  display: flex;
  position: relative;
  bottom: 0px;
  left: -119px;
  margin-right: -157px;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
  font-size: 13px;
}
.iconContainer {
  display: inline-block;
  margin-left: 25px;
  width: 30px;
  font-size: 17px;
  color: #333333;
}
.iconContainer img {
  height: 16px;
}
.filterDropdownSelectorContainer {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 70px;
  margin-right: 10px;
  margin-left: 10px;
  top: 6px;
}
.filterDropdownSelectorContainerWide {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 130px;
  margin-right: 10px;
  margin-left: 10px;
  top: 6px;
}
