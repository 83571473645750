.descriptionTitle {
  font-size: 14px;
  color: #8b8b8d;
}
.description {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.sliderDescription {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}
