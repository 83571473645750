.container {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  background-color: #ffffff;
}
.textareaElement textarea {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  resize: none;
  width: calc(100% - 12px);
  border: 0;
}
.draftContainer {
  min-height: 50px;
}
.textareaElement:focus {
  outline: none;
}
.container :global(.emoji-picker) {
  z-index: 999;
}
