.container {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.content {
  padding: 50px;
  padding-bottom: 20px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.invalid {
  opacity: 0.12;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  box-shadow: -15px 10px 15px #fff;
  z-index: 1;
}
.subtitle {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.input {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.input::placeholder {
  color: #cbcbcb;
}
.input:focus {
  outline: none;
}
.copyLinkButton {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.copyLinkButton .linkIcon {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.dropdownContainer {
  margin-bottom: 10px;
}
.invitationLinkContainer {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.invitationLinkContainer input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.invitationLinkContainer .copyInputLink {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.invitationLinkContainer .copiedInputLink {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.loaderContainer {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.dropdownLicenceTopContainer {
  display: flex;
}
.dropdownLicenceContainer {
  width: 245px;
  margin-right: 10px;
}
.dropdownLicenceContainer:last-child {
  margin-right: 0;
}
.additionalOptionCheck {
  font-size: 12px;
  margin-bottom: 20px;
}
.additionalOptionCheck input {
  width: 11px;
  height: 11px;
  margin-right: 7px;
  cursor: pointer;
}
.creditsLeftTopContainer {
  display: flex;
  margin-bottom: 10px;
}
.creditsLeftContainer {
  margin-right: 30px;
}
.creditsLeftContainer:last-child {
  margin-right: 0;
}
.creditsInput {
  font-family: 'Open Sans Regular';
  width: 50px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.errorContainer {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.warningErrorIcon {
  width: 15px;
  margin-right: 10px;
}
.contentContainer {
  max-height: 400px;
  overflow-x: visible;
  overflow-y: auto;
}
.contentContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.contentContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
