.container {
  width: 795px;
  margin-right: 5px;
}
.textContainer {
  overflow-y: auto;
  max-height: 480px;
  margin: 30px;
  margin-right: 0;
  padding-right: 30px;
}

/* Works on Firefox */
.textContainer {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.textContainer::-webkit-scrollbar {
  width: 7px;
}

.textContainer::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.textContainer::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 20px;
}
.text {
  margin-bottom: 15px;
}
.text a:link,
.text a:visited {
  color: #4a25e8;
  text-decoration: none;
}
.text.purple {
  color: #4a25e8;
}
.inputContainer {
  margin-bottom: 10px;
}
.inputContainer .label {
  margin-bottom: 10px;
}
.inputContainer textarea {
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  width: calc(100% - 40px);
  height: 130px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
