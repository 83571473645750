.container {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.rightContainer {
  display: flex;
}
.searchContainer {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.searchInputContainer {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  flex-grow: 1;
}
.searchInputContainer .questionContainingInput {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 13px;
  width: 260px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.searchInputContainer .close {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #777777;
}
.searchIcon {
  cursor: pointer;
  height: 20px;
}
.buttonMarginWrapper {
  display: inline-block;
  margin: 0 15px;
}
.buttonMarginWrapper ~ .buttonMarginWrapper {
  margin-left: 0;
}
