.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-left: 50px;
  margin-right: 50px;
  min-width: 150px;
}
.stepContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
}
.headerText {
  font-style: italic;
  text-align: center;
  font-weight: bold;
  height: 35px;
}
.stepProgressline {
  position: absolute;
  width: 250px;
  top: 48%;
  right: 50%;
  z-index: 1;
  /* border-top: 2px dashed #4926e9; */
}
.stepOuterCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 2;
}
.stepInnerCircle {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 81%;
  /* border: 9px solid #a184f1;
  background-color: #4926e9; */
  opacity: 1;
  z-index: 1;
}
.footerText {
  font-style: italic;
  font-size: 10px;
  text-align: center;
}
.author {
  color: #9c9696;
  font-size: 10px;
  margin-top: 5px;
}
.button {
  width: 71%;
  height: 35px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  margin-top: 25px;
  border: 0;
}
.enabledButton {
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.disabledButton {
  cursor: default;
  color: rgb(149, 151, 153);
}
.invisibleButton {
  pointer-events: none;
}
.cancelButton {
  background-color: #fc025d;
}
.scheduleButton {
  background-color: #4926e9;
}
.popupContainer {
  display: flex;
  flex-direction: column;
}
.schedulerContainer {
  display: flex;
  width: 45vh;
  height: 18vh;
}
.popupButtonsContainer {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 20px);
  left: -10px;
  border-top: 1px solid #e6e6e6;
}
.popupButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 40px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  border: 0;
  margin: 15px 15px 5px 15px;
  cursor: pointer;
}
.popupLeftButton {
  color: #fc025d;
}
.popupRightButton {
  color: #ffffff;
  border-radius: 5px;
  background-color: #4926e9;
}
.buttonsContainer {
  display: flex;
}
