.container {
  width: 700px;
  max-height: 500px;
}
.title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 15px;
  height: 30px;
  line-height: 24px;
  border-bottom: 1px solid #eeeeee;
}
.message {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #8a898c;
}
.button {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
