.resultsAnswerPercentageRange {
  color: #ad8dea;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  cursor: default;
  position: absolute;
  width: 200px;
}
.resultsAnswerBarContainer {
  overflow: visible !important;
  cursor: default !important;
}
.resultsAnswerCentralValueBar {
  height: 17px;
  background-color: #5200f1;
  border-radius: 10px;
  cursor: default;
  position: absolute;
  z-index: 99;
  top: -2px;
}
.resultsAnswerRangeBar {
  height: 11px;
  background-color: #d2c4ef;
  border-radius: 10px;
  cursor: default;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
