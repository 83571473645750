.login {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.blueLink {
  color: #4926e9;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.register:hover {
  text-decoration: underline;
}
.file {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
}
.logoName {
  position: absolute;
  left: 100px;
  top: 12px;
  color: #221f1f;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 400px;
}

.mainContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.mainContentContainer {
  width: 100%;
  max-width: calc(100% - 550px);
  min-height: calc(100vh - 120px);
}
.mainContentContainer .stepsProgressContainer {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 300px;
}
.mainContentContainer .stepsProgressContainer .stepsProgressContainerLine {
  position: absolute;
  top: 21px;
  left: calc((100vw - 100%) / -2);
  width: calc((100vw - 100%) / 2 + 100%);
  height: 1px;
  border-top: 3px dotted #a184f1;
}
.mainContentContainer .stepsProgressContainer .stepContainer {
  z-index: 10000;
  padding-left: 70px;
  padding-right: 70px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive {
  background-color: rgba(255, 255, 255, 0);
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive {
  margin-left: 70px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 70px;
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  + .stepContainer {
  margin-left: 70px;
  padding-left: 0;
  padding-right: 70px;
  margin-right: 0;
}
.mainContentContainer .stepsProgressContainer .stepContainer:last-of-type,
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  + .stepContainer:last-of-type {
  padding-right: calc(100% - (44px * 3) - (70px * 5));
}
.mainContentContainer .stepsProgressContainer .stepContainer .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 12px solid #e2dafa;
  background-color: #c8bdf8;
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  .circle {
  border-color: #a184f1;
  background-color: #4926e9;
}
.mainContentContainer .stepsProgressContainer .stepContainer .stepLabel {
  font-size: 12px;
  margin-top: 15px;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  left: calc(50% - ((100% - 44px) / 2) + 70px);
  transform: translateX(-50%);
  opacity: 0.3;
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  .stepLabel {
  left: 50%;
  opacity: 1;
}
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  + .stepContainer
  .stepLabel {
  left: calc(50% - 35px);
}
.mainContentContainer
  .stepsProgressContainer
  + .stepContainer:last-of-type
  .stepLabel,
.mainContentContainer
  .stepsProgressContainer
  .stepContainer.stepContainerActive
  + .stepContainer:last-of-type
  .stepLabel {
  left: calc(50% - ((100% - 44px) / 2));
}
.form {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
  min-height: 505px;
}
.title {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.input {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.inputSavingsTarget {
  padding-left: 30px;
}
.euroSign {
  position: absolute;
  left: 15px;
  margin-top: 8px;
  font-size: 14px;
}
.textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #7c7c7c;
  padding: 10px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  height: 100px;
}
.textarea.textareaCharacters {
  height: 120px;
}
.textareaCharactersLength {
  position: absolute;
  font-family: 'Open Sans Light';
  font-size: 14px;
  top: 120px;
  right: 9px;
}
.logoTextarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #7c7c7c;
  font-size: 14px;
  color: #d3d3d3;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.logoTextarea .uploadIcon {
  width: 20px;
  height: 18px;
  margin-bottom: 5px;
}
.logoTextarea .fileName {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
}
.button {
  display: block;
  text-align: center;
  background-color: #4926e9;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 15px;
  text-transform: uppercase;
  width: 100%;
}
.button:hover {
  opacity: 0.7;
}
.error {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.bottomBlobWrapper {
  position: absolute;
  width: 100vw;
  height: 100%;
  bottom: 0px;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.bottomBlob {
  position: absolute;
  bottom: -900px;
  width: 1546px;
  height: 1236px;
  left: -540px;
}
.herdyPickingApples {
  position: absolute;
  width: 1004px;
  height: 677px;
  width: 628px;
  height: 424px;
  bottom: -70px;
  left: 180px;
}
.herdyCollectingApples {
  position: absolute;
  width: 1014px;
  height: 677px;
  width: 634px;
  height: 424px;
  bottom: -70px;
  left: 180px;
}

@media only screen and (max-width: 1024px) {
  .mainContainer {
    flex-direction: column;
    box-sizing: border-box;
  }
  .mainContentContainer {
    min-height: auto;
    width: 100%;
    max-width: 100%;
  }
  .mainContentContainer .stepsProgressContainer {
    margin-bottom: 0;
    justify-content: space-around;
  }
  .mainContentContainer .stepsProgressContainer .stepsProgressContainerLine {
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    top: 15px;
  }
  .mainContentContainer .stepsProgressContainer .stepContainer {
    padding-left: 0;
    padding-right: 0;
  }
  .mainContentContainer
    .stepsProgressContainer
    .stepContainer.stepContainerActive {
    margin-left: 0;
    margin-right: 0;
  }
  .mainContentContainer .stepsProgressContainer:first-child .stepContainer {
    margin-left: 0;
  }
  .mainContentContainer
    .stepsProgressContainer
    .stepContainer.stepContainerActive
    + .stepContainer {
    margin-left: 0;
    padding-right: 0;
  }
  .mainContentContainer .stepsProgressContainer .stepContainer:last-of-type,
  .mainContentContainer
    .stepsProgressContainer
    .stepContainer.stepContainerActive
    + .stepContainer:last-of-type {
    padding-right: calc(100% - (24px * 3) - (35px * 4));
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .mainContentContainer .stepsProgressContainer .stepContainer .circle {
    width: 16px;
    height: 16px;
    border: 8px solid #e2dafa;
  }
  .mainContentContainer .stepsProgressContainer .stepContainer .stepLabel {
    left: 50%;
  }
  .mainContentContainer
    .stepsProgressContainer
    .stepContainer.stepContainerActive
    + .stepContainer
    .stepLabel {
    left: 50%;
  }
  .mainContentContainer
    .stepsProgressContainer
    + .stepContainer:last-of-type
    .stepLabel,
  .mainContentContainer
    .stepsProgressContainer
    .stepContainer.stepContainerActive
    + .stepContainer:last-of-type
    .stepLabel {
    left: 50%;
  }
  .form {
    position: relative;
    width: 100%;
    margin-right: 0;
    min-height: auto;
    margin-bottom: 0;
  }
  .bottomBlobWrapper {
    position: relative;
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    height: 242px;
  }
  .herdyPickingApples {
    width: 359px;
    width: 100%;
    height: 260px;
    left: 0;
    bottom: -50px;
  }
  .herdyCollectingApples {
    width: 362px;
    width: 100%;
    height: 260px;
    left: 0;
    bottom: -50px;
  }
}
