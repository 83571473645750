.wrapper {
  margin-left: auto;
  width: 100%;
  max-width: 525px;
  box-sizing: border-box;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  box-shadow: 0 15px 34px #d4d4d433, 0 61px 61px #d4d4d433,
    0 137px 82px #d4d4d433, 0 244px 98px #d4d4d403, 0 381px 107px #d4d4d400;
}
.contentContainer {
  padding: 70px;
  background-color: #fff;
  min-height: 700px;
  box-sizing: border-box;
  border-radius: 10px;
}
.centralizeContent {
  display: flex;
  align-items: center;
}
.gridLeft {
  z-index: 0;
  position: absolute;
  top: 0;
  left: -19%;
}
.gridRight {
  position: absolute;
  bottom: 0;
  right: -10%;
}
.contact-us-grid-left {
  z-index: 0;
  position: absolute;
  top: 0;
  left: -15%;
}
.w-embed:before,
.w-embed:after {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
svg:not(:root) {
  overflow: hidden;
}
.w-embed:after {
  clear: both;
}
.contact-us-grid-right {
  position: absolute;
  bottom: 0%;
  right: -8%;
}

.backButton {
  font-size: 30px;
  color: #cdcdcd;
  position: absolute;
  top: 8px;
  left: 12px;
  cursor: pointer;
  padding: 0 10px;
}
.backButton:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 1200px) {
  .gridLeft {
    z-index: 0;
    position: absolute;
    top: 0;
    left: -15%;
  }
  .gridRight {
    position: absolute;
    bottom: 0;
    right: -8%;
  }
}

@media only screen and (max-width: 992px) {
  .wrapper {
    margin-left: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
  }
  .contentContainer {
    padding: 0;
    min-height: auto;
    border: none;
  }
  .gridLeft,
  .gridRight {
    display: none;
  }
  .backButton {
    position: relative;
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    left: 0;
    top: 0;
  }
}
