.detailsContainer {
  padding: 20px;
  border-bottom: 1px solid #d5d5d5;
}
.closeIconContainer {
  font-size: 17px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #8f8e8e;
  cursor: pointer;
  z-index: 9;
}
.sectionTitle {
  font-size: 12px;
  color: #2b333f;
  margin-top: 30px;
}
.sectionDetails {
  font-size: 14px;
}
.footerContainer {
  margin-top: 20px;
  display: flex;
}
.sendPushButton {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  -webkit-box-shadow: 0px 10px 1px 0px rgba(0, 0, 0, 0.01);
  -moz-box-shadow: 0px 10px 1px 0px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 10px 1px 0px rgba(0, 0, 0, 0.01);
  cursor: pointer;
  margin-right: 20px;
}
.sendPushButton:hover {
  opacity: 0.7;
}
.sendThisPushAgain {
  background-color: #efefef;
  color: #8f8e8e;
}
.cancelScheduledPN {
  background-color: #e73560;
  color: #ffffff;
}
.pushNotificationDetailsListContainer {
  margin-top: 10px;
}
.pushNotificationDetailsListItem {
  background-color: #f6f7fb;
  margin-bottom: 10px;
  font-size: 12px;
  padding: 10px;
}
