.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleContainer {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.titleContainer .title {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.propertiesContentContainer {
  border-top: 1px solid #eeeeee;
}
.singleProperty {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.singleProperty.askForEmailAddressInWebSurveyProperty {
  border-bottom: none;
}
.singleProperty .propertyTitle {
  margin-right: 15px;
}
.singleProperty:last-child {
  border-bottom: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.switch :global .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.arrowExpanded {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  transform: rotate(180deg);
}
.arrowCollapsed {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.languageSelector {
  display: inline-block;
  color: #000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 12px;
  margin-right: 5px;
  cursor: pointer;
}
.languageSelectorActive {
  color: #fff;
  background-color: #5200f1;
}
.colorCodesContainer {
  display: flex;
  margin: 0 20px 0 20px;
  padding: 10px 0 10px 0;
  flex: 1;
  border-bottom: 1px solid #eeeeee;
}
.customImageUploadContainer {
  padding: 10px 20px 0 20px;
}
.customImageUploadContainer .imageContainer {
  display: inline-block;
  margin-bottom: 20px;
}
.customImageUploadContainer .imageContainer .removeImage {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.customImageUploadContainer .imageContainer .removeImage img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.customImageUploadContainer .imageContainer .selectedImage {
  max-height: 120px;
  max-width: 240px;
  padding: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.dropContainer {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.dropContainer .dropButton {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.dropContainer .dropDescription {
  text-align: center;
}
.moreInfoContainer {
  display: inline-block;
  margin-left: 5px;
}
.moreInfoContainer .questionMark {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.moreInfoContainer .tooltip {
  display: none;
  position: absolute;
  top: -20px;
  left: 25px;
  z-index: 100;
  font-size: 12px;
  width: 640px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px 20px;
  color: #000000;
  border-radius: 5px;
  line-height: 18px;
  white-space: pre-wrap;
  border: 1px solid #e3e3e4;
}
.moreInfoContainer:hover .tooltip {
  display: block;
}
.moreInfoContainer .tooltip ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
