.container {
  cursor: pointer;
}
.container img {
  height: 27px;
  width: 50px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
