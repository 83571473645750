.container {
  width: 800px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}
.question {
}
.tableContainer {
  margin-top: 30px;
  font-family: 'Open Sans Regular';
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.headerRow th {
  text-align: left;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ebeaef;
}
.tableRow td {
  border-bottom: 1px solid #ebeaef;
  height: 30px;
  line-height: 30px;
}
.tableRow td span:hover {
  text-decoration: underline;
  color: #5200f1;
  cursor: pointer;
}
.closeContainer {
  text-align: right;
}
.close {
  margin-top: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}
