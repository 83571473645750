.container {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 700px;
  padding-top: 20px;
}
.blockContainer {
  padding: 15px 30px 15px 0;
  font-size: 14px;
  background-color: #fff;
  border-left: 4px solid #fff;
  margin-left: 20px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-right: 20px;
  border-top: 1px solid #dedada;
  left: -10px;
  width: 100%;
  margin-top: 20px;
}
.footer .details {
  color: #5200f1;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.loadingAnimation {
  display: block;
  position: relative;
  margin: 0;
  width: 28px;
  height: 28px;
  opacity: 0.5;
}
.loadingAnimation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loadingAnimation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingAnimation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingAnimation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
