.campaignsFilterContainer {
  margin-bottom: 20px;
}
.header {
  display: flex;
}
.header .campaignsCount {
  font-size: 12px;
  margin-left: 15px;
  top: 2px;
  display: flex;
}
.header .campaignsCount .campaignsLabel {
  margin-left: 5px;
}
.header .filtersButton {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.header .filtersButton img {
  width: 9px;
  margin-right: 5px;
}
.header .filtersButton.filtersButtonActive {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.filtersContainer {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  display: flex;
  padding: 15px;
}
.filtersContainerOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  opacity: 0.7;
}
.filtersContainerNarrowSection {
  width: 15%;
  border-right: 1px solid #d5d5d5;
}
.filtersContainerNormalSection {
  width: 20%;
  border-right: 1px solid #d5d5d5;
}
.filtersContainerNormalSection:last-of-type {
  border-right: none;
}
.filtersContainerWideSection {
  flex-grow: 1;
  border-right: 1px solid #d5d5d5;
}

.sectionTitle {
  color: #5200f1;
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.filterInput,
.filterDropdown {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  outline: none;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 11px;
}
.filterInput {
  width: calc(100% - 30px);
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.filterDropdown {
  width: calc(100% - 20px);
  height: 22px;
  line-height: 22px;
  margin-left: 10px;
  -webkit-appearance: none;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
}
.languageItem {
  cursor: pointer;
}
.bottomDescription {
  font-size: 11px;
  margin-left: 10px;
  color: #8f8e8e;
  margin-bottom: 10px;
}
.subsectionItemCheckbox {
  font-size: 11px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  color: #b5b5b8;
  cursor: pointer;
}
.subsectionItemCheckboxSelected {
  font-size: 11px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  color: #5200f1;
  cursor: pointer;
}
.subsectionItemLabel {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
  width: 50px;
}
.subsectionItemLabel.subsectionItemLabelLanguage {
  width: auto;
}
.dateItemContainer {
  min-height: 100px;
}
.subSectionItemContainer {
  margin-bottom: 5px;
}
.subsectionItemValue {
  width: calc(100% - 240px);
}
.subsectionItemValue input {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  outline: none;
  background-color: #ffffff;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 62%;
}
.communityContainer {
  min-height: 70px;
}
.typeContainer {
  min-height: 70px;
}
.clientContainer {
  min-height: 70px;
}
.sectionBottomDescription {
  font-size: 10px;
  color: #8f8e8e;
  margin-left: 10px;
}
