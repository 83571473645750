.container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.container .loadingContainer {
  min-height: 391px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 20px;
}
.title {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
  text-transform: capitalize;
}
.chartContainer {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.tooltipContainer {
  position: fixed;
  z-index: 9999999999999999;
  font-family: 'Open Sans SemiBold';
  font-size: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 8px 10px;
  border-radius: 5px;
}
.legend {
  text-align: center;
}
.legend .legendItem {
  display: inline-block;
  margin: 0 10px;
  font-size: 11px;
}
.legend .legendItem .bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
}
.legend .legendItem .bullet.green {
  background-color: #a0d035;
}
.legend .legendItem .bullet.orange {
  background-color: #e8bd2b;
}
.legend .legendItem .bullet.red {
  background-color: #ff005f;
}
.legend .legendItem .bullet.lightGrey {
  background-color: #f2f2f2;
}
.legend .legendItem .bullet.darkGrey {
  background-color: #d9d9d9;
}
.legend .legendItem .bullet.blue {
  background-color: #5200f1;
}
.legend .legendItem .stripe {
  display: inline-block;
  width: 8px;
  height: 1px;
  background-color: #000;
  margin-right: 5px;
  vertical-align: middle;
}
