.container {
  margin-bottom: 30px;
  font-size: 24px;
}
.input {
  height: 40px;
  width: 85px;
  padding: 0 8px;
  margin-left: 25px;
  border: 1px solid #c3c3c3;
  outline: none;
  font-size: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  text-align: right;
}
