.container {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.noResultsPlaceholder {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.appBarPath {
  cursor: pointer;
}
.appBarSpliter {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.mainTable {
  width: 100%;
}
.headerRow {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  user-select: none;
}
.headerRow.clickable {
  cursor: pointer;
}
.headerRow.activeSorting {
  color: #4a25e8;
}
.touchpointsContainer {
  width: 250px;
}
.licenceStartContainer {
  width: 200px;
}
.licenceEndContainer {
  width: 200px;
}
.touchpointsTooltipContainer {
  display: inline-block;
}
.touchpointsTooltipIcon {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.touchpointsTooltipPopup {
  position: absolute;
  left: 30px;
  bottom: 30px;
  padding: 0px 30px;
  width: 350px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 13px;
}
.touchpointsTooltipIcon:hover + .touchpointsTooltipPopup {
  display: block;
}
.topTooltipPopupText {
  border-bottom: 1px solid #dddddd;
}
