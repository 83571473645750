.container {
  width: calc(100% + 65px);
  margin-top: 5px;
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.container.error {
  background-color: #fef0f6;
}
.dropdownRowParent {
  display: flex;
  flex: 1;
  align-items: center;
}
.dropdownRow {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.dropdownRow .close {
  cursor: pointer;
}
.dropdownRow .close img {
  width: 20px;
  height: 20px;
}
.quotaInput {
  width: 55px;
  margin-right: 10px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 23px;
  line-height: 23px;
  padding-left: 10px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 5px;
  margin-left: 15px;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow-x: hidden;
}
.quotaInput:disabled {
  background-color: #f0f0f0;
}
.dropdownContainer {
  width: 20px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 15px;
  z-index: 25;
}
.dropdownContainer.dropdownVisisble:before {
  content: '';
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
.dropdownContainerLabel img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.dropdownContainerArrow {
  width: 20px;
  height: 20px;
}
.ljArrow {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.container.error .ljArrow {
  color: green;
  fill: green;
}
.errorIconContainer {
  position: absolute;
  top: 2px;
  right: 0px;
  padding-left: 10px;
  padding-right: 5px;
  border-left: 1px solid #c1c1c1;
}
.errorIconContainer img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
.errorIconContainer span {
  display: none;
  position: absolute;
  top: -5px;
  left: 60px;
  min-width: 125px;
  max-width: 300px;
  padding: 10px;
  z-index: 100;
  background: #f61553;
  color: #fff;
  -moz-border-radius: 5px; /* this works only in camino/firefox */
  -webkit-border-radius: 5px; /* this is just for Safari */
}
.errorIconContainer span:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f61553;
  left: -8px;

  top: 11px;
}

.errorIconContainer:hover {
  font-size: 99%; /* this is just for IE */
}

.errorIconContainer:hover span {
  display: block;
}
