.container {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.container.noClick {
  cursor: default;
}
.container:first-child {
  border-top: 1px solid #dddddd;
}
.detailsContainer {
  padding-left: 10px;
}
.actionsContainer {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.actionsContainer .actionsButton {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.starredUser {
  display: inline-block;
  height: 14px;
  margin-right: 7px;
}
.rowContainer {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
}
.nameContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 200px;
  line-height: 18px;
  position: relative;
  top: 3px;
}
.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
