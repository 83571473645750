.searchInputDropdownPlaceholder {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  height: 25px;
  line-height: 25px;
  min-width: 167px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
  position: relative;
}
.dropdownIcon {
  width: 8px;
  height: 8px;
  margin-left: 15px;
  display: block;
  position: relative;
  top: 9px;
}
.searchInputDropdown {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 25px;
  width: 100%;
  border-radius: 5px;
}
.dropdownItem {
  padding-left: 8px;
  color: #000000;
}
