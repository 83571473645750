.container {
  padding: 15px;
  width: 650px;
  font-size: 13px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}
.container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.title {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  margin-bottom: 10px;
}
.description {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: italic;
  padding-bottom: 15px;
}
.labelsContainer {
  padding-top: 25px;
  /*max-height: calc(100vh - 400px);*/
  overflow: visible;
  left: -25px;
  width: 700px;
}
.addLabelContainer {
  font-size: 13px;
  display: flex;
  height: 50px;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
}
.addLabelContainer span {
  cursor: pointer;
}
.addLabelContainer .addIcon {
  height: 10px;
  margin-right: 10px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.footer .deletePopulation {
  color: #fc035d;
  cursor: pointer;
}
.footer .deletePopulation .deletePopulationIcon {
  height: 12px;
  margin-right: 7px;
}
.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirmDisabled {
  filter: grayscale(100);
  opacity: 0.5;
  cursor: inherit;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.populationSizeInformation {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  left: -25px;
  width: 650px;
  padding: 0 25px;
}
.populationSizeInformationRed {
  background-color: #fff2f7;
  color: #fc035d;
}
.populationSize {
  text-transform: uppercase;
  width: 25%;
}
.populationSmallAlert {
  color: #fc035d;
  font-size: 12px;
  font-family: Open Sans SemiBold;
  display: flex;
  width: 75%;
}
.populationSmallAlert img {
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  position: relative;
  margin-top: 5px;
}
.noLabelsAdded {
  text-align: center;
  font-size: 11px;
  margin-bottom: 15px;
}
.line {
  border-bottom: 1px solid #e6e6e6;
  left: -25px;
  width: 700px;
}
.textInput {
  border: 1px solid #d6d9db;
  border-radius: 4px;
  width: 300px;
  height: 24px;
  padding: 0 10px;
  outline: none;
}
.textInput:focus {
  border-color: #bcbdbe;
}
