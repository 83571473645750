.container {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.title {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.noResultsPlaceholder {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.appBarPath {
  cursor: pointer;
}
.appBarSpliter {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.mainTable {
  width: 100%;
}
.headerRow {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
