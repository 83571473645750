.groupWrapper {
  display: flex;
  border-top-left-radius: 6px;
  border-left: 3px solid #ffffff00;
}
.groupWrapper.active {
  border-left: 4px solid #8500f0;
}
.verticalGroupLine {
  width: 33px;
  background-color: #e4dafb;
  border-top: 1px solid #bf9dff;
  border-right: 1px solid #bf9dff;
  border-bottom: 1px solid #bf9dff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.verticalGroupLine.active {
  border-left: 3px solid #e4dafb;
}
.groupParentTopContainer {
  padding: 0px 25px;
  background-color: rgba(191, 157, 255, 0.2);
  border: 1px solid #bf9dff;
}
.groupContainer {
  width: 100%;
  border-radius: 5px;
  flex: 1;
}
.active {
  margin-bottom: 0;
}
.firstChildQuestion {
  padding-top: 25px;
}
.lastChildQuestion {
  padding-bottom: 25px;
}
