.container {
  position: fixed;
  width: 208px;
  margin-left: 285px;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: 20;
}
.dropdownItem {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.dropdownItem img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.dropdownItemLabel {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.noAvailableOptions {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.dropdownPopupItemsContainer {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 100px;
}
.dropdownItemNumber {
  padding-right: 2px;
}

.emojiPickerContainer {
  padding: 0 5px 10px 5px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
  z-index: 25;
}
.emojiPickerContainer::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.emojiPickerContainer::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.emojiPickerContainer .emojiCategory {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.emojiPickerContainer .emojiCategory:first-child {
  margin-top: 10px;
}
.emojiPickerContainer .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.emojiPickerContainer .emoji:hover {
  opacity: 0.6;
}
